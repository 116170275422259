import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeadingWrapper from "../common/HeadingWrapper";
import AttachmentSelection from "../common/inputs/AttachmentSelection";
import PageTitle from "../layout/PageTitle";
import { AttachmentViewer } from "./AttachmentViewer";
import Title from "../notebook/Title";
import { useForm } from "react-hook-form";
import { AttachmentsPreview } from "./AttachmentsPreview";
import { SaveButton } from "../common/buttons/SaveButton";
import { ConfirmModal } from "../common/modals/PopupModals";
import { alert } from "../common/Alert";
import { submitAttachments } from "./attachment-utils";
import moment from "moment";
import { useUploadProgress } from "../../hooks";
import JoyrideTour from "../common/modals/JoyrideTour";
import { attachmentSteps } from "../../assets/data/JoyrideTourSteps";

const GET_ATTACHMENTS = gql`
  query patient($uuid: String) {
    patient(uuid: $uuid) {
      name
      lastNameOfOwner
      sex
      age
      weight
      birthday
      isIntact
      species
      breed
      attachments {
        uuid
        fileName
        fileLink
        createdAt
        note {
          uuid
        }
      }
    }
  }
`;

const DELETE_ATTACHMENT = gql`
  mutation deleteAttachment($attachmentUuid: String) {
    deleteAttachment(attachmentUuid: $attachmentUuid) {
      ok
    }
  }
`;

const UPLOAD_PATIENT_ATTACHMENTS = gql`
  mutation uploadPatientAttachments(
    $patientUuid: String!
    $attachments: [AttachmentInput]!
    $createdAt: DateTime!
  ) {
    uploadPatientAttachments(
      patientUuid: $patientUuid
      attachments: $attachments
      createdAt: $createdAt
    ) {
      ok
    }
  }
`;

export default function Attachments() {
  const [selectedAttachments, setSelectedAttachments] =
    useState(null);
  const [isSplitViewActive, setIsSplitViewActive] = useState(null);
  const [
    shouldShowDeleteAttachmentModal,
    setShouldShowDeleteAttachmentModal,
  ] = useState(false);
  const [shouldClearUploads, setShouldClearUploads] = useState(false);
  const [runJoyrideTour, setRunJoyrideTour] = useState(false);

  const navigate = useNavigate();
  const { patientUUID } = useParams();

  const { setPercentageUploaded, setCurrentFileName } =
    useUploadProgress();

  const { data, refetch } = useQuery(GET_ATTACHMENTS, {
    variables: {
      uuid: patientUUID,
    },
  });

  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);
  const [uploadPatientAttachments] = useMutation(
    UPLOAD_PATIENT_ATTACHMENTS,
  );

  const { control, getValues } = useForm();

  function handleAttachmentClick(attachment) {
    if (isSplitViewActive) {
      if (!selectedAttachments) {
        setSelectedAttachments([null, attachment]);
      } else if (selectedAttachments.length === 1) {
        setSelectedAttachments([...selectedAttachments, attachment]);
      } else {
        const lastItem =
          selectedAttachments[selectedAttachments.length - 1];
        setSelectedAttachments([lastItem, attachment]);
      }
    } else {
      setSelectedAttachments([attachment]);
    }
  }

  function hideModal() {
    setShouldShowDeleteAttachmentModal(false);
  }

  async function handleSaveAttachments() {
    const uploadedFiles = await submitAttachments({
      getValues,
      setPercentageUploaded,
      setCurrentFileName,
    });

    if (uploadedFiles) {
      await uploadPatientAttachments({
        variables: {
          createdAt: moment().format(),
          patientUuid: patientUUID,
          attachments: uploadedFiles,
        },
      });
    }

    refetch({ uuid: patientUUID });
    setShouldClearUploads(true);
  }

  return (
    <>
      <JoyrideTour
        steps={attachmentSteps}
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
      />
      <ConfirmModal
        shouldShow={shouldShowDeleteAttachmentModal}
        hideModal={hideModal}
        title={"Delete Attachment"}
        cancelText={"Never Mind"}
        confirmText={"Yes, Delete"}
        cancelAction={() => hideModal()}
        confirmAction={() => {
          if (selectedAttachments) {
            deleteAttachment({
              variables: {
                attachmentUuid: selectedAttachments[0].uuid,
              },
            })
              .then(() => {
                alert("success", "Attachment deleted successfully.");
                refetch({ uuid: patientUUID });
              })
              .catch(() => {
                alert(
                  "error",
                  "An error occurred. Please try again later or contact support.",
                );
              });
          }
          hideModal();
          setSelectedAttachments(null);
        }}
      >
        <h5>
          Are you sure you&apos;d like to{" "}
          <strong className="text-red-500">
            delete this attachment
          </strong>
          ? This action cannot be undone.
        </h5>
      </ConfirmModal>
      <div className="space-y-4">
        <PageTitle
          title="Patient Attachments"
          showHelpButton
          onClick={() => {
            setRunJoyrideTour(true);
          }}
        />
        <HeadingWrapper
          heading={<Title patient={data?.patient} />}
        ></HeadingWrapper>

        <div id="attachmentViewer">
          <AttachmentViewer
            navigate={navigate}
            selectedAttachments={selectedAttachments}
            setSelectedAttachments={setSelectedAttachments}
            isSplitViewActive={isSplitViewActive}
            setIsSplitViewActive={setIsSplitViewActive}
            setShouldShowDeleteAttachmentModal={
              setShouldShowDeleteAttachmentModal
            }
          />
        </div>

        <div id="patientAttachmentPreview">
          <AttachmentsPreview
            selectedAttachments={selectedAttachments}
            isForPatientProfile={false}
            patientUUID={patientUUID}
            handleAttachmentClick={handleAttachmentClick}
            attachments={data?.patient?.attachments}
          />
        </div>
      </div>
      <div className="my-8" id="patientAttachmentUpload">
        <AttachmentSelection
          control={control}
          shouldClearUploads={shouldClearUploads}
          setShouldClearUploads={setShouldClearUploads}
          isForNoteForm={false}
        />
        <div className="flex flex-row justify-end my-2">
          <SaveButton onClick={handleSaveAttachments} />
        </div>
      </div>
    </>
  );
}
