import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { usePreferences } from "./use-preferences";
import mixpanel from "mixpanel-browser";

const findAndReplaceContext = createContext();

export function useFindAndReplaceContext() {
  return useContext(findAndReplaceContext);
}

function useProvideFindAndReplace() {
  const [findAndReplaceSelectedText, setFindAndReplaceSelectedText] =
    useState("");
  const [findAndReplaceMatchCount, setFindAndReplaceMatchCount] =
    useState("");
  const [replacementText, setReplacementText] = useState("");
  const [
    shouldShowReplacementTextBox,
    setShouldShowReplacementTextBox,
  ] = useState(false);

  return {
    findAndReplaceSelectedText,
    setFindAndReplaceSelectedText,
    findAndReplaceMatchCount,
    setFindAndReplaceMatchCount,
    replacementText,
    setReplacementText,
    shouldShowReplacementTextBox,
    setShouldShowReplacementTextBox,
  };
}

export function FindAndReplaceProvider({ children }) {
  const autosave = useProvideFindAndReplace();

  return (
    <findAndReplaceContext.Provider value={autosave}>
      {children}
    </findAndReplaceContext.Provider>
  );
}

export function useFindAndReplace() {
  try {
    useFormContext();
    useFindAndReplaceContext();
  } catch (e) {
    console.log(e);
    console.log("^^^^^^^^^^");
    return null;
  }

  const {
    findAndReplaceSelectedText,
    setFindAndReplaceSelectedText,
    findAndReplaceMatchCount,
    setFindAndReplaceMatchCount,
    replacementText,
    setReplacementText,
    shouldShowReplacementTextBox,
    setShouldShowReplacementTextBox,
  } = useFindAndReplaceContext();
  const { getValues, reset } = useFormContext();
  const { isFindAndReplaceEnabled } = usePreferences();

  useEffect(() => {
    if (isFindAndReplaceEnabled) {
      setShouldShowReplacementTextBox(
        findAndReplaceSelectedText.length > 0 ? true : false,
      );
    }
  }, [findAndReplaceSelectedText]);

  useEffect(() => {
    if (!isFindAndReplaceEnabled) {
      setShouldShowReplacementTextBox(false);
    }
  }, [isFindAndReplaceEnabled]);

  function selectFindAndReplaceText(e) {
    const textarea = e.target;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = textarea.value.substring(start, end);

    /** 
    setSelected text in context, but strip all non-alphanumeric characters
    at the boundaries. It allows alphanumeric characters to be selected
    and replaced, but allows for groups of words to be captured as well with spaces in between.
    Ie: 
    Allowed:
    -  "Normal on exam"
    - "Owner"
    Not allowed: 
    - "Normal on exam." -> note the trailing period won't be selected
    - "Owner " -> note the trailing space won't be selected
    */
    const selectedTextAlphaNumeric = selectedText.replace(
      /^\W+|\W+$/g,
      "",
    );
    setFindAndReplaceSelectedText(selectedTextAlphaNumeric);

    const fieldText = getValues();

    const regex = new RegExp("\\b" + selectedText + "\\b", "gi");

    let count = 0;
    Object.keys(fieldText).forEach((key) => {
      if (fieldText[key] && typeof fieldText[key] === "string") {
        const matches = fieldText[key].match(regex);
        const nextCount = matches ? matches.length : 0;
        count = count + nextCount;
      }
    });

    setFindAndReplaceMatchCount(count);
  }

  /**
   * Note, this won't apply to objectiveFieldArray (not use-blank objective) or
   * customObjectiveFieldArray due to the nature of the way those form states are nested in react hook from-orange-50
   *
   * Ie, instead of replacing the value at the key of "subjective"
   * we'd need to nest down into the customObjectiveField[x].value for each value.
   *
   * This could *easily* be expanded, but out of scope for release one.
   * Instead, we can just document to users when select/replace is on
   * that this setting does not apply to subfields.
   */
  const handleReplace = () => {
    const fieldText = getValues();
    const regex = new RegExp(
      `\\b${findAndReplaceSelectedText}\\b`,
      "gi",
    ); // This ensures only whole words are replaced

    mixpanel.track("Find and Replace", {
      find: findAndReplaceSelectedText,
      replace: replacementText,
    });
    const nextObj = {};

    Object.keys(fieldText).forEach((key) => {
      if (fieldText[key] && typeof fieldText[key] === "string") {
        const replacedText = fieldText[key].replace(
          regex,
          replacementText,
        );

        nextObj[key] = replacedText;
      }
    });

    setFindAndReplaceSelectedText("");
    setFindAndReplaceMatchCount(0);
    setReplacementText("");

    reset({ ...getValues(), ...nextObj });
  };

  function clearFindAndReplace() {
    setFindAndReplaceSelectedText("");
    setFindAndReplaceMatchCount(0);
    setReplacementText("");
    setShouldShowReplacementTextBox(false);
  }

  return {
    selectFindAndReplaceText,
    handleReplace,
    findAndReplaceSelectedText,
    findAndReplaceMatchCount,
    replacementText,
    setReplacementText,
    shouldShowReplacementTextBox,
    clearFindAndReplace,
  };
}
