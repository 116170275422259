import { gql } from "@apollo/client";

export const LINK_TEAM_MEMBER = gql`
  mutation linkToTeamMember($linkedUserUuid: String) {
    linkToTeamMember(linkedUserUuid: $linkedUserUuid) {
      accessCsrf
      user {
        uuid
        email
        firstName
        lastName
      }
    }
  }
`;

export const UNLINK_TEAM_MEMBER = gql`
  mutation unlinkFromTeamMember {
    unlinkFromTeamMember {
      ok
      accessCsrf
    }
  }
`;
