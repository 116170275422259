import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ConfirmModal } from "../../../common/modals/PopupModals";
import AddUsers from "./screens/AddUsers";
import ConfirmNewUsers from "./screens/ConfirmNewUsers";
import NewUsersReceipt from "./screens/NewUsersReceipt";
import GET_EXISTING_USERS from "../../../../graphql/queries/GetExistingUsers";
import ADD_USERS_TO_ORG_TEAM from "../../../../graphql/mutations/AddUsersToOrgTeam";
import { alert } from "../../../common/Alert";
import { captureException } from "@sentry/react";
import { useTeam } from "../../../../hooks/use-team";

const AddTeamMembersModal = (props) => {
  //props
  const {
    isShowingAddMembersModal,
    hideAddMembersModal,
    team,
    refetch,
    orgUuid,
  } = props;

  //state
  const [currentStep, setCurrentStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [newUserEmails, setNewUserEmails] = useState([]);
  const [newUsersState, setNewUsersState] = useState([]);
  const [existingUsersState, setExistingUsersState] = useState([]);
  const [deactivatedUsersState, setDeactivatedUsersState] = useState(
    [],
  );
  const [receiptData, setReceiptData] = useState({});

  //hooks
  const { refreshCurrentTeam } = useTeam();

  //queries
  const [
    getExistingUsers,
    { data: existingUsersData, loading: existingUsersLoading },
  ] = useLazyQuery(GET_EXISTING_USERS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const mappedNewUsers = data?.existingUsers?.newUsers?.map(
        (email) => ({
          email,
          firstName: "",
          lastName: "",
          roleInt: 0,
        }),
      );

      const mappedUsers = {
        newUsers: mappedNewUsers || [],
        existingUsers: data?.existingUsers?.existingUsers || [],
      };
      setNewUsersState(mappedUsers.newUsers);
      setExistingUsersState(mappedUsers.existingUsers);
      setDeactivatedUsersState(
        data?.existingUsers?.deactivatedUsers || [],
      );
      setCurrentStep(1);
    },
  });

  //mutations
  const [
    addUsersToOrgTeam,
    {
      data: addUsersData,
      loading: addUsersLoading,
      error: addUsersError,
    },
  ] = useMutation(ADD_USERS_TO_ORG_TEAM, {
    onCompleted: (data) => {
      setReceiptData(data?.addUsersToOrgTeam?.addUsers);
      refreshCurrentTeam(team);
      setCurrentStep(2);
    },
    onError: (error) => {
      captureException(error);
      alert("error", "An error occurred while adding users.");
    },
  });

  //effects
  useEffect(() => {
    //reset all modal data when the modal opens or closes
    resetState();
  }, [isShowingAddMembersModal]);

  useEffect(() => {
    setErrorMessage("");
  }, [currentStep]);

  //functions
  const resetState = () => {
    setCurrentStep(0);
    setNewUserEmails([]);
    setNewUsersState([]);
    setExistingUsersState([]);
    setDeactivatedUsersState([]);
    setErrorMessage("");
    refetch();
  };

  const handleAddUsers = useCallback(() => {
    if (newUserEmails?.length > 0) {
      //get new, existing, and deactivated users based on the emails entered
      getExistingUsers({ variables: { emails: newUserEmails } });
    } else {
      alert("error", "Please add at least one user to continue.");
    }
  }, [newUserEmails, getExistingUsers]);

  const handleConfirmNewUsers = useCallback(() => {
    //this mapping is important for the addUsersToOrgTeam mutation
    //and for displaying the data properly in the tables
    const updatedNewUsers = newUsersState
      ?.map((user, index) => ({
        email: newUsersState[index]?.email,
        firstName: user.firstName,
        lastName: user.lastName,
        roleInt: user.roleInt === 0 ? null : user.roleInt,
      }))
      .filter((user) => user.email);

    const updatedExistingUsers = existingUsersState
      ?.map((user, index) => ({
        email: existingUsersState[index]?.email,
        firstName: user.firstName,
        lastName: user.lastName,
        roleInt: user.roleInt === 0 ? null : user.roleInt,
      }))
      .filter((user) => user.email);

    const allInputsFilled = [
      ...updatedNewUsers,
      ...updatedExistingUsers,
    ].every((user) => user.firstName && user.lastName);

    if (!allInputsFilled) {
      setErrorMessage("All name inputs must be filled");
      return;
    }

    const addUsersInput = {
      newUserData: updatedNewUsers || [],
      existingUserData: updatedExistingUsers || [],
      orgUuid,
      teamUuid: team?.uuid,
      createdAt: new Date(),
    };

    addUsersToOrgTeam({
      variables: { addUsersInput },
    });
  }, [
    newUsersState,
    existingUsersState,
    orgUuid,
    team?.uuid,
    addUsersToOrgTeam,
  ]);

  const handleRemoveUser = useCallback(
    (indexToRemove, isExisting) => {
      //leave the newEmails state so they can click previous and
      //add the users again in case removing the user was an accident
      if (isExisting) {
        setExistingUsersState((prev) =>
          prev.filter((_, index) => index !== indexToRemove),
        );
      } else {
        setNewUsersState((prev) =>
          prev.filter((_, index) => index !== indexToRemove),
        );
      }
    },
    [],
  );

  //memoized modal steps
  //each step has a component, title, confirm text, confirm action, cancel text, and cancel action
  const steps = useMemo(
    () => [
      {
        component: (
          <AddUsers
            newUserEmails={newUserEmails}
            setNewUserEmails={setNewUserEmails}
            team={team}
          />
        ),
        title: `Add Users to ${team?.name}`,
        confirmText: "Next",
        confirmAction: handleAddUsers,
        cancelText: "Cancel",
        cancelAction: hideAddMembersModal,
      },
      {
        component: (
          <ConfirmNewUsers
            newUsers={newUsersState}
            existingUsers={existingUsersState}
            deactivatedUsers={deactivatedUsersState}
            setUsersState={({ newUsers, existingUsers }) => {
              setNewUsersState(newUsers);
              setExistingUsersState(existingUsers);
            }}
            onRemove={handleRemoveUser}
            error={errorMessage}
          />
        ),
        title: "Confirm New Users",
        confirmText: "Next",
        confirmAction: handleConfirmNewUsers,
        cancelText: "Previous",
        cancelAction: () => {
          setCurrentStep(0);
        },
        width:
          existingUsersState?.length > 0 || newUsersState?.length > 0
            ? "max-w-4xl"
            : "max-w-xl",
      },
      {
        component: (
          <NewUsersReceipt
            addUsersData={addUsersData}
            receiptData={receiptData}
          />
        ),
        title: "Users Added",
        confirmText: "Finish",
        cancelText: "Previous",
        confirmAction: () => {
          refetch();
          hideAddMembersModal();
        },
        width: "max-w-xl",
      },
    ],
    [
      newUserEmails,
      team,
      handleAddUsers,
      hideAddMembersModal,
      newUsersState,
      existingUsersState,
      deactivatedUsersState,
      handleRemoveUser,
      errorMessage,
      handleConfirmNewUsers,
      addUsersData,
      receiptData,
      refetch,
      newUsersState,
      existingUsersState,
    ],
  );

  //get the current step from the memoized array and render the current step of the modal
  //the previous button is disabled once the new users are confirmed and therefore cannot be undone via the previous button
  //the confirm button is disabled if there are no new or existing users to add to encourage users to go back and add users
  //currently the only error that can show up is for empty inputs when adding new users' data
  const currentStepData = steps[currentStep];
  return (
    <ConfirmModal
      shouldShow={isShowingAddMembersModal}
      hideModal={hideAddMembersModal}
      title={currentStepData?.title}
      confirmText={currentStepData?.confirmText}
      confirmAction={currentStepData?.confirmAction}
      cancelText={currentStepData?.cancelText}
      cancelAction={currentStepData?.cancelAction}
      width={currentStepData?.width}
      disableCancel={currentStep === 2}
      disableConfirm={
        currentStep === 1 &&
        newUsersState?.length === 0 &&
        existingUsersState?.length === 0
      }
    >
      {existingUsersLoading ? (
        <div className="flex justify-center">
          <FontAwesomeIcon
            icon={faSpinner}
            className="animate-spin text-gray-500 dark:text-gray-400 text-4xl"
          />
        </div>
      ) : (
        <>
          {currentStepData?.component}
          {errorMessage &&
            (newUsersState?.length > 0 ||
              existingUsersState?.length > 0) && (
              <div className="text-red-500 mt-2">{errorMessage}</div>
            )}
        </>
      )}
    </ConfirmModal>
  );
};

export default AddTeamMembersModal;
