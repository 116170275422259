import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { alert } from "../../common/Alert.js";

export function SoapHeader(props) {
  const { textToCopy, children } = props || {
    children: "",
    textToCopy: "",
  };

  async function copy() {
    navigator.clipboard
      .writeText(
        typeof textToCopy !== "string"
          ? textToCopy.join("")
          : textToCopy,
      )
      .then(() => {
        alert("info", "Copied!");
      });
  }

  function renderHeader() {
    return (
      <h6 className="inline text-lg font-semibold dark:text-gray-300">
        {children}
      </h6>
    );
  }

  function renderCopyButton() {
    return (
      <button
        title="Copy text"
        className="cursor-pointer text-center border-2 border-white dark:border-gray-600 rounded-full text-gray-500 dark:text-gray-400 w-8 h-8 bg-white dark:bg-gray-600 hover:text-indigo-500 dark:hover:text-indigo-500 transition-all focus:outline-none"
        onClick={() => copy()}
      >
        <FontAwesomeIcon icon={faCopy} />
      </button>
    );
  }

  return (
    <div className="flex flex-row">
      <div className="flex-1">{renderHeader()}</div>

      {renderCopyButton()}
    </div>
  );
}
