import React from "react";
import { SoapHeader } from "./SoapHeader";

export function SoapBlock({ type, text, isConsise }) {
  let divClassName = isConsise
    ? "px-3 py-2 border my-1 rounded-xl "
    : "p-3 border my-1 rounded-xl ";

  switch (type) {
    case "Subjective":
      divClassName +=
        "dark:border-blue-600 bg-blue-50 border-blue-300 dark:bg-blue-700/20";
      break;
    case "Objective":
      divClassName +=
        "dark:border-pink-600 bg-pink-50 border-pink-300 dark:bg-pink-700/20 ";

      break;
    case "Assessment":
      divClassName +=
        "dark:border-green-600 bg-green-50 border-green-300 dark:bg-green-700/20 ";

      break;
    case "Plan":
      divClassName +=
        "dark:border-yellow-600 bg-yellow-50 border-yellow-300 dark:bg-yellow-700/20 ";
      break;
    default:
      divClassName +=
        "dark:border-gray-600 bg-gray-50 border-gray-300 dark:bg-gray-600/40 ";
      "break";
  }

  return (
    <div className={divClassName}>
      <SoapHeader textToCopy={text}>{type}</SoapHeader>
      <div style={{ height: "90%", whiteSpace: "pre-wrap" }}>
        <h5
          className={` ${
            isConsise ? "text-xs" : "text-xs md:text-sm"
          } align-top text-gray-900 dark:text-gray-300 w-full bg-transparent border-2 border-transparent rounded-md focus:underline break-words whitespace-pre-wrap`}
        >
          {text ? (
            text
          ) : (
            <span className="text-gray-500 dark:text-gray-500">
              No data recorded.
            </span>
          )}
        </h5>
      </div>
    </div>
  );
}
