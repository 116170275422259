import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import HeadingWrapper from "../../common/HeadingWrapper";
import { ErrorMessage, Input } from "../../common/inputs/Input";
import CreatePatientBreedDropdown from "./BreedDropdown";
import { IntactCheckbox, SexSelectionButton } from "./SexSelection";
import CreatePatientSpeciesDropdown from "./SpeciesDropdown";
import { Breeds } from "../../../assets/data/Breeds.js";
import AgeInput from "./AgeSelection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { RequiredAsterisk } from "../../common/RequiredAsterisk";

export default function CreatePatientForm({
  control,
  errors,
  sex,
  setSex,
  intact,
  setIntact,
  selectedSpecies,
  setSelectedSpecies,
  isAgeInputTypeDOB,
  setIsAgeInputTypeDOB,
  speciesItems,
}) {
  const [breedsList, setBreedsList] = useState([
    "No breed for this species. Try typing in a custom one!",
  ]);
  const [shouldShowMore, setShouldShowMore] = useState(false);

  useEffect(() => {
    function getBreeds() {
      if (selectedSpecies) {
        if (Breeds[selectedSpecies.name]) {
          setBreedsList(Breeds[selectedSpecies.name]);
        } else {
          setBreedsList([
            "No preset breeds for this species. Try typing in a custom one!",
          ]);
        }
      }
    }
    getBreeds();
  }, [selectedSpecies]);

  const inputClassName =
    "appearance-none w-full block px-3 py-2 my-2 border border-gray-100 dark:bg-gray-700 dark:border-gray-700 text-left bg-gray-100 placeholder-gray-400 text-gray-900 dark:text-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 sm:text-sm";

  function handleSelectedItemChange({ selectedItem }) {
    setSelectedSpecies(selectedItem);
  }

  return (
    <form>
      <div className="grid md:grid-cols-2 md:gap-6">
        <div className="space-y-2 md:space-y-6">
          <div>
            <HeadingWrapper
              heading={
                <h4>
                  Name <RequiredAsterisk />
                </h4>
              }
            />
            <div className="space-y-2">
              <Controller
                control={control}
                name="patientName"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    placeholder="Patient's name"
                    data-cy="pFirstName"
                    {...field}
                  />
                )}
              />
              {errors.patientName && (
                <ErrorMessage message="Required" />
              )}
              <Controller
                control={control}
                name="ownersLastName"
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <Input
                    placeholder="Owner's last name"
                    data-cy="pLastName"
                    {...field}
                  />
                )}
              />
            </div>
          </div>

          <div>
            <HeadingWrapper heading={<h4>Species</h4>} />
            <Controller
              control={control}
              name="selectedSpecies"
              render={({ field }) => (
                <CreatePatientSpeciesDropdown
                  {...field}
                  itemsAsObjects={speciesItems}
                  selectedItem={selectedSpecies}
                  handleSelectedItemChange={handleSelectedItemChange}
                  inputClassName={inputClassName}
                  dataCy="pSpeciesDropdown"
                />
              )}
            />
          </div>
          <div>
            <HeadingWrapper heading={<h4>Breed</h4>} />
            <Controller
              control={control}
              name="breed"
              render={({ field }) => (
                <CreatePatientBreedDropdown
                  {...field}
                  items={breedsList}
                  inputClassName={inputClassName}
                  dataCy="pBreedDropdown"
                />
              )}
            />
          </div>
        </div>
        <div className="space-y-6 justify-self-center">
          <div>
            <AgeInput
              isAgeInputTypeDOB={isAgeInputTypeDOB}
              control={control}
            />
            <div className="space-x-5 mt-2 px-3">
              <label className="text-sm dark:text-gray-300">
                <input
                  className="text-indigo-500 focus:ring-0"
                  type="radio"
                  name="AgeInputBirthday"
                  checked={true === isAgeInputTypeDOB}
                  onChange={() => {
                    setIsAgeInputTypeDOB(true);
                  }}
                  value="Enter DOB"
                />{" "}
                Enter D.O.B.
              </label>
              <label className="text-sm dark:text-gray-300">
                <input
                  className="text-indigo-500 focus:ring-0"
                  type="radio"
                  name="AgeInputAge"
                  checked={false === isAgeInputTypeDOB}
                  onChange={() => {
                    setIsAgeInputTypeDOB(false);
                  }}
                  value="Enter Age"
                />{" "}
                Enter Age
              </label>
            </div>
          </div>
          <div>
            <HeadingWrapper
              heading={
                <h4>
                  Sex <RequiredAsterisk />
                </h4>
              }
            />
            <div className="flex flex-row items-center justify-center flex-wrap mt-2">
              <SexSelectionButton
                type="female"
                setSex={setSex}
                sex={sex}
              />
              <SexSelectionButton
                type="male"
                setSex={setSex}
                sex={sex}
              />
            </div>

            <div className="flex items-center justify-center">
              {sex !== null ? (
                <IntactCheckbox
                  sex={sex}
                  intact={intact}
                  setIntact={setIntact}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-2">
        <span
          className="cursor-pointer text-sm dark:text-gray-200 text-gray-900  hover:text-indigo-500 hover:dark:text-indigo-400 "
          onClick={() => setShouldShowMore(!shouldShowMore)}
        >
          More (optional){" "}
          <FontAwesomeIcon
            icon={shouldShowMore ? faChevronUp : faChevronDown}
            size="xs"
          />
        </span>
        {shouldShowMore ? (
          <div className="pt-2">
            <div className="grid md:grid-cols-2 md:gap-6">
              <div>
                <PatientMoreInputs
                  control={control}
                  fieldName={"color"}
                  label={"Color"}
                  placeholder={
                    "ex: coat/skin, distinctive markings, etc."
                  }
                />
                <PatientTextArea
                  control={control}
                  fieldName={"ownerContactInfo"}
                  label={"Owner's contact info"}
                  placeholder={
                    "ex: name, address and telephone number(s)"
                  }
                />
              </div>
              <div>
                <PatientMoreInputs
                  control={control}
                  fieldName={"microchip"}
                  label={"Microchip #"}
                  placeholder={"ex: 981xxxxXXXXXXXX"}
                />
                <PatientTextArea
                  control={control}
                  fieldName={"emergencyContactInfo"}
                  label={"Emergency contact info"}
                  placeholder={
                    "ex: name, address and telephone number(s)"
                  }
                />
              </div>
            </div>
            {/* <PatientTextArea
              control={control}
              fieldName={"referralInfo"}
              label={"Referral info"}
              placeholder={
                "ex: name, email address and telephone number"
              }
            /> */}
          </div>
        ) : null}
      </div>
    </form>
  );
}

function PatientTextArea({ control, fieldName, label, placeholder }) {
  return (
    <Controller
      control={control}
      name={fieldName}
      label={"test"}
      rules={{
        required: false,
      }}
      render={({ field }) => (
        <div className="py-2">
          <HeadingWrapper heading={<h4>{label}</h4>} />
          <TextareaAutosize
            minRows={3}
            className="appearance-none relative block w-full px-3 h-16 border dark:bg-gray-700 dark:border-gray-700 dark:text-gray-200 border-gray-100 bg-gray-100 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 text-xs md:text-sm resize-none"
            {...field}
            placeholder={placeholder}
          />
        </div>
      )}
    />
  );
}

function PatientMoreInputs({
  control,
  fieldName,
  label,
  placeholder,
}) {
  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        required: false,
      }}
      render={({ field }) => (
        <div className="py-2">
          <HeadingWrapper heading={<h4>{label}</h4>} />
          <Input placeholder={placeholder} {...field} />
        </div>
      )}
    />
  );
}
