import { gql } from "@apollo/client";

export const TRANSFER_NOTES = gql`
  mutation transferNoteOwnership(
    $noteUuids: [String]
    $newOwnerUuid: String
  ) {
    transferNoteOwnership(
      noteUuids: $noteUuids
      newOwnerUuid: $newOwnerUuid
    ) {
      ok
    }
  }
`;
