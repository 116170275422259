import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "./RecordButton.module.css";

export const RecordButton = (props) => {
  const { onClick } = props;

  return (
    <div
      className={`${styles.recordButton} w-3/4 h-3/4 rounded-full cursor-pointer flex text-center relative transition-all bg-gradient-to-br from-indigo-400 to-indigo-800 place-items-center place-content-center focus:outline-none shadow-lg`}
      {...props}
      onClick={async () => {
        onClick();
      }}
    >
      <FontAwesomeIcon
        icon={faMicrophone}
        size="4x"
        color="white"
        className="w-96"
      />
    </div>
  );
};
