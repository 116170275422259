import React from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const CustomToast = ({ message }) => {
  return (
    <div className="flex flex-row">
      <div className="flex text-xl mr-5 align-middle">
        <FontAwesomeIcon
          className="inline fill-current ml-3"
          icon={faBell}
        />
      </div>
      <div className="flex">{message}</div>
    </div>
  );
};

export const alert = (type, message) => {
  switch (type) {
    case "warning":
      return toast.warning(<CustomToast message={message} />, {
        autoClose: 5000,
      });
    case "error":
      return toast.error(<CustomToast message={message} />);
    case "success":
      return toast.success(<CustomToast message={message} />);
    case "info":
      return toast.info(<CustomToast message={message} />, {
        closeOnClick: false,
        autoClose: 10000,
      });
    case "note-attachment":
      return toast.info(<CustomToast message={message} />, {
        closeOnClick: false,
        autoClose: false,
        toastId: "note-attachment",
      });
    case "dark":
      return toast.dark(<CustomToast message={message} />);
    default:
      return toast(<CustomToast message={message} />);
  }
};
