import React from "react";

import Generic from "../../assets/imgs/generic.svg";
import Visa from "../../assets/imgs/visa.svg";
import Amex from "../../assets/imgs/amex.svg";
import MasterCard from "../../assets/imgs/mastercard.svg";
import { AccountItem, AccountItemLine } from "./AccountItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyCheckAlt } from "@fortawesome/free-solid-svg-icons";

export function BillingSettings({ user }) {
  function getCardBrandImg() {
    let CardBrandImg = Generic;
    switch (user?.account?.accountDetails?.cardBrand) {
      case "visa":
        CardBrandImg = Visa;
        break;
      case "mastercard":
        CardBrandImg = MasterCard;
        break;
      case "amex":
        CardBrandImg = Amex;
        break;
      default:
        CardBrandImg = Generic;
    }

    return CardBrandImg;
  }
  return (
    <AccountItem
      title="Billing Details"
      icon={
        <FontAwesomeIcon
          icon={faMoneyCheckAlt}
          size="lg"
          color="gray"
        />
      }
    >
      <div className="flex-1 divide-y dark:divide-gray-700">
        <AccountItemLine title="Card Details:">
          {user?.account?.accountDetails?.cardNumber}{" "}
          <img src={getCardBrandImg()} className="w-8 h-4" />
          <small>
            Expires: {user?.account?.accountDetails?.cardExpiryDate}{" "}
          </small>
        </AccountItemLine>
        <AccountItemLine title="Cardholder Name:">
          {user?.account?.accountDetails?.cardholderName}
        </AccountItemLine>
        <AccountItemLine title="Address:">
          {user?.account?.accountDetails?.cardholderAddress}
        </AccountItemLine>
        <AccountItemLine title="Next Billing Date:">
          {user?.account?.accountDetails?.isCanceled ? (
            <span>
              {user?.account?.accountDetails?.nextBillingDate} <br />
              <strong className="text-xs text-red-400">
                Since you canceled your subscription, you will not be
                billed on this date. You will have full access to your
                subscription features until then.{" "}
              </strong>
            </span>
          ) : (
            user?.account?.accountDetails?.nextBillingDate
          )}
        </AccountItemLine>
      </div>
    </AccountItem>
  );
}
