import React, { useState } from "react";
import { ObjectiveFormSection } from "./ObjectiveFormSection";
import { ObjectiveToggles } from "./ObjectiveToggles";
import { SOAPInput } from "./SOAPInput";
import { useFindAndReplace } from "../../../../hooks";

export default function SOAPForm({ control, note }) {
  const [isEditFieldModeActive, setIsEditFieldModeActive] =
    useState(false);
  const { selectFindAndReplaceText } = useFindAndReplace();

  return (
    <div className="space-y-2">
      <SOAPInput
        control={control}
        title={"Subjective"}
        fieldName="subjective"
        headerStyle="bg-blue-400 dark:bg-blue-600"
        boxStyle="border-blue-300 bg-blue-50 dark:bg-blue-700/20 dark:border-blue-600"
        onMouseUp={(e) => {
          selectFindAndReplaceText(e);
        }}
      />

      <div className="relative py-2">
        <ObjectiveToggles
          note={note}
          isEditFieldModeActive={isEditFieldModeActive}
          setIsEditFieldModeActive={setIsEditFieldModeActive}
        />
        <ObjectiveFormSection
          isEditFieldModeActive={isEditFieldModeActive}
          onMouseUp={(e) => {
            selectFindAndReplaceText(e);
          }}
        />
      </div>

      <SOAPInput
        control={control}
        title={"Assessment"}
        fieldName="assessment"
        headerStyle="bg-green-400 dark:bg-green-600"
        boxStyle="border-green-300 bg-green-50 dark:bg-green-700/20 dark:border-green-600"
        onMouseUp={(e) => {
          selectFindAndReplaceText(e);
        }}
      />

      <SOAPInput
        control={control}
        title={"Plan"}
        fieldName="plan"
        headerStyle="bg-yellow-400 dark:bg-yellow-600"
        boxStyle="border-yellow-300 bg-yellow-50 dark:bg-yellow-700/20 dark:border-yellow-600"
        onMouseUp={(e) => {
          selectFindAndReplaceText(e);
        }}
      />

      <div className="pt-4">
        <SOAPInput
          control={control}
          title={"Additional Notes"}
          fieldName="additionalNotes"
          headerStyle="bg-gray-400 dark:bg-gray-600"
          boxStyle="border-gray-300 bg-gray-50 dark:bg-gray-700 dark:border-gray-600"
          onMouseUp={(e) => {
            selectFindAndReplaceText(e);
          }}
        />
      </div>
    </div>
  );
}
