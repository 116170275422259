import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { CancelButton } from "../common/buttons/CancelButton.js";
import { SaveButton } from "../common/buttons/SaveButton.js";
import HeadingWrapper from "../common/HeadingWrapper.js";
import FourohFour from "../home/FourohFour.js";
import PageTitle from "../layout/PageTitle.js";
import { KeyPatientInfoFormFields } from "../notes/edit/form/KeyPatientInfoFormFields.js";
import Title from "./Title.js";

import { EDIT_PATIENT_MUTATION } from "../patients/api/mutations.js";

const GET_PATIENT_KEY_INFO = gql`
  query patientKeyInfo($uuid: String) {
    patient(uuid: $uuid) {
      name
      lastNameOfOwner
      uuid
      species
      sex
      breed
      age
      keyHistory
      keyMedications
      vaccinationStatus
      weight
    }
  }
`;

export default function KeyPatientInfo() {
  const { patientUUID } = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm();

  const { data, loading } = useQuery(GET_PATIENT_KEY_INFO, {
    variables: {
      uuid: patientUUID,
    },
    fetchPolicy: "no-cache",
  });

  const [editPatient] = useMutation(EDIT_PATIENT_MUTATION);

  useEffect(() => {
    if (data && data.patient) {
      const {
        keyHistory,
        keyMedications,
        vaccinationStatus,
        weight,
      } = data.patient;

      reset({
        keyHistory,
        keyMedications,
        vaccinationStatus,
        weight: weight == "Not recorded" ? "" : weight,
      });
    }
  }, [data]);

  if (!data && loading) {
    return null;
  }

  if (!data && !loading) {
    return <FourohFour />;
  }

  function onSubmit(data) {
    const { keyHistory, keyMedications, vaccinationStatus, weight } =
      data;

    editPatient({
      variables: {
        patientUuid: patientUUID,
        patientInput: {
          keyHistory,
          keyMedications,
          vaccinationStatus,
          weight,
        },
      },
    });
    navigate(-1);
  }

  const {
    name,
    keyHistory,
    keyMedications,
    vaccinationStatus,
    weight,
  } = data.patient;

  return (
    <div>
      <PageTitle title="Key Patient Info" />
      <div className="my-1 py-8 ">
        <HeadingWrapper
          heading={<Title patient={data?.patient} />}
        ></HeadingWrapper>

        <h5 className="text-indigo-400 text-sm">
          Key information added here will be shown at the top of{" "}
          {name}&apos;s notebook. You&apos;ll see it alongside key
          info gathered from {name}&apos;s notes.
        </h5>
      </div>

      <KeyPatientInfoFormFields
        control={control}
        keyHistory={keyHistory || ""}
        keyMedications={keyMedications || ""}
        vaccinationStatus={vaccinationStatus || ""}
        weight={weight}
        placeholder="Type key info here..."
      />

      <div className="flex flex-row justify-center mb-10 space-x-4">
        <CancelButton />
        <SaveButton
          type={"save"}
          onClick={handleSubmit(onSubmit)}
          formNoun={"Key Info"}
        />
      </div>
    </div>
  );
}
