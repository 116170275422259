import React from "react";
import { BasicButton } from "../../../../common/buttons/BasicButton.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignLeft,
  faSlidersH,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import { alert } from "../../../../common/Alert.js";

const SelectType = ({ setStep, handleGenerateSummary }) => {
  const { customClientSummariesBeta } = useFlags();

  return (
    <div className="flex justify-center space-x-4 p-2">
      <button
        className={`group p-4 rounded-xl border dark:border-gray-600 flex-1 hover:bg-indigo-500/20 hover:border-indigo-300 dark:hover:border-indigo-500 transition-all shadow-sm`}
        onClick={() => {
          setStep(3);
          handleGenerateSummary(true);
        }}
      >
        <div className="flex flex-row items-center space-x-4">
          <FontAwesomeIcon
            icon={faAlignLeft}
            size="2x"
            className="text-gray-400 dark:text-gray-500 group-hover:text-indigo-500 transition-all"
          />

          <div className="flex flex-col items-start">
            <h4>Simple</h4>
            <h5 className="text-sm">
              Automatically generates a client summary using
              Scribenote's default template.
            </h5>
          </div>
        </div>
      </button>

      <button
        className={` ${
          customClientSummariesBeta
            ? "cursor-pointer"
            : "cursor-not-allowed"
        } group p-4 rounded-xl border dark:border-gray-600 flex-1 hover:bg-indigo-500/20 hover:border-indigo-300 dark:hover:border-indigo-500 transition-all shadow-sm`}
        onClick={() => {
          if (customClientSummariesBeta) {
            setStep(2);
          } else {
            alert("warning", "Coming soon!");
          }
        }}
      >
        <div className="flex flex-row items-center space-x-4">
          <FontAwesomeIcon
            icon={faTasks}
            size="2x"
            className="text-gray-400 dark:text-gray-500 group-hover:text-indigo-500 transition-all"
          />
          <div className="flex flex-col items-start">
            <h4>
              {customClientSummariesBeta
                ? "Custom"
                : "Custom (Coming soon)"}
            </h4>
            <h5 className="text-sm">
              Automatically generates a client summary using custom
              options.
            </h5>
          </div>
        </div>
      </button>
    </div>
  );
};

export default SelectType;
