// import { Controller } from "react-hook-form";
import { SOAPInput } from "../SOAPInput.js";

export function EditClientSummaryResult({
  control,
  generatedSummaryRef,
}) {
  return (
    <SOAPInput
      control={control}
      fieldName="clientSummary"
      label="Client Summary"
      inputRef={generatedSummaryRef}
      title={"Client Summary"}
      boxStyle="border-gray-400 bg-gray-50 dark:bg-gray-700 dark:border-gray-600 border p-4 my-2 rounded-xl w-full text-xs md:text-sm align-top text-gray-700 dark:text-gray-300"
    />
  );
}
