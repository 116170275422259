import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { APPEND_RECORDING } from "../../notes/edit/api/mutations";
import { alert } from "../Alert";
import Record from "../record/Record";
import { MessageModal } from "./PopupModals";
import moment from "moment";
import mixpanel from "mixpanel-browser";

export default function RecordingModal({
  shouldShow,
  setShouldShow,
  isForEditNote,
  noteUuid,
}) {
  const [isRecording, setIsRecording] = useState(false);
  const { control, getValues } = useForm();
  // const { noteUUID } = useParams();
  const [appendRecording] = useMutation(APPEND_RECORDING);

  const navigate = useNavigate();

  // obviously here we'll need the proper mutation for appending audio to an existing note object.
  async function appendRecordingToNote(
    audioStorageLink,
    storageObjectName,
  ) {
    appendRecording({
      variables: {
        noteUuid: noteUuid,
        storageObjectName: storageObjectName,
        createdAt: moment().format(),
      },
    })
      .then(() => {
        mixpanel.track("Appended Recording to Note");
        if (isForEditNote) {
          // reset transcript between recordings
          alert(
            "success",
            "Audio file uploaded! Taking you back to the dashboard...",
          );
          setTimeout(function () {
            navigate("/dashboard", {
              state: { shouldPreventBackNavigation: true },
            });
          }, 1000);
        } else {
          alert("success", "Audio file uploaded!");
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((e) => {
        alert("error", e.message);
        setTimeout(function () {
          navigate("/dashboard", {
            state: { shouldPreventBackNavigation: true },
          });
        }, 1000);
      });
  }

  if (document.readyState === "complete") {
    return createPortal(
      <MessageModal
        shouldShow={shouldShow}
        setShouldShow={setShouldShow}
        title="Add to Recording"
        dismissable={!isRecording}
      >
        <div className="pb-10">
          <h5 className="text-gray-600 text-xs md:text-sm">
            Click to record follow-up audio. We&#39;ll add it to the
            original recording and reprocess your note. Please note
            that edits to the original note will be lost.
          </h5>
          <Record
            isRecording={isRecording}
            setIsRecording={setIsRecording}
            control={control}
            createNewNote={appendRecordingToNote}
            shouldShowTitleInput={false}
            shouldUseSmallSize={true}
            shouldShowTypeDropdown={false}
          />
        </div>
      </MessageModal>,
      document.getElementById("root-node"),
    );
  } else {
    return null;
  }
}
