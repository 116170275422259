import React from "react";
import { useFindAndReplace, usePreferences } from "../../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHighlighter,
  faMagic,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import { alert } from "../../common/Alert";

export default function FindAndReplaceToggle({ isAutoNote }) {
  try {
    useFindAndReplace();
  } catch (e) {
    return null;
  }

  const { isFindAndReplaceEnabled, setIsFindAndReplaceEnabled } =
    usePreferences();

  const isSuperSOAP = location.pathname === "/super_soap";

  return (
    <Tooltip
      arrow
      title={
        isSuperSOAP
          ? "Select and Replace is disabled when using Super SOAP."
          : "Click to toggle Select and Replace. This will allow you to highlight a word and replace all instances of it at once."
      }
    >
      <div id="searchReplaceToggle">
        <button
          disabled={isSuperSOAP}
          onClick={() => {
            if (!isFindAndReplaceEnabled) {
              alert(
                "success",
                "Highlight a word with your mouse to begin",
              );
            }
            setIsFindAndReplaceEnabled(!isFindAndReplaceEnabled);
          }}
          className={`${
            isFindAndReplaceEnabled
              ? "bg-indigo-500 text-white hover:bg-indigo-600"
              : "bg-white dark:bg-gray-800 hover:bg-indigo-50 dark:hover:bg-gray-700 text-indigo-500"
          } px-2 font-semibold justify-center w-10 lg:w-32 h-[40px] ${
            isAutoNote ? "rounded-r-lg" : ""
          } flex flex-row items-center transition-all focus:outline-none  disabled:bg-gray-200 disabled:text-gray-400 disabled:hover:bg-gray-200 disabled:cursor-not-allowed`}
        >
          <FontAwesomeIcon icon={faHighlighter} className="lg:mr-2" />
          <span className="hidden lg:block text-xs">
            Select/Replace
          </span>
        </button>
      </div>
    </Tooltip>
  );
}
