import React from "react";
import NapTime from "../../assets/imgs/otto-scribbles-sleep.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { DeleteMultiModal } from "../common/multiselect/DeleteMultiModal";
import { createPortal } from "react-dom";
import Rows from "./Rows";

export default function InboxWindow({
  isExportWindow = false,
  isAlreadyExported,
  description,
  colorStyles,
  refetch,
  data,
  fetchMore,
  notesLoading,
  activeTab = null,
}) {
  if (notesLoading) {
    return (
      <div className="flex justify-center dark:text-white items-center h-64">
        <FontAwesomeIcon
          icon={faSpinner}
          className="text-4xl text-gray-300 dark:text-gray-500 animate-spin"
        />
      </div>
    );
  }

  if (data?.inboxNotes?.totalNotes === 0) {
    return (
      <div className="border-t-2 dark:border-gray-700/50 rounded-xl h-[556px] shadow-md overflow-y-scroll p-4 space-y-3 flex items-center justify-center">
        <div className="mx-auto">
          <div className="flex flex-col items-center justify-center space-y-5">
            <div className="flex self-center rounded-full w-30 h-30 bg-gray-200"></div>
            <img
              className="flex self-center"
              width="300px"
              src={NapTime}
            />
            <h4 className="text-center text-base text-gray-500 dark:text-gray-400">
              We&apos;re taking a paws...
            </h4>
            <h5 className="w-1/2 text-center text-sm text-gray-400 dark:text-gray-500">
              There&apos;s nothing to show here yet. Any notes or
              recordings you create will be found under the different
              tabs here depending on their status.
            </h5>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {document.readyState === "complete" &&
      document.getElementById("root-node")
        ? createPortal(
            <DeleteMultiModal
              notes={data?.inboxNotes?.notes}
              refetch={refetch}
            />,
            document.getElementById("root-node"),
          )
        : null}

      <div
        className={`bg-gradient-to-b ${colorStyles} border-t-4 dark:border-gray-700/50 rounded-xl px-4`}
      >
        {description ? (
          <h5
            className={`text-xs md:text-sm text-gray-400 dark:text-gray-500 text-center mt-2 rounded-lg p-2 mb-2`}
          >
            <FontAwesomeIcon icon={faInfoCircle} /> {description}
          </h5>
        ) : null}

        {/* <Sort
          refetch={refetch}
          sortAscending={sortAscending}
          setSortAscending={setSortAscending}
        /> */}

        <Rows
          items={data?.inboxNotes?.notes ?? []}
          itemCount={data?.inboxNotes?.totalNotes ?? 0}
          isExportWindow={isExportWindow}
          isAlreadyExported={isAlreadyExported}
          refetch={refetch}
          fetchMore={fetchMore}
          activeTab={activeTab}
        />
      </div>
    </>
  );
}
