import React from "react";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import MARK_NOTES_EXPORTED from "../../../graphql/mutations/MarkNotesExported.js";
import { useLocation } from "react-router-dom";
import { copyAll } from "./utils.js";

function CopyFullSOAP({
  note,
  refetch = null,
  isForSharedExport = false,
}) {
  const [modifyNotes] = useMutation(MARK_NOTES_EXPORTED);

  const location = useLocation();

  if (note) {
    return (
      <button
        title="Copy entire note"
        id="copyAllIcon"
        disabled={
          note?.isNewRecording ||
          note?.isMarkedAsProcessing ||
          note?.isMarkedAsFailed
        }
        className={
          !note?.isNewRecording &&
          !note?.isMarkedAsProcessing &&
          !note?.isMarkedAsFailed
            ? "flex-grow-0 self-center border rounded-full w-8 h-8 bg-indigo-500 border-indigo-500 hover:bg-indigo-700 text-white transition-all font-medium outline-none text-sm shadow-md focus:outline-none"
            : "flex-grow-0 self-center border rounded-full w-8 h-8 bg-gray-300 border-gray-300 dark:bg-gray-600 dark:border-gray-600 text-white transition-all font-medium outline-none text-sm focus:outline-none cursor-not-allowed"
        }
        onClick={() => {
          copyAll({ note, isForSharedExport });

          if (!isForSharedExport) {
            modifyNotes({
              variables: {
                noteUuids: [note.uuid],
                action: "mark_exported",
              },
            });

            if (location.pathname == "/inbox") {
              refetch({});
            } else {
              if (refetch !== null) {
                refetch({ isApproved: true });
              }
            }
          }
        }}
      >
        <FontAwesomeIcon icon={faCopy} />
      </button>
    );
  } else {
    return null;
  }
}

export default CopyFullSOAP;
