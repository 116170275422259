import React from "react";

import ToggleSwitch from "../common/buttons/ToggleSwitch";

import { useLocalStorage } from "../../hooks";

function PreferenceToggle({ title, toggleState, setToggleState }) {
  return (
    <div className="flex flex-row items-center space-x-2 cursor-pointer">
      <ToggleSwitch
        toggleState={toggleState}
        setToggleState={setToggleState}
      />
    </div>
  );
}

export function ExportSOAPKeyInfoToggle() {
  const [shouldShowNoteKeyInfo, setShouldShowNoteKeyInfo] =
    useLocalStorage("should-export-note-key-info");

  return (
    <PreferenceToggle
      toggleState={shouldShowNoteKeyInfo}
      setToggleState={setShouldShowNoteKeyInfo}
    />
  );
}

export function ExportSOAPSignalmentToggle() {
  const [
    shouldShowPatientSignalment,
    setShouldShowPatientSignalment,
  ] = useLocalStorage("should-show-patient-signalment");

  return (
    <PreferenceToggle
      toggleState={shouldShowPatientSignalment}
      setToggleState={setShouldShowPatientSignalment}
    />
  );
}

export function PageSaverToggle() {
  const [shouldEnablePageSaver, setShouldEnablePageSaver] =
    useLocalStorage("page-saver");

  return (
    <PreferenceToggle
      toggleState={shouldEnablePageSaver}
      setToggleState={setShouldEnablePageSaver}
    />
  );
}
