import { gql } from "@apollo/client";

export const EDIT_TEMPLATE = gql`
  mutation editTemplate(
    $templateUuid: String
    $templateFields: TemplateFieldsInput
    $noteInput: NoteInput
  ) {
    editTemplate(
      templateUuid: $templateUuid
      templateFields: $templateFields
      noteInput: $noteInput
    ) {
      template {
        uuid
      }
      ok
    }
  }
`;
