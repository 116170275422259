import Twemoji from "../../Twemoji";

export const species = {
  dog: {
    displayName: "Dog",
    icon: "🐶",
  },
  cat: {
    displayName: "Cat",
    icon: "🐱",
  },
  horse: { displayName: "Horse", icon: "🐴" },
  goat: { displayName: "Goat", icon: "🐐" },
  cow: { displayName: "Cow", icon: "🐮" },
  pig: { displayName: "Pig", icon: "🐷" },
  fowl: { displayName: "Fowl", icon: "🐔" },
  sheep: { displayName: "Sheep", icon: "🐑" },
  "mouse/rat/gerbil": {
    displayName: "Mouse/Rat/Gerbil",
    icon: "🐭",
  },
  "chinchilla/hamster/guineapig": {
    displayName: "Hamster/Chinchilla/Guinea Pig",
    icon: "🐹",
  },
  "rabbit/lagomorph": {
    displayName: "Rabbit/Lagomorph",
    icon: "🐰",
  },
  bird: {
    displayName: "Bird",
    icon: "🦜",
  },
  fish: {
    displayName: "Fish",
    icon: "🐠",
  },
  "reptile/amphibian": {
    displayName: "Reptile/Amphibian",
    icon: "🦎",
  },
  snake: {
    displayName: "Snake",
    icon: "🐍",
  },
  spider: { displayName: "Spider", icon: "🕷" },
  other: { displayName: "Other", icon: "🦄" },
};

export function getSpeciesIcon(name) {
  return species[name]?.icon;
}

export function getSpeciesDisplayName(name) {
  return species[name]?.displayName;
}

export function getSpeciesLabel(displayName, emoji) {
  return (
    <h5 className="text-sm">
      {displayName} <Twemoji emoji={emoji} />
    </h5>
  );
}

export function getPatientIntactText({
  intact,
  sex,
  isForEdit = false,
}) {
  if (intact && !isForEdit) {
    return "Intact";
  }

  if (sex === "f") {
    return "Spayed";
  } else {
    return "Neutered";
  }
}
