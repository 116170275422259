import { Tooltip } from "@mui/material";
import React from "react";
import { getFillColor, getStrokeColor } from "./utils";

const FelineDentalChart = ({ toothConditions }) => {
  // Generic function to render a shape (path or ellipse) with a tooltip
  const renderShape = (shapeType, toothId, shapeProps) => {
    const ShapeComponent = shapeType === "path" ? "path" : "ellipse";
    return (
      <Tooltip
        title={toothConditions[toothId] || "Normal"}
        key={toothId}
        placement="left"
        disableFocusListener
        arrow
        enterDelay={0}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}
      >
        <ShapeComponent
          id={toothId}
          {...shapeProps}
          fill={getFillColor({ toothConditions, toothId })}
          stroke={getStrokeColor({ toothConditions, toothId })}
          className="cursor-pointer"
        />
      </Tooltip>
    );
  };

  return (
    <svg
      viewBox="0 0 176 292"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Feline">
        <g id="Feline-Right">
          {renderShape("ellipse", "103", {
            cx: "68.3254",
            cy: "9.78574",
            rx: "4",
            ry: "6",
            transform: "rotate(-13.9125 68.3254 9.78574)",
          })}

          {renderShape("ellipse", "403", {
            cx: "69.8004",
            cy: "283.119",
            rx: "2.91378",
            ry: "3.48452",
            transform: "rotate(8.01001 69.8004 283.119)",
          })}

          {renderShape("ellipse", "401", {
            cx: "83.5711",
            cy: "283.832",
            rx: "2.91378",
            ry: "3.48452",
            transform: "rotate(1.03493 83.5711 283.832)",
          })}
          {renderShape("ellipse", "402", {
            cx: "76.5711",
            cy: "283.832",
            rx: "2.91378",
            ry: "3.48452",
            transform: "rotate(8.01001 76.5711 283.832)",
          })}
          {renderShape("ellipse", "106", {
            cx: "39.3254",
            cy: "53.7857",
            rx: "4",
            ry: "6",
            transform: "rotate(2.13852 39.3254 53.7857)",
          })}
          {renderShape("ellipse", "109", {
            cx: "15.2116",
            cy: "133.508",
            rx: "4.82845",
            ry: "7.46668",
            transform: "rotate(103.436 15.2116 133.508)",
          })}
          {renderShape("ellipse", "408", {
            cx: "39.1948",
            cy: "202.081",
            rx: "5.72094",
            ry: "9.64631",
            transform: "rotate(157.349 39.1948 202.081)",
          })}
          {renderShape("path", "407", {
            d: "M42.9151 224.284C40.8634 219.368 41.564 214.396 44.4799 213.179C47.3958 211.962 49.858 215.559 51.9097 220.476C53.9613 225.392 54.8255 229.767 51.9097 230.983C48.9938 232.2 44.9668 229.201 42.9151 224.284Z",
          })}
          {renderShape("ellipse", "107", {
            cx: "33.1586",
            cy: "81.5",
            rx: "5.68851",
            ry: "12.3585",
            transform: "rotate(29.0543 33.1586 81.5)",
          })}
          {renderShape("path", "108", {
            d: "M35.0001 105C21.5001 112 16.0001 130.5 7.55518 128.803C3.38257 126.485 4.05494 117.306 9.05696 108.303C14.059 99.299 19.9947 91.1819 24.1673 93.5C28.3399 95.8181 40.0021 95.9963 35.0001 105Z",
          })}
          {renderShape("path", "404", {
            d: "M59.3827 259.51C53.6587 273.598 40.2307 273.723 42.7002 281.976C45.3935 285.916 57.6966 285.287 66.2002 279.476C71.2002 276.058 70.0218 276.743 71.2002 272.476C73.0238 265.872 67.8863 253.698 59.3827 259.51Z",
          })}
          {renderShape("path", "409", {
            d: "M32.5064 164.015C35.2002 178.476 42.0766 186.029 37.7077 190.993C33.7637 195.476 28.7555 189.06 24.7205 182.417C20.6854 175.774 17.5626 164.881 20.7002 162.976C23.8378 161.07 28.4714 157.372 32.5064 164.015Z",
          })}
          {renderShape("path", "104", {
            d: "M56.2001 28.8625C56.5959 34.8618 53.6745 39.939 49.675 40.2028C45.6755 40.4667 42.1125 35.8172 41.7167 29.818C41.3209 23.8188 39.5006 2.76386 43.5001 2.50002C47.4995 2.23617 55.8043 22.8633 56.2001 28.8625Z",
          })}
          {renderShape("ellipse", "102", {
            cx: "74.8188",
            cy: "6.65712",
            rx: "3.53354",
            ry: "5.4776",
            transform: "rotate(-3.03696 74.8188 6.65712)",
          })}
          {renderShape("ellipse", "101", {
            cx: "81.8188",
            cy: "5.65712",
            rx: "3.53354",
            ry: "5.4776",
            transform: "rotate(-3.03696 81.8188 5.65712)",
          })}
        </g>
        <g id="Feline-Left">
          {renderShape("ellipse", "201", {
            cx: "3.53354",
            cy: "5.4776",
            rx: "3.53354",
            ry: "5.4776",
            transform:
              "matrix(-0.998596 -0.0529801 -0.0529801 0.998596 95.6376 0.37442)",
          })}
          {renderShape("ellipse", "202", {
            cx: "3.53354",
            cy: "5.4776",
            rx: "3.53354",
            ry: "5.4776",
            transform:
              "matrix(-0.998596 -0.0529801 -0.0529801 0.998596 102.638 1.37442)",
          })}
          {renderShape("ellipse", "203", {
            cx: "4",
            cy: "6",
            rx: "4",
            ry: "6",
            transform:
              "matrix(-0.970664 -0.24044 -0.24044 0.970664 111.651 4.92352)",
          })}
          {renderShape("ellipse", "303", {
            cx: "2.91378",
            cy: "3.48452",
            rx: "2.91378",
            ry: "3.48452",
            transform:
              "matrix(-0.990244 0.139346 0.139346 0.990244 110.147 279.262)",
          })}
          {renderShape("ellipse", "301", {
            cx: "2.91378",
            cy: "3.48452",
            rx: "2.91378",
            ry: "3.48452",
            transform:
              "matrix(-0.999837 0.0180619 0.0180619 0.999837 96.8266 280.296)",
          })}
          {renderShape("ellipse", "302", {
            cx: "2.91378",
            cy: "3.48452",
            rx: "2.91378",
            ry: "3.48452",
            transform:
              "matrix(-0.990244 0.139346 0.139346 0.990244 103.376 279.976)",
          })}
          {renderShape("ellipse", "206", {
            cx: "4",
            cy: "6",
            rx: "4",
            ry: "6",
            transform:
              "matrix(-0.999304 0.0373155 0.0373155 0.999304 139.795 47.6407)",
          })}
          {renderShape("ellipse", "209", {
            cx: "4.82845",
            cy: "7.46668",
            rx: "4.82845",
            ry: "7.46668",
            transform:
              "matrix(0.232354 0.972631 0.972631 -0.232354 151.751 130.546)",
          })}
          {renderShape("ellipse", "308", {
            cx: "5.72094",
            cy: "9.64631",
            rx: "5.72094",
            ry: "9.64631",
            transform:
              "matrix(0.922871 0.385109 0.385109 -0.922871 129.358 208.78)",
          })}
          {renderShape("path", "307", {
            d: "M134.632 224.284C136.684 219.368 135.983 214.396 133.067 213.179C130.152 211.962 127.689 215.559 125.638 220.476C123.586 225.392 122.722 229.767 125.638 230.983C128.554 232.2 132.581 229.201 134.632 224.284Z",
          })}
          {renderShape("ellipse", "207", {
            cx: "5.68851",
            cy: "12.3585",
            rx: "5.68851",
            ry: "12.3585",
            transform:
              "matrix(-0.87416 0.485638 0.485638 0.87416 141.16 67.9341)",
          })}
          {renderShape("path", "208", {
            d: "M140.347 105C153.847 112 159.347 130.5 167.792 128.803C171.965 126.485 171.292 117.306 166.29 108.303C161.288 99.299 155.353 91.1819 151.18 93.5C147.007 95.8181 135.345 95.9963 140.347 105Z",
          })}
          {renderShape("path", "304", {
            d: "M118.165 259.51C123.889 273.598 137.317 273.723 134.847 281.976C132.154 285.916 119.851 285.287 111.347 279.476C106.347 276.058 107.526 276.743 106.347 272.476C104.524 265.872 109.661 253.698 118.165 259.51Z",
          })}
          {renderShape("path", "309", {
            d: "M145.041 164.015C142.347 178.476 135.471 186.029 139.84 190.993C143.784 195.476 148.792 189.06 152.827 182.417C156.862 175.774 159.985 164.881 156.847 162.976C153.71 161.07 149.076 157.372 145.041 164.015Z",
          })}
          {renderShape("path", "204", {
            d: "M119.147 28.8625C118.751 34.8618 121.673 39.939 125.672 40.2028C129.672 40.4667 133.235 35.8172 133.631 29.818C134.026 23.8188 135.847 2.76386 131.847 2.50002C127.848 2.23617 119.543 22.8633 119.147 28.8625Z",
          })}
        </g>
      </g>
    </svg>
  );
};

export default FelineDentalChart;
