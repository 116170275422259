import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { PatientList } from "./PatientList";
import ToggleSwitch from "../common/buttons/ToggleSwitch";
import Downshift from "downshift";
import PageTitle from "../layout/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAmountDown,
  faSortAmountUp,
  faUndo,
  faFilter,
  faSort,
} from "@fortawesome/free-solid-svg-icons";

export const GET_PATIENT_ANALYTICS = gql`
  query patientAnalytics(
    $sortBy: String
    $filterBy: String
    $ascending: Boolean
  ) {
    patientAnalytics(
      sortBy: $sortBy
      filterBy: $filterBy
      ascending: $ascending
    ) {
      uuid
      createdAt
      name
      lastNameOfOwner
      breed
      birthday
      age
      sex
      species
    }
  }
`;

export function PatientFilteringOptions({
  sortBy,
  setSortBy,
  filterBy,
  setFilterBy,
  ascending,
  setAscending,
  refetch,
}) {
  function resetFilters() {
    setSortBy("name");
    setFilterBy("none");
    setAscending(true);
    refetch({ sortBy: "name", filterBy: "none", ascending: true });
  }

  function applyFilters() {
    refetch();
  }

  return (
    <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-2 md:space-y-0 md:space-x-4 mb-6 md:mb-2">
      <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
        <div className="flex flex-row items-center space-x-2">
          <h4 className="text-xs md:text-sm">
            <FontAwesomeIcon icon={faSort} /> Sort:
          </h4>
          <SortDropdown sortBy={sortBy} setSortBy={setSortBy} />
        </div>
        <div className="flex flex-row items-center space-x-2">
          <h4 className="text-xs md:text-sm">
            <FontAwesomeIcon icon={faFilter} size="sm" /> Filter:
          </h4>
          <FilterDropdown
            sortBy={sortBy}
            filterBy={filterBy}
            setFilterBy={setFilterBy}
          />
          {filterBy !== "none" ? (
            <div className="flex-row space-x-4">
              <button onClick={() => resetFilters()}>
                <h5 className="text-xs: md:text-sm dark:text-gray-500 hover:text-red-600 dark:hover:text-red-600">
                  <FontAwesomeIcon icon={faUndo} size="sm" /> Reset
                </h5>
              </button>
              {/* <button onClick={() => applyFilters()}>
          <h5 className="text-xs: md:text-sm">Apply Filters</h5>
        </button> */}
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex flex-row items-center space-x-2">
        <h4>
          <FontAwesomeIcon icon={faSortAmountUp} size="1x" />
        </h4>
        <ToggleSwitch
          setToggleState={() => {
            setAscending(!ascending);
          }}
          toggleState={ascending}
          toggleCallback={() => refetch()}
        />
        <h4>
          <FontAwesomeIcon icon={faSortAmountDown} size="1x" />
        </h4>
      </div>
    </div>
  );
}

function SortDropdown({ sortBy, setSortBy }) {
  return (
    <select
      className="p-1 font-normal text-xs dark:text-gray-300 md:text-sm flex-1 mt-1 rounded-lg dark:bg-gray-700 bg-gray-50"
      name="filterOption"
      placeholder="Sort by..."
      onChange={(e) => setSortBy(e.target.value)}
      defaultValue={sortBy}
    >
      <option value={"name"}>First name</option>
      <option value={"lastNameOfOwner"}>
        Owner&apos;s Last Name
      </option>
      <option value={"createdAt"}>Created At</option>
      <option value={"birthday"}>Birthday</option>
    </select>
  );
}

function FilterDropdown({ sortBy, filterBy, setFilterBy }) {
  return (
    <select
      className="p-1 font-normal text-xs dark:text-gray-300 md:text-sm flex-1 mt-1 rounded-lg dark:bg-gray-700 bg-gray-50"
      name="filterOption"
      placeholder="Filter by..."
      onChange={(e) => setFilterBy(e.target.value)}
      defaultValue={filterBy}
    >
      <option value={"none"}>None</option>
      {sortBy === "birthday" ? (
        <>
          <option value={"birthdayLastMonth"}>
            Birthday last month
          </option>
          <option value={"birthdayThisMonth"}>
            Birthday this month
          </option>
          <option value={"birthdayNextMonth"}>
            Birthday next month
          </option>
        </>
      ) : null}
    </select>
  );
}

export default function PatientAnalytics() {
  const [sortBy, setSortBy] = useState("name");
  const [filterBy, setFilterBy] = useState("none");
  const [ascending, setAscending] = useState(true);

  const { refetch, data } = useQuery(GET_PATIENT_ANALYTICS, {
    variables: {
      sortBy,
      filterBy,
      ascending,
    },
  });

  return (
    <div>
      <PageTitle title="Patient Analytics" />

      <PatientFilteringOptions
        sortBy={sortBy}
        setSortBy={setSortBy}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        ascending={ascending}
        setAscending={setAscending}
        refetch={refetch}
      />

      <PatientList patients={data?.patientAnalytics || []} />
    </div>
  );
}
