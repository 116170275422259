import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

export default function NoteCreatedAt({ createdAt, size }) {
  let textSize = "text-xs md:text-sm";
  if (size == "lg") {
    textSize = "text-sm md:text-base";
  } else if (size == "sm") {
    textSize = "text-xs";
  }
  return (
    <span
      className={`text-gray-500 dark:text-gray-400 ${textSize} whitespace-nowrap`}
    >
      <FontAwesomeIcon
        icon={faClock}
        size="sm"
        className="fill-current"
      />{" "}
      {moment(createdAt).format("MMM Do, h:mm A")}
    </span>
  );
}
