import React from "react";
import ExportMulti from "../../export/ExportMulti";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import GET_PATIENTS_APPROVED_NOTES from "../../../graphql/queries/GetPatientsApprovedNotes";

export default function HeaderSection(props) {
  const { filterDates } = props;
  const { patientUUID } = useParams();
  const { data, loading } = useQuery(GET_PATIENTS_APPROVED_NOTES, {
    variables: {
      uuid: patientUUID,
    },
  });

  if (!data || loading) {
    return null;
  }

  function renderExportMulti(data) {
    if (data?.patient?.notes.length !== 0) {
      return <ExportMulti isForPatientNotebook={true} data={null} />;
    } else return null;
  }

  return (
    <div className="flex flex-row items-end">
      <div className="flex flex-row items-end flex-wrap space-x-1 md:space-x-4 flex-1 mb-3">
        <h2 className="text-left">Notes</h2>
      </div>

      <div>{renderExportMulti(data)}</div>
    </div>
  );
}
