import React from "react";
import { useNavigate } from "react-router-dom";
import Twemoji from "../../Twemoji";
import { species } from "../patients/utils.js";
import moment from "moment";

function InfoText({ children }) {
  return (
    <h5 className="text-sm text-gray-500 dark:text-gray-300">
      {children}
    </h5>
  );
}

export function PatientList({ patients }) {
  const navigate = useNavigate();

  if (!patients) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center">
      {patients.map((patient) => (
        <div
          className={
            "py-2 px-3 hover:bg-indigo-50 hover:dark:bg-indigo-900  dark:bg-gray-700 bg-gray-50 shadow-sm cursor-pointer rounded-lg my-1"
          }
          key={patient.id}
          onClick={() => {
            navigate(`/notebook/${patient.uuid}`);
          }}
        >
          <div className="flex flex-row items-center">
            <div className="flex-1 flex-row">
              <div
                className={
                  patient.sex == "m"
                    ? "text-blue-400 font-medium text-base"
                    : patient.sex == "f"
                    ? "text-pink-400 font-medium text-base"
                    : "text-indigo-400 font-medium text-base"
                }
              >
                {patient.name} {patient?.lastNameOfOwner}
              </div>
              <InfoText>
                Breed:{" "}
                {patient?.breed
                  ? `${patient.breed}`
                  : "Not specified"}
              </InfoText>
              <InfoText>
                Birthday:{" "}
                {moment(patient?.birthday).format("DD MMM YYYY")}
              </InfoText>
              <InfoText>Age: {patient?.age}</InfoText>{" "}
              <InfoText>
                Created in Scribenote:{" "}
                {moment(patient?.createdAt).format("DD MMM YYYY")}
              </InfoText>
            </div>
            <h3>
              <Twemoji
                emoji={
                  species[patient.species]?.icon
                    ? species[patient.species]?.icon
                    : "🦄"
                }
              />
            </h3>
          </div>
        </div>
      ))}
    </div>
  );
}
