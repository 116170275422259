import React from "react";

export function ObjectiveTypeSeparator({ title }) {
  return (
    <div className="flex flex-row items-center">
      <div className="border border-pink-300 dark:border-pink-300 rounded-xl flex-1"></div>
      <h4 className="text-gray-600 ml-1">{title}</h4>
    </div>
  );
}

export function ObjectiveTypeMainSeparator({ title }) {
  return (
    <div className="flex flex-row items-center">
      <div className="border-2 border-pink-400 rounded-xl flex-1"></div>
      <h4 className="text-gray-600 mx-2 text-base md:text-lg">
        {title}
      </h4>
      <div className="border-2 border-pink-400 rounded-xl flex-1"></div>
    </div>
  );
}
