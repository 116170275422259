import { faSave } from "@fortawesome/free-regular-svg-icons";
import {
  faCheck,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useUploadProgress } from "../../../hooks";

/** A pretty save button for forms.
 * Two types: "save" or "submit"
 */
export function SaveButton({
  onClick,
  type,
  formNoun,
  disabled,
  withSpinner = true,
  disabledText,
  children,
  dataCy,
}) {
  return (
    <button
      id="submitNoteButton"
      className="border-2 shadow-md border-indigo-500 rounded-full w-44 py-2 text-lg font-semibold text-white bg-indigo-500 hover:bg-indigo-600 disabled:bg-gray-400 disabled:border-gray-400  max-w-xs text-center my-2 transition-all focus:outline-none"
      onClick={onClick}
      disabled={disabled}
      data-cy={dataCy}
    >
      {disabled ? (
        <>
          {withSpinner ? (
            <FontAwesomeIcon
              icon={faSpinner}
              color="white"
              className="animate-spin mr-1"
            />
          ) : null}
          {disabledText ? disabledText : null}
        </>
      ) : (
        <>
          {children ? (
            children
          ) : (
            <>
              <FontAwesomeIcon
                icon={type === "save" ? faSave : faCheck}
              />{" "}
              {type === "save" ? "Save " : "Submit "} {formNoun}{" "}
            </>
          )}
        </>
      )}
    </button>
  );
}
