import { useFlags } from "launchdarkly-react-client-sdk";
import { createContext, useContext, useEffect } from "react";
import { ENVIRONMENT, SCRIBENOTE_FLASK } from "../constants";
import { usePorterMigrationFlag } from "../hooks/use-porter-migration-flag";

const ScribenoteGlobalContext = createContext();

export function ScribenoteGlobalContextProvider({ children }) {
  usePorterMigrationFlag();

  return (
    <ScribenoteGlobalContext.Provider>
      {children}
    </ScribenoteGlobalContext.Provider>
  );
}

export function useScribenoteGlobalContext() {
  return useContext(ScribenoteGlobalContext);
}
