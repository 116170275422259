import React, { useEffect, useState } from "react";
import ExportNoteItem from "./ExportNoteItem";
import { gql, useQuery } from "@apollo/client";

import ExportMulti from "./ExportMulti";
import JoyrideTour from "../common/modals/JoyrideTour.js";
import { exportNotesSteps } from "../../assets/data/JoyrideTourSteps.js";
import PageTitle from "../layout/PageTitle";
import FullScreenLoading from "../common/loading/FullScreenLoading";
import Tabs from "../common/tabs/Tabs";
import {
  useVirtualizer,
  useWindowVirtualizer,
} from "@tanstack/react-virtual";
import { NOTE_FIELDS } from "../../graphql/fragments/note";

const getUsersApprovedNotes = gql`
  ${NOTE_FIELDS}
  query getUsersNotes($isApproved: Boolean) {
    user {
      notes(isApproved: $isApproved, isTagged: true) {
        patient {
          name
          lastNameOfOwner
          species
          sex
          uuid
        }
        ...NoteFields
        keyHistory
        keyMedications
        vaccinationStatus
        weight
      }
    }
  }
`;

function RowVirtualizerDynamic({
  items,
  refetch,
  isAlreadyExported,
}) {
  const parentRef = React.useRef();

  const virtualizer = useVirtualizer({
    count: items?.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 220,
  });

  if (!items) {
    return null;
  }

  return (
    <div
      ref={parentRef}
      className="List"
      style={{
        height: 600,
        overflowY: "auto",
      }}
    >
      <div
        style={{
          height: virtualizer.getTotalSize(),
          width: "100%",
          position: "relative",
        }}
      >
        {virtualizer.getVirtualItems().map((virtualRow) => (
          <div
            key={virtualRow.key}
            data-index={virtualRow.index}
            ref={virtualizer.measureElement}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${virtualRow.start}px)`,
            }}
          >
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={virtualizer.measureElement}
              className="py-2"
            >
              <ExportNoteItem
                key={virtualRow.key}
                exportNoteData={items[virtualRow.index]}
                refetch={refetch}
                isAlreadyExported={isAlreadyExported}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function EmptyState({ prevExp }) {
  return (
    <div className="p-5 h-20">
      <div className="flex flex-col self-center justify-self-center space-y-5">
        <h4 className="text-center text-base text-gray-500 dark:text-gray-400">
          There&apos;s nothing here yet!
        </h4>
        <h5 className="text-center text-sm text-gray-400 dark:text-gray-500">
          {prevExp
            ? "Looks like you haven't exported any notes yet!"
            : "New notes will appear here when they are ready to be exported."}
        </h5>
      </div>
    </div>
  );
}

function ExportMultiButton({ items, refetch }) {
  return (
    <div className="fixed flex flex-col items-center justify-center left-0 bottom-0 z-40 w-full pointer-events-none">
      <div className="bg-indigo-50 dark:bg-gray-800/80 backdrop-blur-md shadow-lg rounded-t-xl pointer-events-auto flex items-center justify-center w-64 p-4">
        <div
          id="exportAllButton"
          className="border-8 border-white dark:border-gray-800 rounded-full"
        >
          <ExportMulti data={items} refetch={refetch} />
        </div>
      </div>
    </div>
  );
}

function TabWrapper({ children, items, refetch }) {
  return (
    <div className="relative">
      <div
        className={`relative border-2 border-gray-200 dark:border-gray-700/50 rounded-xl h-[600px] px-4 pt-4 pb-8 space-y-3 ${
          items?.length > 0 ? "" : "flex items-center justify-center"
        }`}
      >
        {children}
      </div>
      {items?.length > 0 ? (
        <div className="w-full pt-6 absolute -bottom-8 z-20 flex items-center justify-center">
          <div
            id="exportAllButton"
            className="border-8 border-white dark:border-gray-800 rounded-full"
          >
            <ExportMulti data={items} refetch={refetch} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default function ExportNotes() {
  const [activeTab, setActiveTab] = useState("newNotesSection");

  const { data, refetch, loading } = useQuery(getUsersApprovedNotes, {
    variables: { isApproved: true },
  });

  const [runJoyrideTour, setRunJoyrideTour] = useState(false);
  const [unexportedNotes, setUnexportedNotes] = useState([]);
  const [previouslyExportedNotes, setPreviouslyExportedNotes] =
    useState([]);

  useEffect(() => {
    // Without this happening in state, refetch goes super slow!
    let unexportedNotesTemp = [];
    let previouslyExportedNotesTemp = [];

    data?.user?.notes.forEach((noteData, index) => {
      if (!noteData.hasBeenExported) {
        unexportedNotesTemp.push(noteData);
      } else {
        previouslyExportedNotesTemp.push(noteData);
      }
    });

    setUnexportedNotes(unexportedNotesTemp);
    setPreviouslyExportedNotes(previouslyExportedNotesTemp);
  }, [data]);

  if (!loading && !data) {
    return null;
  }

  return (
    <div className="">
      <FullScreenLoading loadingTrigger={loading} />
      <JoyrideTour
        steps={exportNotesSteps}
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
      />

      <PageTitle
        title="Export Notes"
        onClick={() => {
          setRunJoyrideTour(true);
        }}
        showHelpButton
      />

      <div className="mt-6">
        <Tabs
          id="exportNotesTabs"
          tabArray={[
            {
              id: "newNotesSection",
              title: "New Notes",
              count: unexportedNotes?.length,
            },
            {
              id: "prevExportedNotesSection",
              title: "Previously Exported Notes",
              count: previouslyExportedNotes?.length,
            },
          ]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        >
          <div
            className={
              activeTab === "newNotesSection" ? "block" : "hidden"
            }
            id="newNotesSection"
          >
            {activeTab === "newNotesSection" ? (
              // <TabWrapper items={unexportedNotes} refetch={refetch}>
              <div className="border-t-2 dark:border-gray-700/50 rounded-xl shadow-md px-4 pb-6">
                {unexportedNotes?.length ? (
                  <>
                    <ExportMultiButton
                      items={unexportedNotes}
                      refetch={refetch}
                    />
                    <RowVirtualizerDynamic
                      items={unexportedNotes}
                      refetch={refetch}
                      isAlreadyExported={true}
                    />
                  </>
                ) : (
                  <EmptyState />
                )}
              </div>
            ) : // </TabWrapper>
            null}
          </div>

          <div
            className={
              activeTab === "prevExportedNotesSection"
                ? "block"
                : "hidden"
            }
            id="prevExportedNotesSection"
          >
            {activeTab === "prevExportedNotesSection" ? (
              // <TabWrapper
              //   items={previouslyExportedNotes}
              //   refetch={refetch}
              // >
              <div className="border-t-2 dark:border-gray-700/50 rounded-xl shadow-md px-4 pb-6">
                {previouslyExportedNotes?.length ? (
                  <>
                    <ExportMultiButton
                      items={previouslyExportedNotes}
                      refetch={refetch}
                    />
                    <RowVirtualizerDynamic
                      items={previouslyExportedNotes}
                      refetch={refetch}
                      isAlreadyExported={false}
                    />
                  </>
                ) : (
                  <EmptyState prevExp />
                )}
              </div>
            ) : // </TabWrapper>
            null}
          </div>
        </Tabs>
      </div>
    </div>
  );
}
