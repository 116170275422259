import { gql } from "@apollo/client";

export const MODIFY_NOTE = gql`
  mutation modifyNote($noteInput: NoteInput) {
    modifyNote(noteInput: $noteInput) {
      note {
        uuid
      }
      successMessage
    }
  }
`;

export const CREATE_PATIENT_NOTE = gql`
  mutation createPatientNote($noteInput: NoteInput) {
    createPatientNote(noteInput: $noteInput) {
      note {
        uuid
      }
      successMessage
    }
  }
`;

export const CREATE_QUICK_NOTE = gql`
  mutation createQuickNote($noteInput: NoteInput) {
    createQuickNote(noteInput: $noteInput) {
      note {
        uuid
      }
      successMessage
    }
  }
`;

export const APPEND_RECORDING = gql`
  mutation appendRecording(
    $noteUuid: String
    $storageObjectName: String
    $createdAt: DateTime
  ) {
    appendRecording(
      noteUuid: $noteUuid
      storageObjectName: $storageObjectName
      createdAt: $createdAt
    ) {
      ok
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation deleteNote($noteUuid: String) {
    deleteNote(noteUuid: $noteUuid) {
      ok
    }
  }
`;

export const DELETE_NOTES = gql`
  mutation deleteNotes($noteUuids: [String]) {
    deleteNotes(noteUuids: $noteUuids) {
      ok
    }
  }
`;
