import React from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import PredictiveInput from "../../../common/inputs/PredictiveInput";
import { defaultList } from "../../../../assets/data/Dictionary";

export function AdditionalFieldInput({
  fieldName,
  control,
  title,
  placeholder = null,
}) {
  const { field } = useController({
    name: fieldName,
    control,
  });
  return (
    <>
      <h4 className="text-gray-600 dark:text-gray-300">{title}</h4>
      <PredictiveInput
        inputClassName={`p-3 my-2 relative border-2 border-transparent rounded-lg w-full text-xs md:text-sm h-11 z-0 align-top bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 focus:ring-2 focus:ring-indigo-300 resize-none`}
        positionStyles="p-3 my-2 h-11 border-2 border-transparent"
        placeholder={placeholder}
        field={field}
        dictionary={defaultList}
      />
    </>
  );
}

AdditionalFieldInput.propTypes = {
  control: PropTypes.any,
  title: PropTypes.node,
  fieldName: PropTypes.string,
  placeholder: PropTypes.string,
};
