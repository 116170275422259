import React, { useState } from "react";
import { useSpring, animated, config } from "react-spring";

export default function Tooltip({
  delay,
  children,
  content,
  disabled,
  tooltipContainerClassName,
}) {
  let timeout;
  const [active, setActive] = useState(false);
  const tooltipAnim = useSpring({
    config: config.wobbly,
    opacity: active ? 1 : 0,
    transform: active ? "scale(1)" : "scale(0.8)",
  });

  const showTip = () => {
    timeout = setTimeout(() => {
      if (!disabled) {
        setActive(true);
      }
    }, delay || 0);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      className={tooltipContainerClassName}
    >
      {/* Wrapping */}
      {children}
      {active && (
        <animated.div style={tooltipAnim} className="absolute z-50">
          {/* Content */}
          {content}
        </animated.div>
      )}
    </div>
  );
}

export function TooltipContainer({ children, positioningClasses }) {
  return (
    <div
      className={`p-2 bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-500 rounded-lg transform ${positioningClasses} text-sm dark:text-gray-300`}
    >
      {children}
    </div>
  );
}
