import React from "react";
import { useParams } from "react-router";

import EditableTemplate from "./EditableTemplate.js";
import PageTitle from "../layout/PageTitle";
import { useQuery } from "@apollo/client";
import { GET_TEMPLATE_BY_UUID } from "../../graphql/queries/GetTemplate.js";
import { AutosaveProvider } from "../../hooks/use-autosave.js";

export default function EditTemplate() {
  const { uuid } = useParams();
  const { data } = useQuery(GET_TEMPLATE_BY_UUID, {
    variables: { uuid },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <PageTitle className="mb-6" title="Edit Template" />
      {data?.template ? (
        <AutosaveProvider>
          <EditableTemplate template={data?.template} />
        </AutosaveProvider>
      ) : null}
    </div>
  );
}
