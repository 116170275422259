import React, { useRef, useEffect } from "react";
import { VariableSizeList as List } from "react-window";
import { useNavigate } from "react-router";
import NapTime from "../../../assets/imgs/otto-scribbles-sleep.svg";
import NoteItem from "./NoteItem.js";
import { getNoteItemSize } from "../../inbox/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import LoadMore from "../../common/inbox/LoadMore";

function EmptyState() {
  return (
    <div className="p-5 h-full flex">
      <div className="flex flex-col self-center justify-self-center space-y-5">
        <div className="flex self-center rounded-full w-30 h-30 bg-gray-200"></div>
        <img
          className="flex self-center"
          width="280px"
          src={NapTime}
        />

        <h4 className="text-center text-sm text-gray-500 dark:text-gray-400">
          Take a paws...
        </h4>
        <h5 className="text-center text-xs text-gray-400 dark:text-gray-500">
          There are no new notes for you to catch up on for now. Go on
          and enjoy the rest of your day! <br /> (and let Otto and
          Scribbles catch some Z&apos;s)
        </h5>
      </div>
    </div>
  );
}

function NoteList(props) {
  const { items, incrementOffset, loadingMore, totalNotes } = props;
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef?.current) {
      listRef?.current.resetAfterIndex(0, true);
    }
  }, [items]);

  const getItemSize = (index) => getNoteItemSize(items, index);

  const renderNoteItem = ({ index, style }) => {
    const note = items[index];
    const itemStyle = {
      ...style,
      height: style?.height,
    };

    if (index == items.length && totalNotes <= items.length) {
      // Only grow the index if there are more notes to load
      return null;
    }

    if (index == items.length && totalNotes > items.length) {
      return (
        <LoadMore
          itemStyle={itemStyle}
          incrementOffset={incrementOffset}
          loadingMore={loadingMore}
        />
      );
    }

    return (
      <div style={itemStyle} className="pt-4">
        <NoteItem dashboard={true} note={note} {...props} />
      </div>
    );
  };

  return (
    <div className="h-full">
      <div className="z-50 bg-gradient-to-t from-white via-white dark:from-gray-800 dark:via-gray-800 absolute bottom-2 w-full flex justify-center pt-10 px-5"></div>
      {!items?.length ? (
        <div className="w-[85%] h-[85%] mx-auto">
          <EmptyState />
        </div>
      ) : (
        <div className="relative flex flex-col justify-center items-center w-full pt-5">
          <div className="z-10 bg-gradient-to-b from-white via-white dark:from-gray-800 dark:via-gray-800 absolute top-1 w-full flex justify-center pt-10 px-5"></div>

          <List
            ref={listRef}
            height={400}
            width="95%"
            // When infinite scrolling is implemented,
            // this will need to be updated to the total number of notes (no + 1).
            // The plus one is only here to account for the "Load More" button
            itemCount={items?.length + 1}
            itemSize={getItemSize}
          >
            {renderNoteItem}
          </List>
        </div>
      )}
    </div>
  );
}

export default NoteList;
