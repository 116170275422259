import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { ENVIRONMENT, SCRIBENOTE_FLASK } from "../constants";
import { useAuth } from "./use-auth";

export function usePorterMigrationFlag() {
  const { porterApiSwitch } = useFlags();

  useEffect(() => {
    // If the user is authenticated, this runs every time the porterApiSwitch is toggled
    // Since that flag is active only for authenticated users.
    let host = "";

    if (porterApiSwitch) {
      if (ENVIRONMENT === "prod") {
        host = "api-prod-next";
      } else if (ENVIRONMENT === "qa") {
        host = "api-qa-next";
      }

      host = `https://${host}.scribenote.com`;
    }

    if (!host) {
      host = SCRIBENOTE_FLASK;
    }

    localStorage.setItem("SCRIBENOTE_API_HOST", host);
  }, [porterApiSwitch]);

  return null;
}
