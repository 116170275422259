import React from "react";
import { getPatientIntactText } from "../utils";

export function SexSelectionButton({ type, sex, setSex, dataCy }) {
  const femaleClassName = `${
    sex === "f" && "bg-pink-500 text-white"
  } flex-1 text-center dark:text-gray-200 hover:bg-pink-400 hover:text-white border border-pink-500 rounded-full px-3 py-1 cursor-pointer mx-1 my-1 transition-all`;
  const maleClassName = `${
    sex === "m" && "bg-blue-500 text-white"
  } flex-1 text-center dark:text-gray-200 hover:bg-blue-400 hover:text-white border border-blue-500 rounded-full px-3 py-1 cursor-pointer mx-1 my-1 transition-all`;
  return (
    <div
      className={type === "female" ? femaleClassName : maleClassName}
      data-cy={dataCy ? dataCy : "pSexFemale"}
      onClick={() => {
        type === "female" ? setSex("f") : setSex("m");
      }}
    >
      {type === "female" ? "♀ Female" : "♂ Male"}
    </div>
  );
}

export function IntactCheckbox({ sex, intact, setIntact }) {
  return (
    <label className="my-3 dark:text-gray-300">
      <input
        className="mr-2 rounded-md align-middle text-indigo-500 focus:ring-0"
        type="checkbox"
        defaultChecked={!intact}
        onChange={() => setIntact(!intact)}
      />
      {getPatientIntactText({ sex, intact, isForEdit: true })}
    </label>
  );
}
