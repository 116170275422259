import React, { useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  faCopy,
  faFilePdf,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { NOTE_FIELDS } from "../../graphql/fragments/note";
import {
  checkisAllowedAndDownload,
  downloadClientSummary,
} from "../common/clinical/NotePDF";
import { GET_HEADER_INFO } from "../notebook/note/ExportToPDF";
import { copyAll, copyClientSummary } from "../notebook/note/utils";

const GET_NOTE = gql`
  ${NOTE_FIELDS}
  query getNote($uuid: String) {
    note(uuid: $uuid) {
      patient {
        name
        lastNameOfOwner
        sex
        species
      }
      user {
        firstName
        lastName
        name
        account {
          logoFileLink
          headerInfoPdf
        }
      }
      ...NoteFields
      keyHistory
      keyMedications
      vaccinationStatus
    }
  }
`;

function ExportOptionButton({ children, onClick, cooldown = false }) {
  const [isCooldown, setIsCooldown] = useState(false);

  const handleClick = () => {
    if (!isCooldown) {
      onClick();
      if (cooldown) {
        setIsCooldown(true);
        setTimeout(() => {
          setIsCooldown(false);
        }, 5000);
      }
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`flex-1 rounded-full shadow-md bg-indigo-500 ${
        isCooldown
          ? "cursor-not-allowed opacity-50"
          : "hover:bg-indigo-600 dark:hover:bg-indigo-600"
      } p-2 text-white font-semibold transition-colors`}
      disabled={isCooldown}
    >
      {children}
    </button>
  );
}

export function ExportNoteInline({
  exportExpanded,
  getValues,
  isClientSummaryMode = false,
  frontmatter,
  backmatter,
  clientSummary,
  note,
}) {
  const { noteUUID } = useParams();

  const [getNote] = useLazyQuery(GET_NOTE, {
    fetchPolicy: "network-only",
  });

  const handleExportButton = () => {
    getNote({
      variables: {
        uuid: noteUUID,
      },
    })
      .then((response) => {
        if (isClientSummaryMode) {
          downloadClientSummary(
            frontmatter,
            backmatter,
            clientSummary,
            response?.data?.note?.user,
            response?.data?.note?.patient,
            response?.data?.note,
          );
        } else {
          checkisAllowedAndDownload(
            response?.data?.note,
            response?.data?.note?.patient,
            response?.data?.note?.user,
          ).catch((response) => {
            console.log(response);
          });
        }
      })
      .catch((e) => {
        alert(
          "error",
          "An error has occurred. Please try again later.",
        );
        console.log(e);
      });
  };

  const handleCopyText = () => {
    let newestNoteData = note;
    try {
      newestNoteData = getValues();
    } catch (error) {
      console.log(error);
    }

    if (isClientSummaryMode) {
      copyClientSummary({
        frontmatter: frontmatter,
        backmatter: backmatter,
        clientSummary: clientSummary,
      });
    } else {
      copyAll({
        note: newestNoteData,
        isForSharedExport: true,
      });
    }
  };

  return (
    <div className="flex-1 w-full flex flex-row items-center space-x-4 mb-4">
      <ExportOptionButton onClick={handleCopyText}>
        <FontAwesomeIcon icon={faCopy} /> Copy Text
      </ExportOptionButton>
      <ExportOptionButton
        onClick={handleExportButton}
        cooldown={true}
      >
        <FontAwesomeIcon icon={faFilePdf} /> Download PDF
      </ExportOptionButton>
    </div>
  );
}
