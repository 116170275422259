import React from "react";
import { useNavigate } from "react-router-dom";
import Twemoji from "../../Twemoji.js";
import { species as speciesObj } from "../patients/utils.js";

export default function PatientTitle({
  sex,
  species,
  name,
  lastNameOfOwner = "",
  size,
  patientUUID,
}) {
  let textSize = "text-lg md:text-xl";
  if (size == "lg") {
    textSize = "text-xl md:text-3xl";
  } else if (size == "sm") {
    textSize = "text-sm";
  } else if (size == "xs") {
    textSize = "text-xs";
  } else if (size == "xxl") {
    textSize = "text-2xl md:text-4xl";
  }

  const navigate = useNavigate();
  return (
    <span
      onClick={() => navigate(`/notebook/${patientUUID}/`)}
      className={
        sex == "f"
          ? `${textSize} text-pink-400 font-bold cursor-pointer`
          : sex == "m"
          ? `${textSize} text-blue-400 font-bold cursor-pointer`
          : `${textSize} text-indigo-400 font-bold cursor-pointer`
      }
    >
      {name} {lastNameOfOwner}{" "}
      <Twemoji
        emoji={
          speciesObj[species]?.icon ? speciesObj[species]?.icon : "🦄"
        }
      />
    </span>
  );
}
