import React from "react";
import { useNavigate } from "react-router-dom";
import HeaderSection from "./HeaderSection.js";
import FullScreenLoading from "../../common/loading/FullScreenLoading.js";
import NewNoteButton from "./NewNoteButton.js";
import Note from "./Note.js";
import NoteMultiSelector from "./NoteMultiSelector.js";
import { DeleteMultiModal } from "../../common/multiselect/DeleteMultiModal.js";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { MultiSelectActionContainer } from "../../common/multiselect/MultiSelectActionContainer.js";
import { QuickActionInstructionText } from "../../common/multiselect/QuickActionInstructionText.js";
import mixpanel from "mixpanel-browser";

function EmptyState() {
  return (
    <div key={0}>
      <div className="p-5 bg-gray-50 dark:bg-gray-700 rounded-xl">
        <div className="flex flex-col self-center justify-self-center space-y-5">
          <h4 className="text-center text-base text-gray-500 dark:text-gray-400">
            There&apos;s nothing here yet!
          </h4>
          <h5 className="text-center text-xs md:text-sm text-gray-400 dark:text-gray-500">
            You have not completed any notes for this patient yet.{" "}
            <br /> Try recording one above, then check the Inbox on
            your Dashboard to begin editing it, or click{" "}
            <strong>+ New Typed Note</strong> above to create a new
            note without a recording.
          </h5>
        </div>
      </div>
    </div>
  );
}

function Notes(props) {
  const navigate = useNavigate();
  const {
    patientUUID,
    isPageLoading,
    isRecording,
    patient,
    refetch,
  } = props;

  let listItems = null;

  if (patient) {
    if (patient?.notes?.length === 0) {
      listItems = (
        <div key={0}>
          <EmptyState />
        </div>
      );
    } else {
      listItems = patient?.notes?.map((note, index) => (
        <div
          key={index}
          className="flex flex-row w-full"
          id={`notesCard-${index}`}
        >
          <NoteMultiSelector
            checkBoxItemUuid={note?.uuid}
            icon={faBolt}
            tooltipText="Select this note for ⚡️ Quick Actions"
            isPatient={note?.patient ? true : false}
          >
            <Note
              note={note}
              user={patient?.user}
              patientData={patient}
            />
          </NoteMultiSelector>
        </div>
      ));
    }
  }

  return (
    <div>
      <DeleteMultiModal notes={patient?.notes} refetch={refetch} />
      <HeaderSection {...props} />
      <FullScreenLoading loadingTrigger={isPageLoading} />
      {listItems?.length > 0 ? <QuickActionInstructionText /> : null}
      <div className="w-full mb-4" id="newNotePatientNotebook">
        <NewNoteButton
          onClick={() => {
            mixpanel.track("New Patient Note");
            navigate(`/new_note/patient/${patientUUID}`);
          }}
          disabled={isRecording}
        />
      </div>

      <div>
        <ul className="space-y-4">{listItems}</ul>
      </div>
      <MultiSelectActionContainer notes={patient?.notes} />
    </div>
  );
}

export default Notes;
