import React from "react";
import { animated, useSpring, config } from "react-spring";

export default function ToggleSwitch({
  toggleState,
  setToggleState,
  toggleCallback = null,
}) {
  const toggleAnim = useSpring({
    config: config.stiff,
    transform: toggleState ? "translateX(16px)" : "translateX(0px)",
  });

  function handleToggle() {
    setToggleState(!toggleState);

    if (toggleCallback !== null) {
      toggleCallback();
    }
  }
  return (
    <div
      className={`flex flex-row rounded-full self-center p-1 ${
        toggleState
          ? "bg-indigo-400 dark:bg-indigo-500"
          : "bg-gray-300"
      }  dark:bg-gray-700 items-center w-10 cursor-pointer`}
      onClick={handleToggle}
    >
      <animated.div
        style={toggleAnim}
        className="h-4 w-4 rounded-full bg-white flex items-center justify-center"
      ></animated.div>
    </div>
  );
}
