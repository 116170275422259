import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import {
  useFormContext,
  useWatch,
  Controller,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import ToggleSwitch from "../../../common/buttons/ToggleSwitch";

export function ObjectiveToggles({
  isEditFieldModeActive,
  setIsEditFieldModeActive,
  note,
}) {
  const { control, setValue } = useFormContext();
  const shouldUseGenericObjective = useWatch({
    control,
    name: "shouldUseGenericObjective",
  });

  return (
    <div className="flex flex-row items-center justify-end space-x-4 md:space-x-6">
      {!shouldUseGenericObjective ? (
        <div
          id="editFieldsToggle"
          className="flex flex-col md:flex-row items-center space-y-1 md:space-x-2"
        >
          <h5 className="text-xs md:text-sm">Edit Fields </h5>
          <ToggleSwitch
            toggleState={isEditFieldModeActive}
            setToggleState={setIsEditFieldModeActive}
          />{" "}
        </div>
      ) : null}

      {note?.isAutoSoap ? null : (
        <div
          id="useBlankObjectiveToggle"
          className="flex flex-col md:flex-row items-center space-y-1 md:space-x-2"
        >
          <h5 className="text-xs md:text-sm">Use Blank</h5>

          <Controller
            control={control}
            rules={{
              required: false,
            }}
            render={({
              field: { onChange, value, defaultValue },
            }) => (
              <ToggleSwitch
                toggleState={shouldUseGenericObjective}
                setToggleState={() => {
                  onChange(!shouldUseGenericObjective);
                }}
              />
            )}
            name="shouldUseGenericObjective"
          />
        </div>
      )}
    </div>
  );
}
