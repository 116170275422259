import React, { useState, createContext, useContext } from "react";

const accountContext = createContext();

export const useAccount = () => {
  return useContext(accountContext);
};

export function useProvideAccount() {
  const [isUserConfirmed, setIsUserConfirmed] = useState(
    localStorage.getItem("is_user_confirmed"),
  );
  const [isUserSubscribed, setIsUserSubscribed] = useState(
    localStorage.getItem("is_user_subscribed"),
  );
  const [isUserOnFreeTrial, setIsUserOnFreeTrial] = useState(
    localStorage.getItem("is_user_on_free_trial"),
  );
  const [accountType, setAccountType] = useState(
    localStorage.getItem("user_account_type"),
  );

  const removeLocalStorageAccount = () => {
    localStorage.removeItem("is_user_confirmed");
    localStorage.removeItem("is_user_subscribed");
    localStorage.removeItem("user_account_type");
    localStorage.removeItem("is_user_on_free_trial");

    setIsUserConfirmed(false);
    setIsUserSubscribed(false);
    setAccountType(false);
  };

  function updateAccountContext({
    accountType = null,
    isSubscribed = null,
    isUserOnFreeTrial = null,
    isConfirmed = null,
  }) {
    /** 
    NOTE: There is no mutation associated with isSubscribed and accountType
    since that is supposed to be handled by the stripe webhook.
    If isSubscribed and accountType aren't saving locally, 
    ensure the webhook is running.
    */
    if (isConfirmed !== null) {
      localStorage.setItem("is_user_confirmed", isConfirmed);
      setIsUserConfirmed(isConfirmed);
    }

    if (isSubscribed !== null) {
      localStorage.setItem("is_user_subscribed", isSubscribed);
      setIsUserSubscribed(isSubscribed);
    }

    if (isUserOnFreeTrial !== null) {
      localStorage.setItem(
        "is_user_on_free_trial",
        isUserOnFreeTrial,
      );
      setIsUserOnFreeTrial(isUserOnFreeTrial);
    }

    if (accountType) {
      localStorage.setItem("user_account_type", accountType);
      setAccountType(accountType);
    }
  }

  // Since these can be strings, this has caused/has the potential to cause more
  // type errors. Instead, force these hook values to be boolean by comparing them
  // to boolean true or string true
  return {
    isConfirmed:
      isUserConfirmed === true || isUserConfirmed === "true",
    isSubscribed:
      isUserSubscribed === true || isUserSubscribed === "true",
    isUserOnFreeTrial:
      isUserOnFreeTrial === true || isUserOnFreeTrial === "true",
    accountType,
    setAccountType,
    removeLocalStorageAccount,
    updateAccountContext,
  };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAccount().
export function AccountProvider({ children }) {
  const account = useProvideAccount();

  return (
    <accountContext.Provider value={account}>
      {children}
    </accountContext.Provider>
  );
}
