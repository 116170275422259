import React, { useState, useEffect } from "react";
import { NORMAL_ON_EXAM } from "../../../constants.js";
import { Controller } from "react-hook-form";
import PredictiveInput from "../../common/inputs/PredictiveInput";
import { defaultList } from "../../../assets/data/Dictionary.js";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useWindowDimensions } from "../../../hooks";

const SYMMETRY_TYPES = ["None", "L/R", "OS/OD", "AS/AD"];

export default function CustomObjectiveFieldItem({
  id,
  customFieldName,
  customFieldValue,
  customFieldSymmetricalValueLeft,
  customFieldSymmetricalValueRight,
  customFieldSymmetryType,
  symmetryType = "None",
  symmetryLabels,
  control,
  setValue,
  isEditFieldModeActive,
}) {
  const [symmetry, setSymmetry] = useState(
    symmetryType !== "None" ? true : false,
  );

  const [symmetryTypeSymbols, setSymmetryTypeSymbols] =
    useState(symmetryLabels);
  const { width } = useWindowDimensions();

  useEffect(() => {
    /**
     *  If symmetry changes from symmetrical to non-symmetrical.
     *    We want to update the customFieldValue since the user might have typed in it.
     *    It would be confusing to have a field value AND a left and right value in the DB. Instead,
     *    clear the value of the customFieldValue so that only left and right symmetry values are present.
     *
     * If symmetry changes from non-symmetrical to symmetrical.
     *    We want to update the customFieldValueLeft/Right so that they aren't in the database alongside
     *    a non-symmetrical custom field.
     */

    if (symmetry) {
      setValue(customFieldValue, "");
    } else {
      setValue(customFieldSymmetricalValueRight, "");
      setValue(customFieldSymmetricalValueLeft, "");
    }
  }, [symmetry]);

  return (
    <div
      id={id}
      className="flex flex-row items-end dark:border-gray-600 space-x-4 my-1 w-full bg-pink-400/10 dark:bg-white/10 rounded-lg p-2"
    >
      <Controller
        name={customFieldName}
        control={control}
        rules={{ required: true, minLength: 1 }}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextareaAutosize
            minRows={0}
            readOnly={!isEditFieldModeActive}
            disabled={!isEditFieldModeActive} // Required to allow tabbing past it during note creation
            className="w-24 md:w-44 max-w-sm overflow-y-scroll max-h-16 bg-transparent border-b dark:border-gray-500 border-pink-200 font-semibold text-pink-500 dark:text-gray-300 text-xs md:text-sm pt-1 md:pt-0 resize-none"
            onChange={(value) => onChange(value)}
            value={value}
          />
        )}
      />

      <div className="flex md:flex-1 w-full flex-col md:flex-row md:items-end md:space-x-4">
        <div className="flex md:flex-1 w-full flex-row items-end border-b border-pink-200 dark:border-gray-500">
          {symmetry && (
            <h4 className="text-gray-500 text-xs md:text-sm">
              {symmetryTypeSymbols?.left}
            </h4>
          )}
          {!symmetry ? (
            <Controller
              name={customFieldValue}
              control={control}
              render={({ field }) => (
                <PredictiveInput
                  inputClassName={`w-full px-2 font-normal text-xs md:text-sm bg-transparent resize-none`}
                  positionStyles="px-2"
                  placeholder={NORMAL_ON_EXAM}
                  field={field}
                  dictionary={defaultList}
                />
              )}
            />
          ) : (
            <Controller
              name={customFieldSymmetricalValueLeft}
              control={control}
              render={({ field }) => (
                <PredictiveInput
                  inputClassName={`w-full px-2 font-normal text-xs md:text-sm bg-transparent resize-none`}
                  positionStyles="px-2"
                  placeholder={NORMAL_ON_EXAM}
                  field={field}
                  dictionary={defaultList}
                />
              )}
            />
          )}
        </div>

        {symmetry && (
          <div className="flex flex-1 w-full flex-row items-end border-b border-pink-200 dark:border-gray-500">
            <h4 className="text-gray-500 text-xs md:text-sm">
              {symmetryTypeSymbols?.right}
            </h4>
            <Controller
              name={customFieldSymmetricalValueRight}
              control={control}
              render={({ field }) => (
                <PredictiveInput
                  inputClassName={`w-full px-2 font-normal text-xs md:text-sm bg-transparent resize-none`}
                  positionStyles="px-2"
                  placeholder={NORMAL_ON_EXAM}
                  field={field}
                  dictionary={defaultList}
                />
              )}
            />
          </div>
        )}
        {isEditFieldModeActive ? (
          <div className="space-x-2 flex flex-row items-center">
            <Controller
              name={customFieldSymmetryType}
              control={control}
              render={({ field: { onChange } }) => (
                <select
                  className="p-1 font-normal text-xs md:text-sm flex-1 mt-1 border-pink-400 rounded-lg bg-white dark:bg-gray-800"
                  name="symmetryType"
                  defaultValue={symmetryType}
                  onChange={(e) => {
                    onChange(e.target.value);
                    switch (e.target.value) {
                      case "L/R":
                        setSymmetryTypeSymbols({
                          left: "L",
                          right: "R",
                        });
                        setSymmetry(true);
                        break;
                      case "OS/OD":
                        setSymmetryTypeSymbols({
                          left: "OS",
                          right: "OD",
                        });
                        setSymmetry(true);
                        break;
                      case "AS/AD":
                        setSymmetryTypeSymbols({
                          left: "AS",
                          right: "AD",
                        });
                        setSymmetry(true);
                        break;
                      default:
                        setSymmetryTypeSymbols({
                          left: "",
                          right: "",
                        });
                        setSymmetry(false);
                        break;
                    }
                  }}
                >
                  {SYMMETRY_TYPES.map((item) => (
                    <option key={item} value={item}>
                      {width < 800 && "Symmetry: "}
                      {item}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
