import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { gql, useMutation } from "@apollo/client";
import { useSpring, animated, config } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { TEMPLATE_COLOR_SCHEMES } from "../../constants.js";
import { alert } from "../common/Alert.js";
import { ConfirmModal } from "../common/modals/PopupModals.js";
import TemplateSelectContext from "../../context/templateSelectContext.js";

const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($templateUuid: String) {
    deleteTemplate(templateUuid: $templateUuid) {
      ok
    }
  }
`;

export function TemplateItem({
  uuid = null,
  name,
  description,
  colorScheme,
  isPreset,
  refetch,
  isForTemplateSelection,
}) {
  const [
    isTemplateItemBeingHoveredOver,
    setIsTemplateItemBeingHoveredOver,
  ] = useState(false);
  const [isBeingDeleteHoveredOver, setIsDeleteBeingHoveredOver] =
    useState(false);
  const [
    shouldShowConfirmDeleteModal,
    setShouldShowConfirmDeleteModal,
  ] = useState(false);

  const {
    setSelectedTemplateUuid,
    setShouldShowTemplateSelectModal,
  } = useContext(TemplateSelectContext);

  const [deleteTemplate] = useMutation(DELETE_TEMPLATE);
  const navigate = useNavigate();

  const trashCanAnim = useSpring({
    config: config.stiff,
    opacity: isTemplateItemBeingHoveredOver ? 1 : 0,
    transform: isTemplateItemBeingHoveredOver
      ? "scale(1)"
      : "scale(0.8)",
  });

  let templateBodyClassName = `group relative m-1.5 rounded-xl block shadow-sm pointer-event-auto ${
    TEMPLATE_COLOR_SCHEMES[colorScheme]
      ? TEMPLATE_COLOR_SCHEMES[colorScheme]?.backgroundColor
      : "bg-gray-100 dark:bg-gray-500"
  } ${
    !isPreset || isForTemplateSelection
      ? "cursor-pointer"
      : "cursor-not-allowed"
  } ${!isForTemplateSelection ? "max-w-sm h-20" : null}`;

  let templateHeaderClassName = `rounded-lg text-center font-semibold text-lg w-full h-8 py-0.5 px-2 group-hover:opacity-60 line-clamp-1 overflow-hidden break-all transition-all ${
    TEMPLATE_COLOR_SCHEMES[colorScheme]
      ? TEMPLATE_COLOR_SCHEMES[colorScheme]?.headerColor
      : "bg-gray-300 dark:bg-gray-400"
  }`;

  return (
    <div
      className={templateBodyClassName}
      onMouseEnter={() => setIsTemplateItemBeingHoveredOver(true)}
      onMouseLeave={() => setIsTemplateItemBeingHoveredOver(false)}
      onClick={() => {
        if (isForTemplateSelection) {
          setSelectedTemplateUuid(uuid);
          setShouldShowTemplateSelectModal(false);
          return;
        }

        if (
          !isBeingDeleteHoveredOver &&
          !shouldShowConfirmDeleteModal
        ) {
          if (!isPreset) {
            if (uuid) {
              navigate(`/templates/edit/${uuid}`);
            } else {
              navigate("/templates/new");
            }
          }
        }
      }}
    >
      {isTemplateItemBeingHoveredOver &&
      uuid &&
      !isPreset &&
      !isForTemplateSelection ? (
        <animated.div
          style={trashCanAnim}
          className="absolute flex w-8 h-8 items-center justify-center -right-1 -top-1 text-white bg-red-400 border-2 border-white rounded-full z-30 cursor-pointer hover:border-red-400 pointer-events-auto"
          onClick={() => {
            setShouldShowConfirmDeleteModal(true);
          }}
          onMouseEnter={() => setIsDeleteBeingHoveredOver(true)}
          onMouseLeave={() => setIsDeleteBeingHoveredOver(false)}
        >
          <FontAwesomeIcon
            icon={faTrash}
            className="fill-current"
            size="xs"
          />
        </animated.div>
      ) : null}

      <ConfirmModal
        shouldShow={shouldShowConfirmDeleteModal}
        hideModal={() => setShouldShowConfirmDeleteModal(false)}
        title={"Delete Template"}
        cancelText={"Never Mind"}
        confirmText={"Yes, Delete"}
        cancelAction={() => setShouldShowConfirmDeleteModal(false)}
        confirmAction={async () => {
          await deleteTemplate({ variables: { templateUuid: uuid } })
            .then(() => {
              alert("success", "Template deleted successfully.");
              refetch();
            })
            .catch((error) => {
              alert("error", error.message);
            });

          setShouldShowConfirmDeleteModal(false);
        }}
      >
        <h5>
          Are you sure you&apos;d like to{" "}
          <strong className="text-red-500">
            delete this template?{" "}
          </strong>
        </h5>
        <h5 className="my-2">
          {" "}
          Template name:{" "}
          <strong className="text-indigo-500">{name}</strong>
        </h5>
      </ConfirmModal>

      <div className={templateHeaderClassName}>{name}</div>
      {description ? (
        <h5
          className={`py-1 px-4 w-full text-gray-600 dark:text-gray-100 text-xs text-center group-hover:opacity-60 transition-all line-clamp-2 overflow-hidden break-words ${
            isForTemplateSelection ? null : "h-12"
          }`}
        >
          {description}
        </h5>
      ) : (
        <h5 className="py-1 px-4 text-center text-xs text-gray-600 dark:text-gray-100 text-opacity-60 dark:text-opacity-60">
          No description added.
        </h5>
      )}
    </div>
  );
}
