import React from "react";
import { useFormContext } from "react-hook-form";
import ObjectiveFields from "../../common/ObjectiveFields";
import { SOAPInput } from "./SOAPInput";

export function ObjectiveFormSection({
  isEditFieldModeActive,
  getValues,
  onMouseUp,
}) {
  const { watch, control, setValue, resetField } = useFormContext();
  const shouldUseGenericObjective = watch(
    "shouldUseGenericObjective",
  );

  return (
    <>
      {shouldUseGenericObjective ? (
        <SOAPInput
          control={control}
          title={"Objective"}
          fieldName="objective"
          getValues={getValues}
          onMouseUp={onMouseUp}
          name="objective"
          headerStyle="bg-pink-400 dark:bg-pink-600"
          boxStyle="border-pink-300 bg-pink-50 dark:bg-pink-700/20 dark:border-pink-600"
        />
      ) : (
        <div className="border border-pink-300 bg-pink-50 dark:bg-gray-700 dark:border-pink-600 p-4 my-2 rounded-xl w-full text-sm align-top text-gray-700 dark:text-gray-300">
          <div className="absolute z-10 top-11 md:top-7 left-0 rounded-tr-full rounded-tl-full rounded-br-full bg-pink-400 dark:bg-pink-600 py-1 px-4">
            <h4 className="text-white dark:text-gray-300">
              Objective
            </h4>
          </div>
          <ObjectiveFields
            control={control}
            setValue={setValue}
            getValues={getValues}
            isEditFieldModeActive={isEditFieldModeActive}
            resetField={resetField}
          />
        </div>
      )}
    </>
  );
}
