import { React, useState } from "react";
import {
  useNoteMultiselect,
  useNoteMultiselectContext,
} from "../../../hooks";
import Twemoji from "../../../Twemoji";
import NoteCreatedAt from "../../notebook/note/NoteCreatedAt";
import { ConfirmModal } from "../modals/PopupModals";
import TeamUserDropdown from "../../layout/header/TeamUserDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { truncate } from "../../../utils";

export function TransferMultiModal({
  notes,
  shouldShowTransferModal,
  setShouldShowTransferModal,
  refetch,
  confirmAction,
  loading,
  setNewUser,
}) {
  const { numSelectedNotes, selectedNoteUuids } =
    useNoteMultiselectContext();

  const propHandleChange = (e) => {
    setNewUser(e.target.value);
  };

  return (
    <ConfirmModal
      shouldShow={shouldShowTransferModal}
      hideModal={() => setShouldShowTransferModal(false)}
      title={
        numSelectedNotes > 1
          ? "Transfer these notes?"
          : "Transfer this note?"
      }
      confirmText="Transfer"
      confirmAction={confirmAction}
      cancelAction={() => setShouldShowTransferModal(false)}
      disabled={loading}
    >
      <h5>
        Which team member would you like to{" "}
        <strong>
          transfer{" "}
          {numSelectedNotes > 1
            ? `these ${numSelectedNotes} notes`
            : "this note"}
        </strong>{" "}
        to?
      </h5>
      <div className="flex flex-row items-center space-x-2 mt-4">
        <div className="flex-1">
          {notes?.map((note) => {
            if (
              Object.keys(selectedNoteUuids)?.includes(note?.uuid)
            ) {
              return (
                <h5
                  key={note?.uuid}
                  className="text-sm py-1 border p-2 rounded-lg dark:border-gray-700/50 bg-gray-50 dark:bg-gray-700 my-1"
                >
                  <Twemoji emoji={"📝"} />{" "}
                  <span className="font-medium">
                    {note?.patient?.name || ""}{" "}
                  </span>
                  <span>
                    <span className="font-medium">
                      {truncate(note?.title, 40)}
                    </span>{" "}
                    <NoteCreatedAt
                      createdAt={note?.createdAt}
                      size="sm"
                    />
                  </span>
                </h5>
              );
            }
          })}
        </div>
        <FontAwesomeIcon
          className="dark:text-gray-700"
          icon={faArrowCircleRight}
          size="2x"
        />
        <div className="flex-1">
          <TeamUserDropdown
            disableExtraStyles={true}
            propHandleChange={propHandleChange}
          />
        </div>
      </div>
    </ConfirmModal>
  );
}
