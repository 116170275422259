import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStamp } from "@fortawesome/free-solid-svg-icons";

import TemplateSelectContext from "../../../../context/templateSelectContext.js";
import { alert } from "../../../common/Alert.js";
import { Tooltip } from "@mui/material";

export function UseTemplateButton({ noteUUID, isAutoSoap }) {
  const {
    setShouldShowTemplateSelectModal,
    setUuidOfNoteToOverwriteWithTemplate,
  } = useContext(TemplateSelectContext);

  return (
    <>
      <Tooltip
        arrow
        title={
          isAutoSoap
            ? "Templates for Auto-Notes are coming soon!"
            : "Click to select a template to apply. A template will overwrite any existing content in the note."
        }
      >
        <span>
          <button
            id="useTemplate"
            disabled={isAutoSoap}
            onClick={() => {
              setShouldShowTemplateSelectModal(true);
              alert(
                "warning",
                "Warning: selecting a template will overwrite any existing text in this note's fields.",
              );
              if (noteUUID) {
                setUuidOfNoteToOverwriteWithTemplate(noteUUID);
              }
            }}
            className="rounded-full font-semibold px-3 h-[40px] flex flex-row items-center bg-indigo-500 disabled:dark:text-gray-500 text-white hover:bg-indigo-600 transition-all focus:outline-none border border-indigo-500 disabled:bg-gray-300 disabled:dark:bg-gray-700 disabled:dark:border-gray-700 disabled:hover:bg-gray-300 disabled:border-gray-300 disabled:cursor-not-allowed shadow-md"
          >
            <FontAwesomeIcon icon={faStamp} className="md:mr-2" />
            <span className="hidden md:block whitespace-nowrap">
              Use Template
            </span>
          </button>
        </span>
      </Tooltip>
    </>
  );
}
