import { gql } from "@apollo/client";

const ADD_USERS_TO_ORG_TEAM = gql`
  mutation orgUsersToOrgTeam(
    $addUsersInput: AddUsersToOrgTeamInput!
  ) {
    addUsersToOrgTeam(addUsersInput: $addUsersInput) {
      ok
      addUsers {
        existing {
          success
          email
        }
        newUsers {
          success
          email
        }
      }
    }
  }
`;

export default ADD_USERS_TO_ORG_TEAM;
