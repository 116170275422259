import { SCRIBENOTE_FLASK } from "./constants";

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");

  if (newWindow) {
    newWindow.opener = null;
    newWindow.focus();
  }
};

export const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

/**
 * A temporary measure for determining where to send requests app wide
 * based on how a feature flag (use-porter-migration-flag) sets the
 * SCRIBENOTE_API_HOST localstorage item
 *
 *
 * @returns {string} The URL of the Flask server to use
 */
export const getTempScribenoteFlask = () => {
  // const flask_host =
  //   localStorage.getItem("SCRIBENOTE_API_HOST") || SCRIBENOTE_FLASK;

  // return flask_host;
  // Not used for now
  return SCRIBENOTE_FLASK;
};
