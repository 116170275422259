import React, { useState, useEffect } from "react";
import moment from "moment";

export const Timer = ({ start, isPaused }) => {
  const [timer, setTimer] = useState("00:00:00");

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        if (timer === "00:00:00") {
          //   const delta = moment.utc() - start;
          const delta = moment(moment.utc().diff(start))
            .utcOffset(0)
            .format("HH:mm:ss");
          setTimer(delta);
        } else {
          //   const delta = moment.utc() - start;
          const delta = moment(moment.utc().diff(start));

          const timeOnTimer = moment.duration(
            moment.utc(timer, "HH:mm:ss"),
          );

          const sumOfTimes = moment.duration(delta).add(timeOnTimer);

          const newDelta = moment
            .utc(sumOfTimes.as("milliseconds"))
            .utcOffset(0)
            .format("HH:mm:ss");

          setTimer(newDelta);
        }
      }, 500);

      return () => clearInterval(interval);
    }
  }, [isPaused]);

  return (
    <span
      className={
        "w-32 text-center text-gray-500 dark:text-indigo-50 font-medium text-xl rounded-full border-2 border-white dark:border-gray-800 bg-white dark:bg-gray-800 p-1"
      }
    >
      {timer}
    </span>
  );
};
