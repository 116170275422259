import React from "react";

export default function NewNoteButton({ onClick, disabled }) {
  return (
    <div
      id="createNewPatientSOAPButton"
      className={`${
        disabled
          ? "opacity-50 cursor-not-allowed"
          : "opacity-100 hover:bg-indigo-100 dark:hover:bg-gray-700 cursor-pointer"
      } flex items-center justify-center border-2 border-indigo-100 dark:border-gray-700 rounded-lg py-2 bg-white dark:bg-gray-800 focus:outline-none transition-colors shadow-sm`}
      onClick={disabled ? null : onClick}
    >
      <h4 className="text-gray-600 dark:text-white">
        + New Typed Note
      </h4>
    </div>
  );
}
