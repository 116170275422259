import { faCheck } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useNoteMultiselectContext } from "../../../hooks";
import Tooltip, {
  TooltipContainer,
} from "../../common/modals/Tooltip";
import { CheckBox } from "../../common/multiselect/CheckBox";

export default function NoteMultiSelector({
  checkBoxItemUuid,
  icon,
  tooltipText,
  children,
  isStyled = true,
  isPatient,
}) {
  const { selectedNoteUuids } = useNoteMultiselectContext();
  const isSelected = selectedNoteUuids[checkBoxItemUuid];

  return (
    <div
      className={
        isStyled
          ? `${
              isSelected
                ? "border-2 border-indigo-500 bg-indigo-500 dark:bg-indigo-500"
                : "border-2 border-transparent"
            } flex flex-1 pl-3 rounded-lg items-center space-x-2 w-full`
          : `flex flex-1 items-center space-x-2 w-full`
      }
    >
      <Tooltip
        content={<TooltipContainer>{tooltipText}</TooltipContainer>}
      >
        <CheckBox
          checkBoxItemUuid={checkBoxItemUuid}
          icon={isSelected ? faCheck : icon}
          isSelected={isSelected}
          isPatient={isPatient}
        />
      </Tooltip>
      {children}
    </div>
  );
}
