import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function Step({ stepNumber, currentStepNumber, stepName }) {
  return (
    <div className="flex justify-center">
      {currentStepNumber > stepNumber ? (
        <div className="mx-2 relative h-3 w-3 flex items-center justify-center">
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="1x"
            color="#6366F1"
          />
        </div>
      ) : (
        <div
          className={`h-3 w-3 ${
            currentStepNumber == stepNumber
              ? " dark:bg-indigo-400 bg-indigo-400"
              : " bg-gray-200 dark:bg-gray-600"
          } rounded-full mx-2 relative`}
        ></div>
      )}

      <h5
        className={
          currentStepNumber == stepNumber
            ? "text-center absolute transform translate-y-4 text-xs font-semibold text-gray-600 dark:text-gray-200"
            : "hidden md:block text-center absolute transform translate-y-4 text-xs text-gray-500"
        }
      >
        {stepName}
      </h5>
    </div>
  );
}

export default function ProgressIndicator({
  currentStepNumber,
  stepNames,
}) {
  return (
    <div className="flex flex-row items-start mx-auto w-full max-w-xl md:max-w-none md:w-[600px] mb-6 mt-4">
      {stepNames.map((stepName, index) => (
        <Fragment key={index}>
          <Step
            stepName={stepName}
            stepNumber={index}
            currentStepNumber={currentStepNumber}
          />
          {index !== stepNames.length - 1 ? (
            <div className="h-0.5 rounded-full w-32 bg-gray-200 dark:bg-gray-600 mt-1"></div>
          ) : null}
        </Fragment>
      ))}
    </div>
  );
}

ProgressIndicator.propTypes = {
  currentStepNumber: PropTypes.number,
  stepNames: PropTypes.array,
};

Step.propTypes = {
  currentStepNumber: PropTypes.number,
  stepName: PropTypes.string,
  stepNumber: PropTypes.number,
};
