import { gql } from "@apollo/client";

const DEACTIVATE_USER = gql`
  mutation deactivateOrgTeamUser($rmTeamUserInput: RmTeamUserInput!) {
    deactivateOrgTeamUser(rmTeamUserInput: $rmTeamUserInput) {
      ok
    }
  }
`;

export default DEACTIVATE_USER;
