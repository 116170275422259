import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useLocalStorage } from "../../hooks";

/**
 *
 * @param {*} bannerId Hides specific banners. Examples: (None) = used to hide the small screen size banner, ExistingCustomerAutoSOAPLaunchBanner = used to hide the autosoap demo banner used to show existing customers., ...
 * @returns
 */
export function PermanentlyHideableBanner({
  children,
  bannerId = null,
}) {
  const [isPermanentlyHidden, setIsPermanentlyHidden] =
    useLocalStorage(bannerId ?? "hide-banners");

  const location = useLocation();

  const disabledRoutes = [
    "/",
    "/auth/login",
    "/auth/register",
    "/registration_confirmation",
    "/subscriptions",
  ];

  if (location && disabledRoutes.includes(location.pathname)) {
    return null;
  }

  return (
    <>
      {!isPermanentlyHidden && (
        <Banner setIsPermanentlyHidden={setIsPermanentlyHidden}>
          {children}
        </Banner>
      )}
    </>
  );
}

export function Banner({
  children,
  isWarning = true,
  bgStyle,
  setIsPermanentlyHidden,
}) {
  const [shouldShowBanner, setShouldShowBanner] = useState(true);
  return (
    <div
      className={
        shouldShowBanner
          ? "flex items-center justify-center my-1"
          : "hidden"
      }
    >
      <div
        className={`rounded-xl ${
          bgStyle
            ? bgStyle
            : isWarning
            ? "bg-yellow-300"
            : "bg-red-300"
        } flex-1 flex flex-row items-center py-3 px-4 space-x-2`}
      >
        <FontAwesomeIcon
          icon={faBell}
          className="fill-current text-gray-700"
        />
        <div className="text-center text-gray-700 flex-1 text-xs md:text-base">
          {children}
        </div>
        <button
          onClick={() => {
            setShouldShowBanner(false);
            setIsPermanentlyHidden(true);
          }}
          className="justify-self-end text-gray-700 focus:outline-none hover:text-gray-500 transition-all cursor-pointer"
        >
          ✖
        </button>
      </div>
    </div>
  );
}
