import React from "react";
import PropTypes from "prop-types";
import { animated } from "react-spring";
import Twemoji from "../../../Twemoji.js";
import NoteCreatedAt from "../../notebook/note/NoteCreatedAt.js";
import PatientTitle from "../../notebook/PatientTitle";
import { RandomSuccessEmoji } from "../../common/Randomizer.js";

function CompletedSOAP({ popInAnim, note, isForDeletedNote }, ref) {
  return (
    <animated.div style={popInAnim}>
      <div
        style={{ scrollMargin: "100px" }}
        className={`flex flex-row items-center shadow-md rounded-xl ${
          isForDeletedNote
            ? "bg-red-100 dark:bg-red-900/80"
            : "bg-green-100 dark:bg-green-900/80"
        } my-5`}
        ref={ref}
      >
        <div
          className={`rounded-l-xl px-4 py-3 ${
            isForDeletedNote
              ? "bg-red-300 dark:bg-red-600/50"
              : "bg-green-300 dark:bg-green-600/50"
          }`}
        >
          <span className="text-xl">
            {isForDeletedNote ? (
              <Twemoji emoji={"🗑"} />
            ) : (
              <RandomSuccessEmoji />
            )}
          </span>
        </div>

        <div className="flex flex-row flex-1 px-5 rounded-r-xl space-x-3">
          <div className="flex flex-row items-center w-full">
            <h4 className="text-gray-700 dark:text-gray-300 mr-3 text-xl">
              {isForDeletedNote ? "Note Deleted" : "Note Saved!"}
            </h4>
            <div className="flex-1">
              {note?.patient ? (
                <span className="text-base dark:text-gray-300">
                  Note for{" "}
                  <PatientTitle
                    sex={note?.patient?.sex}
                    species={note?.patient?.species}
                    name={note?.patient?.name}
                    patientUUID={note?.patient?.uuid}
                    lastNameOfOwner={note?.patient?.lastNameOfOwner}
                  />{" "}
                </span>
              ) : (
                <span>
                  <span className="font-bold text-indigo-400 dark:text-indigo-400 text-xl">
                    Quicknote
                  </span>
                  {"   "}
                  {note?.title ? (
                    <>
                      <Twemoji emoji="📝" />{" "}
                      <span className="font-normal text-gray-500 dark:text-gray-300 text-sm">
                        {note?.title.slice(0, 25)}
                        {"... "}
                      </span>
                    </>
                  ) : null}
                </span>
              )}
            </div>
            <div className="">
              <NoteCreatedAt createdAt={note?.createdAt} />
            </div>
          </div>
        </div>
      </div>
    </animated.div>
  );
}

export default React.forwardRef(CompletedSOAP);

CompletedSOAP.propTypes = {
  popInAnim: PropTypes.any,
};
