import React from "react";
import {
  Controller,
  useFormContext,
  useWatch,
} from "react-hook-form";
import AutosuggestTextToggle from "../../common/AutocompleteTextToggle.js";
import NoteFormTypeToggle from "./NoteFormTypeToggle.js";
import FindAndReplaceToggle from "../../common/FindAndReplaceToggle.js";

export default function NoteFormOptions({ isAutoNote }) {
  const { control } = useFormContext();
  const shouldUseCustomForm = useWatch({
    control,
    name: "shouldUseCustomForm",
  });

  return (
    <>
      <div className="flex-1 flex flex-row items-center border dark:border-gray-700 rounded-lg divide-x dark:divide-gray-700 shadow-sm">
        <AutosuggestTextToggle />
        <FindAndReplaceToggle isAutoNote={isAutoNote} />
        {/* Hide the form toggle for now ONLY FOR TYPED NOTES*/}
        {isAutoNote ? null : (
          <Controller
            control={control}
            rules={{
              required: false,
            }}
            render={({ field: { onChange } }) => (
              <NoteFormTypeToggle
                onChange={onChange}
                value={shouldUseCustomForm}
              />
            )}
            name="shouldUseCustomForm"
          />
        )}
      </div>
    </>
  );
}
