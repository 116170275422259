import React from "react";

import { useQuery } from "@apollo/client";
import HeadingWrapper from "../common/HeadingWrapper";

import { TemplateList } from "./TemplateList";
import { GET_USERS_TEMPLATES } from "../../graphql/queries/GetUsersTemplates.js";

export function TemplateModalList() {
  const { data } = useQuery(GET_USERS_TEMPLATES);

  return (
    <div>
      <HeadingWrapper heading={<h3>Preset Templates</h3>} />
      <div className="flex flex-col my-2 w-5/6 mx-auto">
        <TemplateList
          data={data}
          isForTemplateSelection={true}
          isPreset={true}
        />
      </div>
      <HeadingWrapper heading={<h3>Your Templates</h3>} />

      <div className="flex flex-col my-2 w-5/6 mx-auto">
        <TemplateList
          data={data}
          isForTemplateSelection={true}
          isPreset={false}
        />
      </div>
    </div>
  );
}
