import React from "react";
import { useNavigate } from "react-router-dom";
import { NextStepButton } from "./FirstLoginTour";
import EmailPreview from "../../../assets/imgs/onboarding-email-preview.png";
import { useOnboarding } from "../../../hooks";
import Twemoji from "../../../Twemoji";
import mixpanel from "mixpanel-browser";

export default function FinalStep({ goToStep }) {
  const {
    setShouldShowFirstLoginTour,
    subscribedUserCompleteOnboarding,
  } = useOnboarding();

  const navigate = useNavigate();

  return (
    <>
      <h3 className="text-center">
        You&apos;re all set <Twemoji emoji={"🎉"} /> <br /> Check your
        email!
      </h3>
      <img
        src={EmailPreview}
        className="md:w-[500px] w-[300px] h-72 object-cover rounded-lg overflow-clip"
      />
      <div className="flex flex-col md:w-[600px] items-center text-center space-y-4">
        <p className="font-semibold">
          We&apos;ve sent your completed SOAP note to the email you
          signed up with.
        </p>
        <p className="text-xs md:text-sm">
          By default, you won&apos;t get emails sent automatically
          after completing SOAP notes. If you&apos;d like to have your
          SOAPs automatically sent to an email address of your choice,
          you can set that up later in your account settings.
        </p>
      </div>

      <NextStepButton
        onClick={() => {
          subscribedUserCompleteOnboarding();

          setShouldShowFirstLoginTour(false);
          navigate("/dashboard");
          mixpanel.track("Finished Final Onboarding Step");
        }}
        text={"Finish Tutorial"}
      />
      <button
        className="text-xs md:text-sm text-indigo-500 underline"
        onClick={() => goToStep(1)}
      >
        Want to try that again? Click here to restart the tutorial.
      </button>
    </>
  );
}
