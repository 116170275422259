import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useLazyQuery } from "@apollo/client";
import GET_ORGANIZATION_BY_ID from "../../graphql/queries/GetOrganization";
import { useNavigate, useLocation } from "react-router-dom";
import PageTitle from "../layout/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import FullScreenLoading from "../common/loading/FullScreenLoading";
import PartyScribbles from "../../assets/imgs/party-scribbles.png";
import { useAuth } from "../../hooks";

const AdminPage = () => {
  //state
  const [teams, setTeams] = useState([]);
  const [isSortReverse, setIsSortReverse] = useState(false);
  const [currentSort, setCurrentSort] = useState("");

  //hooks
  const navigate = useNavigate();
  const location = useLocation();
  const { orgUuid } = useAuth();

  //queries
  const [getOrganization, { loading, error, data }] = useLazyQuery(
    GET_ORGANIZATION_BY_ID,
    { fetchPolicy: "no-cache" },
  );

  //functions
  const fetchOrganization = useCallback(() => {
    if (orgUuid) {
      getOrganization({ variables: { uuid: orgUuid } });
    }
  }, [orgUuid, getOrganization]);

  const sortTeams = useCallback((teams, sortBy, reverse) => {
    return [...teams].sort((a, b) => {
      const aValue = a[sortBy] ?? "";
      const bValue = b[sortBy] ?? "";

      if (typeof aValue === "number" || typeof bValue === "number") {
        return reverse ? bValue - aValue : aValue - bValue;
      } else {
        return reverse
          ? bValue.localeCompare(aValue)
          : aValue.localeCompare(bValue);
      }
    });
  }, []);

  //effects
  useEffect(() => {
    fetchOrganization();
  }, [fetchOrganization]);

  useEffect(() => {
    if (data?.organization?.teams) {
      const queryParams = new URLSearchParams(location.search);
      const savedSortBy = queryParams.get("sortBy");
      const savedIsSortReverse =
        queryParams.get("isSortReverse") === "true";
      const savedScrollPosition = queryParams.get("scrollPosition");

      setTeams(data.organization.teams);

      if (savedSortBy) {
        setCurrentSort(savedSortBy);
        setIsSortReverse(savedIsSortReverse);
      }

      if (savedScrollPosition) {
        //the setTimeout seems unnecessary, but it places the scroll logic into the event queue
        //you need the call stack to clear before you can scroll so this is needed
        setTimeout(() => {
          window.scrollTo(0, parseInt(savedScrollPosition, 10));
        }, 0);
      }
    }
  }, [data, location.search]);

  const sortedTeams = useMemo(() => {
    if (currentSort) {
      return sortTeams(teams, currentSort, isSortReverse);
    }
    return teams;
  }, [teams, currentSort, isSortReverse, sortTeams]);

  //loading and error states
  if (loading) {
    return <FullScreenLoading loadingTrigger={loading} />;
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h2>Error: {error.message}</h2>
        <p>Please try again later.</p>
      </div>
    );
  }

  const handleSort = (sortBy) => {
    setIsSortReverse((prevIsSortReverse) => !prevIsSortReverse);
    setCurrentSort(sortBy);
  };

  const handleRowClick = (teamUuid) => {
    const queryParams = new URLSearchParams({
      sortBy: currentSort,
      isSortReverse: isSortReverse.toString(),
      scrollPosition: window.scrollY.toString(),
    }).toString();
    navigate(`/admin/${teamUuid}?${queryParams}`);
  };

  //render
  return (
    <>
      <PageTitle
        className="mb-6"
        title={`Manage ${
          data?.organization?.name
            ? data.organization.name
            : "Organization"
        }`}
        onClick={() => {
          alert("warning", "Tutorial coming soon!");
        }}
        showHelpButton
      />
      <h4 className="font-normal text-gray-400 dark:text-gray-500 mt-2 rounded-lg p-2 mb-2">
        Below are the teams or users that are managed under your
        organization. Click on a team to see its members and manage
        them.
      </h4>

      {sortedTeams.length === 0 ? (
        <div className="flex flex-col items-center bg-gray-100/70 dark:bg-gray-700/30 border-2 border-dashed border-gray-50 dark:border-gray-700/50 rounded-lg py-10 px-2 w-full my-4 dark:hover:bg-gray-700/50 transition-all cursor-pointer hover:bg-indigo-100 hover:border-indigo-200">
          <img
            className="flex self-center"
            width="100px"
            src={PartyScribbles}
            alt="Party Scribbles"
          />
          <h4 className="mt-4 mb-2">No one's here yet...</h4>
          <h5 className="text-gray-700 max-w-3xl">
            Looks like the party hasn&apos;t started yet. Contact
            support to add your first team!
          </h5>
        </div>
      ) : (
        <div className="bg-gray-100/70 dark:bg-gray-700/30 border border-gray-50 dark:border-gray-700/50 rounded-lg py-2 px-2 w-full my-4">
          <table className="table-auto w-full dark:text-gray-300 text-left">
            <thead>
              <tr className="border-b dark:border-gray-700/50">
                <TableHeader
                  title="Team Name"
                  sortBy="name"
                  handleSort={handleSort}
                />
                <TableHeader
                  title="Location"
                  sortBy="location"
                  handleSort={handleSort}
                />
                <TableHeader
                  title="# of Users"
                  sortBy="numUsers"
                  handleSort={handleSort}
                />
                <TableHeader
                  title="Created At"
                  sortBy="createdAt"
                  handleSort={handleSort}
                />
              </tr>
            </thead>
            <tbody>
              {sortedTeams.map((team, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowClick(team.uuid)}
                  className="cursor-pointer even:bg-transparent dark:odd:bg-gray-800 odd:bg-white dark:even:bg-transparent hover:bg-indigo-100 dark:hover:bg-indigo-700/50 rounded-lg"
                >
                  <td className="py-2 pl-2">
                    <h5>{team.name}</h5>
                  </td>
                  <td className="py-2">
                    <h5>
                      {team.location
                        ? team.location
                        : "Location not specified"}
                    </h5>
                  </td>
                  <td className="py-2">
                    <h5>{team.numUsers}</h5>
                  </td>
                  <td className="py-2">
                    <h5>
                      {moment(team.createdAt)?.isValid()
                        ? moment(team.createdAt).format("MMM Do YYYY")
                        : "Date unavailable"}
                    </h5>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

function TableHeader({ sortBy, title, handleSort }) {
  return (
    <th
      className="font-semibold text-sm cursor-pointer"
      onClick={() => handleSort(sortBy)}
    >
      {title} <FontAwesomeIcon icon={faSort} />
    </th>
  );
}

export default AdminPage;
