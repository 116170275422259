import { useLazyQuery, useQuery } from "@apollo/client";
import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./use-auth";
import query from "../graphql/queries/CurrentUser.js";

const currentUserContext = createContext();

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useCurrentUser = () => {
  return useContext(currentUserContext);
};

export function useProvideCurrentUser() {
  const [getUser, { data, refetch, loading }] = useLazyQuery(query, {
    fetchPolicy: "no-cache",
    pollInterval: 15000,
  });

  const auth = useAuth();

  useEffect(() => {
    if (auth?.isUserAuthenticated) {
      getUser();
    }
  }, [auth]);

  return {
    data,
    loading,
    refetch,
  };
}

export function CurrentUserProvider({ children }) {
  const currentUser = useProvideCurrentUser();

  return (
    <currentUserContext.Provider value={currentUser}>
      {children}
    </currentUserContext.Provider>
  );
}
