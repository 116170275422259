import React from "react";

export const Input = ({
  name,
  type,
  placeholder,
  onFocus,
  onBlur,
  required = true,
  dataCy,
}) => {
  return (
    <input
      id={name || type}
      name={name || type}
      type={type}
      required={required}
      className="appearance-none relative block w-full px-3 py-2 border bg-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-100 border-gray-100 placeholder-gray-400 dark:placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 sm:text-sm"
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      data-cy={dataCy}
    />
  );
};
