import React from "react";

import { ObjectiveTypeSeparator } from "../../../notes/common/ObjectiveTypeSeparators";
import { SoapHeader } from "../SoapHeader";
import { CustomObjectiveText } from "./CustomObjectiveText";

import { DefaultObjectiveText } from "./DefaultObjectiveText";
import {
  getObjectiveFieldsToShow,
  getObjectiveFieldsString,
} from "../utils";

export function ObjectiveBlock({ isConsise, note }) {
  let OBJECTIVE_FIELDS_TO_SHOW = getObjectiveFieldsToShow(note);

  function renderObjectiveFieldsTitle(type, title) {
    if (
      OBJECTIVE_FIELDS_TO_SHOW.filter((item) => item.type === type)
        .length > 0
    ) {
      return <ObjectiveTypeSeparator title={title} />;
    }
  }

  return (
    <div
      className={
        isConsise
          ? "px-3 py-2 border my-1 rounded-xl bg-pink-50 border-pink-400 dark:bg-pink-700/20 dark:border-pink-600"
          : "p-3 border my-1 rounded-xl bg-pink-50 border-pink-400 dark:bg-pink-700/20 dark:border-pink-600"
      }
    >
      <SoapHeader textToCopy={getObjectiveFieldsString(note)}>
        Objective
      </SoapHeader>
      <ul
        className={
          isConsise
            ? "text-xs my-1 list-none text-gray-700 dark:text-gray-300"
            : "text-sm my-3 list-none text-gray-700 dark:text-gray-300"
        }
      >
        {isConsise
          ? null
          : renderObjectiveFieldsTitle("vital", "Vitals")}
        {note?.weight ? (
          <DefaultObjectiveText
            header={"Weight"}
            text={note?.weight}
            name={"weight"}
          />
        ) : null}
        {OBJECTIVE_FIELDS_TO_SHOW.map(
          ({ name, label, type }, index) => {
            if (type === "vital") {
              return (
                <DefaultObjectiveText
                  header={label}
                  text={note[name]}
                  name={name}
                  key={index}
                  index={index}
                />
              );
            }
          },
        )}

        {isConsise
          ? null
          : renderObjectiveFieldsTitle("system", "Systems")}

        {OBJECTIVE_FIELDS_TO_SHOW.map(
          ({ name, label, type }, index) => {
            if (type === "system") {
              return (
                <DefaultObjectiveText
                  header={label}
                  text={note[name]}
                  name={name}
                  key={index}
                  index={index}
                />
              );
            }
          },
        )}
        {isConsise
          ? null
          : renderObjectiveFieldsTitle("overall", "Overall")}

        {OBJECTIVE_FIELDS_TO_SHOW.filter(
          (item) => item.type === "overall",
        ).length > 0 ? (
          <DefaultObjectiveText
            header={"Overall"}
            text={note.overall}
            name={"overall"}
            index={1}
          />
        ) : null}

        {note?.customObjectiveFields?.length > 0 && (
          <>
            <ObjectiveTypeSeparator title="Custom" />
            {note?.customObjectiveFields?.map((item, index) => {
              return (
                <CustomObjectiveText
                  key={index}
                  index={index}
                  name={item.name}
                  value={item.value}
                  symmetricalValueLeft={item.symmetricalValueLeft}
                  symmetricalValueRight={item.symmetricalValueRight}
                  symmetryType={item.symmetryType}
                  symmetryLabels={item.symmetryLabels}
                  isConsise={isConsise}
                />
              );
            })}
          </>
        )}
      </ul>
    </div>
  );
}
