import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
import { useDarkMode, useWindowDimensions } from "../../../../hooks";
import moment from "moment";

export function NoteCreatedAtTimer() {
  const [enabled] = useDarkMode();
  const [isDateInputValid, setIsDateInputValid] = useState(true);

  const { setValue } = useFormContext();
  const { width } = useWindowDimensions();

  const handleChange = (newValue) => {
    var isValidDate = /^\d{2}\/\d{2}\/\d{4}\s$/; // validate that typed date is mm/dd/yyyy
    var now = moment();

    if (newValue?._isValid) {
      setIsDateInputValid(true);
      setValue("createdAt", newValue, { shouldDirty: true });
    } else {
      if (isValidDate.test(newValue?._i)) {
        let valueWithTime = newValue?._i + "12:00 AM"; // if just date is entered, set it with default time
        if (
          moment(
            valueWithTime,
            "MM/DD/YYYY hh:mm A",
            true,
          ).isValid() &&
          moment(valueWithTime, "MM/DD/YYYY hh:mm A") <= now // moment validates that this is in fact a date and is not in the future
        ) {
          setValue(
            "createdAt",
            moment(valueWithTime, "MM/DD/YYYY hh:mm A"),
            { shouldDirty: true }, // if valid, set it
          );
          setIsDateInputValid(true);
        } else {
          setIsDateInputValid(false);
        }
      } else {
        setIsDateInputValid(false);
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Controller
        name="createdAt"
        render={({ field }) => (
          <DateTimePicker
            label=""
            OpenPickerButtonProps={{
              sx: { color: "#6366f1" },
            }}
            disableFuture
            InputProps={{
              sx: {
                "&.Mui-error": {
                  borderRadius: 2,
                  border: 2,
                  padding: 2,
                  borderColor: "red",
                },
                "&.Mui-focused": {
                  borderRadius: 2,
                  border: 2,
                  padding: 2,
                  borderColor: "rgb(165 180 252)",
                },
                "&::before": {
                  borderBottom: 0,
                },
                "&::after": {
                  border: 0,
                },
                "&:hover:not(.Mui-disabled)::before": {
                  borderBottom: 0,
                },
                backgroundColor: enabled
                  ? "rgb(55 65 81)"
                  : "rgb(243 244 246)",
                fontFamily: "Inter",
                fontSize: 12,
                borderRadius: 2,
                border: 2,
                padding: 2,
                borderColor: "transparent",
                height: 40,
                minWidth: width > 600 ? 200 : 100,
                color: enabled ? "#FFFFFF" : "",
              },
            }}
            {...field}
            value={field.value}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                error={!isDateInputValid}
                // helperText={"errorMessage"}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
}
