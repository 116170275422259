import moment from "moment";

import { alert } from "../../../common/Alert.js";

export async function submitBasicNote({
  structuredNoteData,
  props,
  mutations,
  completeNoteSuperSoap,
  isDraft,
  isAutosave = false,
  isNewNote = false,
  isScribeEdit = false,
  shouldBillUserForNote,
  inlineEmailExport = null,
}) {
  const { isSuperSOAP, patientUUID, noteUUID } = props;
  const { modifyNote, createPatientNote, createQuickNote } =
    mutations;

  if (isDraft) {
    structuredNoteData["isDraft"] = true;
  }

  if (isAutosave) {
    structuredNoteData["isAutosave"] = true;
  }

  if (isScribeEdit) {
    structuredNoteData["isTagged"] = true;
  }

  let submittedNoteUuid;
  let successMessage = "";

  // Use near identical logic as in Scribenote Mobile
  if (noteUUID) {
    const variables = {
      ...structuredNoteData,
      noteUuid: noteUUID,
      patientUuid: patientUUID,
      isApproved: isDraft || isScribeEdit ? false : true,
    };

    if (!isDraft) {
      variables.taggedAt = moment().format();
    }

    try {
      const modifyNoteResponse = await modifyNote({
        variables: { noteInput: variables },
      });

      successMessage =
        modifyNoteResponse.data.modifyNote.successMessage;

      submittedNoteUuid = noteUUID;

      if (isSuperSOAP && !isAutosave) {
        // Don't complete note if it is just an autosave happening
        completeNoteSuperSoap(noteUUID);
      }
    } catch (error) {
      if (!isAutosave) {
        alert("error", error.message);
      }
    }
  } else if (patientUUID) {
    // We need to create THIS note since it is a new patient note

    const variables = {
      ...structuredNoteData,
      patientUuid: patientUUID,
      isApproved: isDraft ? false : true,
    };

    if (!isDraft) {
      variables.taggedAt = moment().format();
    }

    try {
      const patientNoteResponse = await createPatientNote({
        variables: { noteInput: variables },
      });

      successMessage =
        patientNoteResponse.data.createPatientNote.successMessage;

      submittedNoteUuid =
        patientNoteResponse.data.createPatientNote.note.uuid;
    } catch (error) {
      if (!isAutosave) {
        alert("error", error.message);
      }
    }
  } else {
    // If not for an existing note, or not for an existing patient,
    // this is a NEW QUICKNOTE.
    // Create the quicknote and push the newQuicknote forward
    // for confirmation

    const variables = {
      ...structuredNoteData,
      isApproved: isDraft ? false : true,
    };

    if (!isDraft) {
      variables.taggedAt = moment().format();
    }

    try {
      const quicknoteResponse = await createQuickNote({
        variables: { noteInput: variables },
      });
      successMessage =
        quicknoteResponse.data.createQuickNote.successMessage;
      submittedNoteUuid =
        quicknoteResponse.data.createQuickNote.note.uuid;
    } catch (error) {
      if (!isAutosave) {
        alert("error", error.message);
      }
    }
  }

  if (successMessage && !isAutosave && !isNewNote) {
    alert("success", successMessage);
  }

  if (successMessage && isNewNote) {
    alert("success", "New note created. Autosaving...");
  }

  if (inlineEmailExport !== null) {
    inlineEmailExport({
      uuid: submittedNoteUuid,
    });
  }

  return { submittedNoteUuid };
}
