import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useForm, FormProvider } from "react-hook-form";
import { ConfirmModal } from "../../common/modals/PopupModals";
import { alert } from "../../common/Alert.js";
import moment from "moment";
import { getSpeciesLabel, species } from "../utils.js";
import {
  CREATE_PATIENT_MUTATION,
  EDIT_PATIENT_MUTATION,
  DELETE_PATIENT_MUTATION,
} from "../api/mutations.js";
import CreatePatientForm from "./PatientForm";

import { getDateFromInput } from "../../common/date/utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser";

export function EditablePatientModal(props) {
  const {
    currentPatientInfo,
    shouldShow,
    hideModal,
    redirectToNotebook,
    isForPatientEdit,
    refetch,
    isForPatientSelectionAction,
    setSelectedPatientName,
    setSelectedPatientUuid,
    onAddPatient,
  } = props;

  const methods = useForm();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();
  const location = useLocation();

  const isForPatientNoteCreation =
    location.pathname === "/new_note/select";

  const [submitClicked, setSubmitClicked] = useState(false);
  const [selectedSpecies, setSelectedSpecies] = useState(null);
  const [isAgeInputTypeDOB, setIsAgeInputTypeDOB] = useState(true);

  const [sex, setSex] = useState(null);
  const [intact, setIntact] = useState(true);
  const [
    shouldShowDeletePatientModal,
    setShouldShowDeletePatientModal,
  ] = useState(false);

  const [createPatient] = useMutation(CREATE_PATIENT_MUTATION);
  const [editPatient] = useMutation(EDIT_PATIENT_MUTATION);
  const [deletePatient] = useMutation(DELETE_PATIENT_MUTATION);

  useEffect(() => {
    if (isForPatientEdit && currentPatientInfo) {
      const {
        name,
        lastNameOfOwner,
        breed,
        birthdayParts: { dd, mm, yyyy },
        isIntact,
        sex,
        species,
        color,
        microchip,
        ownerContactInfo,
        emergencyContactInfo,
      } = currentPatientInfo;

      const speciesFromList = speciesItems.find(
        ({ name }) => name === species,
      );

      setSelectedSpecies(speciesFromList);
      setSex(sex);
      setIntact(isIntact);
      setIsAgeInputTypeDOB(true);

      reset({
        patientName: name,
        ownersLastName: lastNameOfOwner,
        breed,
        dd,
        mm,
        yyyy,
        weeks: null,
        months: null,
        years: null,
        color,
        microchip,
        ownerContactInfo,
        emergencyContactInfo,
      });
    }
  }, [currentPatientInfo]);

  const onError = () => {
    alert(
      "error",
      "Patient form errors, please review above for details.",
    );
  };

  const speciesItems = Object.keys(species).map((item) => {
    return {
      label: getSpeciesLabel(
        species[item].displayName,
        species[item].icon,
      ),
      name: item,
    };
  });

  function onSubmit(data) {
    setSubmitClicked(true);

    if (sex === null) {
      alert("error", "Please specify the patient's sex!");
    }

    let {
      patientName,
      ownersLastName,
      breed,
      weeks,
      months,
      years,
      yyyy,
      mm,
      dd,
      color,
      microchip,
      ownerContactInfo,
      emergencyContactInfo,
    } = data;

    const birthday = isAgeInputTypeDOB
      ? getDateFromInput({ yyyy, mm, dd })
      : null;

    // Refactor note, move to api.js
    if (isForPatientEdit) {
      editPatient({
        variables: {
          patientUuid: currentPatientInfo.uuid,
          patientInput: {
            name: patientName,
            lastNameOfOwner: ownersLastName ?? "",
            species: selectedSpecies?.name ?? "",
            breed: breed ?? "",
            weeks: weeks ?? 0,
            months: months ?? 0,
            years: years ?? 0,
            birthday: birthday ?? null,
            sex: sex,
            isIntact: intact,
            color: color ?? null,
            microchip: microchip ?? null,
            ownerContactInfo: ownerContactInfo ?? null,
            emergencyContactInfo: emergencyContactInfo ?? null,
          },
        },
      })
        .then((response) => {
          hideModal();
          alert("success", "Patient updated successfully!");
        })
        .catch((response) => {
          const errors = response.graphQLErrors.map(
            (error) => error.message,
          );
          // need to catch errors to prevent exception
          // might as well console log to debug if client has issue
          console.log(errors);
          setSubmitClicked(false);
        });
      refetch();
    } else {
      mixpanel.track("Create Patient");
      createPatient({
        variables: {
          name: patientName,
          lastNameOfOwner: ownersLastName ?? "",
          species: selectedSpecies?.name ?? "",
          breed: breed ?? "",
          weeks: weeks ?? 0,
          months: months ?? 0,
          years: years ?? 0,
          birthday: birthday ?? null,
          sex: sex,
          isIntact: intact,
          createdAt: moment().format(),
          color: color ?? null,
          microchip: microchip ?? null,
          ownerContactInfo: ownerContactInfo ?? null,
          emergencyContactInfo: emergencyContactInfo ?? null,
        },
      })
        .then((response) => {
          const { uuid } = response.data.createPatient.patient;

          if (
            !isForPatientSelectionAction &&
            !isForPatientNoteCreation
          ) {
            redirectToNotebook(uuid);
            alert(
              "info",
              <h5 className="text-sm font-normal text-white">
                New patient {patientName} created!{" "}
                <a
                  onClick={() => {
                    navigate("/notebook", {
                      state: { shouldShow: true },
                    });
                  }}
                  className="underline hover:text-indigo-400 transition-all cursor-pointer"
                >
                  Click here
                </a>{" "}
                to create another one.
              </h5>,
            );
          }
          if (isForPatientSelectionAction) {
            hideModal();
            setSelectedPatientName(patientName);
            setSelectedPatientUuid(uuid);
            alert("success", `New patient ${patientName} created!`);
          }
          if (isForPatientNoteCreation) {
            hideModal();
            onAddPatient(uuid);
            alert("success", `New patient ${patientName} created!`);
          }
        })
        .catch((response) => {
          const errors = response.graphQLErrors.map(
            (error) => error.message,
          );
          // need to catch errors to prevent exception
          // might as well console log to debug if client has issue
          console.log(errors);
          setSubmitClicked(false);
        });
    }
  }

  return (
    <>
      <ConfirmModal
        shouldShow={shouldShow}
        hideModal={hideModal}
        title={isForPatientEdit ? "Edit Patient" : "New Patient"}
        cancelAction={() => hideModal()}
        confirmAction={handleSubmit(onSubmit, onError)}
        disabled={submitClicked}
        dataCy="confirmPatient"
      >
        {isForPatientEdit && (
          <div className="flex flex-row items-center justify-between">
            <div>
              <button
                className="text-sm font-medium text-gray-400 dark:text-gray-500 transition-all hover:text-gray-600 dark:hover:text-gray-600 focus:outline-none"
                onClick={() => {
                  navigate(
                    `/audit_trail/patient/${currentPatientInfo.uuid}`,
                  );
                }}
              >
                <FontAwesomeIcon icon={faHistory} size="sm" /> Past
                Revisions
              </button>
            </div>
            <div>
              <button
                onClick={() => setShouldShowDeletePatientModal(true)}
                className="text-sm font-medium text-red-400 transition-all hover:text-red-700 focus:outline-none"
              >
                Delete Patient
              </button>
            </div>
          </div>
        )}
        <FormProvider {...methods}>
          <CreatePatientForm
            control={control}
            errors={errors}
            sex={sex}
            setSex={setSex}
            intact={intact}
            setIntact={setIntact}
            selectedSpecies={selectedSpecies}
            setSelectedSpecies={setSelectedSpecies}
            isAgeInputTypeDOB={isAgeInputTypeDOB}
            setIsAgeInputTypeDOB={setIsAgeInputTypeDOB}
            submitClicked={submitClicked}
            speciesItems={speciesItems}
          />
        </FormProvider>
      </ConfirmModal>
      <ConfirmModal
        shouldShow={shouldShowDeletePatientModal}
        hideModal={() => setShouldShowDeletePatientModal(false)}
        title={"Delete Patient"}
        cancelText={"Never Mind"}
        confirmText={"Yes, Delete"}
        cancelAction={() => setShouldShowDeletePatientModal(false)}
        confirmAction={() => {
          deletePatient({
            variables: {
              patientUuid: currentPatientInfo.uuid,
            },
          })
            .then(() => {
              navigate("/notebook");
              alert("success", "Patient deleted successfully.");
            })
            .catch((response) => {
              const errors = response.graphQLErrors.map((error) =>
                alert("error", error.message),
              );
            });
        }}
      >
        <h5>
          Are you sure you&apos;d like to{" "}
          <strong className="text-red-500">
            delete this patient
          </strong>
          ? All of this patients' existing{" "}
          <strong>notes and recordings</strong> will be deleted as
          well. This action cannot be undone.
        </h5>
      </ConfirmModal>
    </>
  );
}
