import React from "react";
import { AudioPlayer } from "./AudioPlayer.js";
import { SoapEditButton } from "../../common/clinical/SoapEditButton.js";
import { AttachmentsPreview } from "../../attachments/AttachmentsPreview.js";
import NoteCreatedAt from "./NoteCreatedAt.js";
import NotesExpandableSection from "./NotesExpandableSection.js";
import NoteStatus from "./NoteStatus.js";
import ExportButton from "../../email/ExportButton.js";
import CopyFullSOAP from "./CopyFullSOAP.js";
import ExportToPDF from "./ExportToPDF.js";

export default function Note({ desc, note, user, patientData }) {
  function handleAttachmentClick(attachment) {
    window.open(attachment.fileLink, "_blank");
  }

  if (!note) {
    return null;
  }

  return (
    <div className="w-full">
      <div className="flex border-2 border-indigo-100 bg-indigo-100 dark:border-gray-900/50 rounded-t-lg dark:bg-gray-900 py-2 px-3">
        <div className="flex-1 flex flex-col items-start space-y-1">
          <div className="flex flex-row items-center space-x-2">
            {note?.title ? (
              <h3 className="flex font-medium text-base md:text-lg line-clamp-1">
                {note?.title}
              </h3>
            ) : null}
            <NoteStatus note={note} />
          </div>
          <NoteCreatedAt createdAt={note?.createdAt} size="sm" />
        </div>

        <div className="flex flex-row space-x-3 pl-4">
          <SoapEditButton note={note} />
          <CopyFullSOAP note={note} />

          <ExportToPDF
            note={note}
            user={user}
            patient={patientData}
          />
        </div>
      </div>
      <div className="border rounded-b-lg dark:border-gray-900/50 bg-gray-50 dark:bg-gray-800 px-3 pb-3">
        {desc ? (
          <div>
            <h5 className="text-sm py-2 text-gray-700 dark:text-gray-200 text-center">
              {desc}
            </h5>
          </div>
        ) : null}

        {!note?.isNewRecording && !note?.isMarkedAsProcessing ? (
          <NotesExpandableSection data={note} />
        ) : null}

        {note?.attachments?.length > 0 ? (
          <div className="my-2">
            <AttachmentsPreview
              attachments={note?.attachments}
              handleAttachmentClick={handleAttachmentClick}
              isCompactView={true}
              attachmentPreviewText="Attachments"
              emptyStateText="No files are currently attached to this note."
            />
          </div>
        ) : null}

        <div className="mt-3">
          <AudioPlayer
            noteUuid={note?.uuid}
            // shouldShowDeleteButton={false}
          ></AudioPlayer>
        </div>
      </div>
    </div>
  );
}
