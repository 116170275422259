import React from "react";
import { useNavigate } from "react-router-dom";

export default function DashboardItem(props) {
  const navigate = useNavigate();

  const {
    children,
    title,
    redirectTo,
    shouldShow = true,
    id,
    navigationState,
  } = props;

  let dashboardItemClassName =
    "group flex flex-col place-content-center place-items-center text-center bg-white dark:bg-gray-800 text-gray-600 shadow-sm border dark:border-gray-900/50 rounded-xl py-10 cursor-pointer hover:shadow-lg hover:text-indigo-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-indigo-500 transition-all";

  if (!shouldShow) {
    dashboardItemClassName = "hidden";
  }

  return (
    <div
      id={id}
      className={dashboardItemClassName}
      onClick={() => {
        if (redirectTo) {
          navigate(redirectTo, navigationState);
        }
      }}
    >
      {children}
      <h3 className="font-medium text-lg text-gray-600 dark:text-gray-400 group-hover:text-indigo-500 transition-all">
        {title}
      </h3>
    </div>
  );
}
