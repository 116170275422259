import { Tooltip } from "@mui/material";
import { useAccount, useAuth, useNoteUsage } from "../../../hooks";

export function NoteUsageCounter({ small }) {
  const account = useAccount();
  const auth = useAuth();

  const {
    isAppointmentsBetaUser,
    numAppointmentsUsed,
    numAppointmentsAllowed,
    hasReachedAutoSoapLimit,
    shouldShowAutoSoapCounter,
  } = useNoteUsage();

  // NON PAID USERS
  if (auth?.isUserAuthenticated) {
    return (
      <div className="flex flex-row items-center space-x-2">
        {shouldShowAutoSoapCounter ? (
          <Tooltip
            title={
              isAppointmentsBetaUser &&
              account?.accountType !== "unlimited"
                ? "You can make a limited amount of Appointments for free. You will need to subscribe to a paid plan for more."
                : "You can make unlimited Auto-Notes."
            }
            arrow
          >
            <div
              id="autoSOAPUsageCounter"
              data-cy="autoSOAPUsageCounter"
              className={`flex items-center cursor-default space-x-1 ${
                small ? "py-1 px-2" : "py-2.5 px-3"
              } rounded-full ${
                hasReachedAutoSoapLimit
                  ? "bg-red-500/10 text-red-500"
                  : "bg-green-500/10 text-green-500"
              }`}
            >
              <span className="text-sm font-medium">
                {numAppointmentsUsed}/
                {account?.accountType !== "unlimited"
                  ? numAppointmentsAllowed
                  : "∞"}
              </span>
              <span className="text-xs whitespace-nowrap">
                Medical Records
              </span>
            </div>
          </Tooltip>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
}
