import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import JoyrideTooltip from "../modals/JoyrideTooltip";

const JoyrideTour = ({
  steps,
  runJoyrideTour,
  setRunJoyrideTour,
}) => {
  const [stepIndex, setStepIndex] = useState(0);

  function handleTourCallback(data) {
    const { status, action, index, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRunJoyrideTour(false);
    }

    if (action === "close") {
      setRunJoyrideTour(false);
      setStepIndex(0);
    }
    if (action === "skip") {
      setStepIndex(0);
    }
    if (status === "finished") {
      setRunJoyrideTour(false);
      setStepIndex(0);
    }
  }
  return (
    <Joyride
      steps={steps}
      run={runJoyrideTour}
      callback={handleTourCallback}
      tooltipComponent={JoyrideTooltip}
      stepIndex={stepIndex}
      continuous
      showSkipButton
      disableScrolling
    />
  );
};

export default JoyrideTour;
