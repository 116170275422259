import { Text, View } from "@react-pdf/renderer";
import React from "react";

export default function DentalChartLegend({ isForPDF = false }) {
  if (isForPDF) {
    return (
      <View style={{ flexDirection: "row", marginBottom: 20 }}>
        <View style={{ flexDirection: "column" }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <View
              style={{
                width: 10,
                height: 10,
                borderRadius: 2,
                backgroundColor: "#d4d4d8",
              }}
            />
            <Text style={{ marginLeft: 4, fontSize: 10 }}>
              Normal
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <View
              style={{
                width: 10,
                height: 10,
                borderRadius: 2,
                backgroundColor: "#fbbf24",
              }}
            />
            <Text style={{ marginLeft: 4, fontSize: 10 }}>
              Abnormal
            </Text>
          </View>
          <View
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <View
              style={{
                width: 10,
                height: 10,
                borderRadius: 2,
                backgroundColor: "#fb7185",
              }}
            />
            <Text style={{ marginLeft: 4, fontSize: 10 }}>
              Unerupted
            </Text>
          </View>
        </View>

        <View style={{ flexDirection: "column", marginLeft: 8 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <View
              style={{
                width: 10,
                height: 10,
                borderRadius: 2,
                backgroundColor: "#2dd4bf",
              }}
            />
            <Text style={{ marginLeft: 4, fontSize: 10 }}>
              Extracted
            </Text>
          </View>
          <View
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <View
              style={{
                width: 10,
                height: 10,
                borderRadius: 2,
                backgroundColor: "#ffffff",
                borderWidth: 1,
                borderColor: "#475569",
              }}
            />
            <Text style={{ marginLeft: 4, fontSize: 10 }}>
              Missing
            </Text>
          </View>
        </View>
      </View>
    );
  } else {
    return (
      <div className="flex flex-row items-start bg-white p-4 rounded-xl">
        <div className="flex flex-col">
          <div className="flex items-center">
            <div className="w-4 h-4 rounded-md bg-gray-300" />
            <h5 className="ml-2 text-sm dark:text-gray-700">
              Normal
            </h5>
          </div>
          <div>
            <div className="flex items-center">
              <div className="w-4 h-4 rounded-md bg-[#fbbf24]" />
              <h5 className="ml-2 text-sm dark:text-gray-700">
                Abnormal
              </h5>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 rounded-md bg-[#fb7185]" />
            <h5 className="ml-2 text-sm dark:text-gray-700">
              Unerupted
            </h5>
          </div>
        </div>

        <div className="flex flex-col ml-4">
          <div className="flex items-center">
            <div className="w-4 h-4 rounded-md bg-[#2dd4bf]" />
            <h5 className="ml-2 text-sm dark:text-gray-700">
              Extracted
            </h5>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 rounded-md bg-white border-2 border-gray-600" />
            <h5 className="ml-2 text-sm dark:text-gray-700">
              Missing
            </h5>
          </div>
        </div>
      </div>
    );
  }
}
