import React from "react";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

export function SoapEditButton({ note }) {
  const navigate = useNavigate();

  return (
    <button
      title="Edit note"
      className={
        "flex-grow-0 shadow-md self-center border rounded-full w-8 h-8 bg-indigo-500 border-indigo-500 hover:bg-indigo-700 text-white transition-all font-medium outline-none text-sm focus:outline-none"
      }
      onClick={() => {
        navigate(`/edit_note/${note?.uuid}`);
      }}
    >
      <FontAwesomeIcon icon={faEdit} />
    </button>
  );
}
