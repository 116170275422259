export function getAllKeyInfo(data) {
  const allKeyHistories = [];
  const allKeyMedications = [];
  const allKeyVaccinations = [];

  if (data && data.patient) {
    data.patient.notes.forEach((note) => {
      allKeyHistories.push({
        text: note.keyHistory,
        createdAt: note.createdAt,
        noteUuid: note.uuid,
      });
      allKeyMedications.push({
        text: note.keyMedications,
        createdAt: note.createdAt,
        noteUuid: note.uuid,
      });
      allKeyVaccinations.push({
        text: note.vaccinationStatus,
        createdAt: note.createdAt,
        noteUuid: note.uuid,
      });
    });
  }

  return { allKeyHistories, allKeyMedications, allKeyVaccinations };
}

export function filterSummaryData(data) {
  if (data.length > 0) {
    const filteredSummaryInfo = data.filter(
      (item) => item.text && item.text !== "",
    );
    return filteredSummaryInfo;
  } else {
    return null;
  }
}
