import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import PageTitle from "../layout/PageTitle.js";
import { PatientSelectForAction } from "../common/multiselect/PatientSelectionForAction.js";
import { gql, useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { alert } from "../common/Alert.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const COPY_NOTE_TO_PATIENT = gql`
  mutation copyNotesToPatient(
    $patientUuid: String
    $noteUuids: [String]
    $shouldCopyTitles: Boolean
    $createdAt: DateTime
  ) {
    copyNotesToPatient(
      patientUuid: $patientUuid
      noteUuids: $noteUuids
      shouldCopyTitles: $shouldCopyTitles
      createdAt: $createdAt
    ) {
      ok
    }
  }
`;

export default function CopyPatientSelect() {
  const [selectedPatientUuid, setSelectedPatientUuid] = useState("");
  const [selectedPatientName, setSelectedPatientName] = useState("");

  const { noteUUIDs } = useParams();
  const navigate = useNavigate();
  const { control, getValues } = useForm();

  const [copyNotesToPatient, { loading }] = useMutation(
    COPY_NOTE_TO_PATIENT,
  );

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  const shouldPluralizeText = noteUUIDs.split(",").length > 1;
  const numNotes = noteUUIDs.split(",").length;

  function renderModalText() {
    return (
      <>
        Are you sure you&apos;d like to copy{" "}
        <strong className="text-indigo-400">
          {numNotes} {shouldPluralizeText ? "notes" : "note"}{" "}
        </strong>
        to{" "}
        <strong className="text-indigo-400">
          {selectedPatientName}
        </strong>
        's notebook?
      </>
    );
  }

  function renderCopyNoteTitlesOption() {
    return (
      <>
        <h5 className="text-sm text-indigo-400 dark:text-gray-500 mt-2">
          <FontAwesomeIcon icon={faInfoCircle} /> The copied note will
          first save as a draft. Recordings and attachments will not
          be copied to the new note, but will remain on the original.
        </h5>
        <div className="flex flex-row space-x-2 mt-4">
          <Controller
            control={control}
            rules={{
              required: false,
            }}
            render={({ field: { onChange, value } }) => (
              <input
                type="checkbox"
                className="hover:cursor-pointer"
                onChange={(value) => onChange(value)}
                checked={value}
              />
            )}
            name="shouldCopyTitles"
            defaultValue={false}
          />

          <h4>Copy note titles?</h4>
        </div>
        <h5 className="text-sm text-gray-500 dark:text-gray-500">
          Checking this will copy the title
          {shouldPluralizeText ? "s" : ""} from the original note
          {shouldPluralizeText ? "s" : ""} to the newly copied note
          {shouldPluralizeText ? "s" : ""}.
        </h5>
      </>
    );
  }

  return (
    <div>
      <PageTitle title="Copy notes to a patient" />
      <PatientSelectForAction
        selectedPatientName={selectedPatientName}
        selectedPatientUuid={selectedPatientUuid}
        setSelectedPatientName={setSelectedPatientName}
        setSelectedPatientUuid={setSelectedPatientUuid}
        modalTitle={"Copy to this patient?"}
        modalText={renderModalText()}
        loading={loading}
        confirmAction={() => {
          const { shouldCopyTitles } = getValues();
          copyNotesToPatient({
            variables: {
              noteUuids: noteUUIDs.split(","),
              patientUuid: selectedPatientUuid,
              shouldCopyTitles: shouldCopyTitles,
              createdAt: moment().format(),
            },
          })
            .then(() => {
              navigate(`/notebook/${selectedPatientUuid}`);
              alert(
                "success",
                `Copied ${numNotes} note${
                  shouldPluralizeText ? "s" : ""
                } successfully.`,
              );
            })
            .catch((response) => {
              const errors = response.graphQLErrors.map((error) =>
                alert("error", error.message),
              );
            });
        }}
        additionalOptions={renderCopyNoteTitlesOption()}
      />
    </div>
  );
}
