import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useEffect, useState } from "react";
import { config, animated, useSpring } from "react-spring";
import { useFindAndReplace } from "../../../hooks";
import { BasicButton } from "../buttons/BasicButton";
import { InputWithRef } from "./Input";

export function FloatingWordMultiEditInput() {
  const {
    findAndReplaceMatchCount,
    findAndReplaceSelectedText,
    clearFindAndReplace,
    replacementText,
    setReplacementText,
    shouldShowReplacementTextBox,
    handleReplace,
  } = useFindAndReplace();

  const [isFocused, setIsFocused] = useState(false);

  const slideAnim = useSpring({
    transform: shouldShowReplacementTextBox
      ? "translateX(10px)"
      : "translateX(1000px)",
    config: config.default,
  });

  const inputRef = useRef(null);

  useEffect(() => {
    const handleHotkey = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "k") {
        event.preventDefault();
        inputRef.current?.focus();
      }
    };

    const handleEnterKey = (event) => {
      if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        handleReplace();
      }
    };

    window.addEventListener("keydown", handleHotkey);
    window.addEventListener("keydown", handleEnterKey);

    return () => {
      window.removeEventListener("keydown", handleHotkey);
      window.removeEventListener("keydown", handleEnterKey);
    };
  }, [handleReplace]);

  // Disabled on super-soap due to virtualization causing issues with rendering this off screen
  // When we move off of a virtualized super-soap, this can be re-added
  const isSuperSOAP = location.pathname === "/super_soap";
  if (isSuperSOAP) {
    return null;
  }

  return (
    <animated.div
      style={slideAnim}
      className="fixed bottom-1/4 right-0 z-20 flex flex-col items-center justify-center pointer-events-none"
    >
      <div className="flex flex-col items-center pointer-events-auto">
        <button
          onClick={clearFindAndReplace}
          className="fixed top-1 left-4 z-20 text-xl text-red-500"
          aria-label="Close"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div
          className="relative w-[320px] flex flex-col items-center bg-white dark:bg-gray-800 py-4 px-10 rounded-2xl space-y-2 border dark:border-white border-black"
          style={{
            boxShadow: "0px -5px 20px -10px rgba(0,0,0,0.40)",
          }}
        >
          <h5 className="text-xs pt-2 md:text-sm text-center">
            Replace {findAndReplaceMatchCount}{" "}
            {findAndReplaceMatchCount == 1 ? "instance" : "instances"}{" "}
            of{" "}
            <span className="italic">
              &quot;{findAndReplaceSelectedText}&quot;
            </span>{" "}
            with:
          </h5>

          <InputWithRef
            ref={inputRef}
            placeholder={
              isFocused ? "" : "Press Ctrl/Cmd + K to focus"
            }
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            defaultValue={findAndReplaceSelectedText}
            onChange={(e) => {
              const nextVal = e.target.value;
              setReplacementText(nextVal);
            }}
            value={replacementText}
          />
          <div className="flex flex-row items-center">
            <BasicButton
              disabled={!replacementText?.length}
              onClick={() => handleReplace()}
            >
              Replace
            </BasicButton>
          </div>
          <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
            Press Ctrl/Cmd + Enter to replace
          </p>
        </div>
      </div>
    </animated.div>
  );
}
