import React, { useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { defaultList } from "../../../../assets/data/Dictionary";
import PredictiveInput from "../../../common/inputs/PredictiveInput";
import { useFindAndReplace } from "../../../../hooks";
import { alert } from "../../../common/Alert";
import CopyIndividualField from "./CopyIndividualField";

export default function CustomForm({ control }) {
  const { field } = useController({
    name: "customText",
    control,
  });

  const { selectFindAndReplaceText } = useFindAndReplace();
  const { getValues } = useFormContext();

  function copyText() {
    const textToCopy = getValues();

    navigator.clipboard
      .writeText(textToCopy["customText"])
      .then(() => {
        alert("info", "Copied!");
      });
  }

  return (
    <div className="relative w-full">
      <CopyIndividualField onClick={() => copyText()} />
      <PredictiveInput
        field={field}
        placeholder={"Type notes here..."}
        positionStyles="p-4 my-2 border-2 border-transparent"
        dictionary={defaultList}
        inputClassName={
          "bg-gray-100 border-2 border-gray-100 dark:border-gray-700 focus:border-indigo-300 focus:dark:border-indigo-300 dark:bg-gray-700 p-4 my-2 rounded-xl w-full text-xs md:text-sm align-top text-gray-700 dark:text-gray-300 resize-none"
        }
        onMouseUp={(e) => {
          selectFindAndReplaceText(e);
        }}
      />
    </div>
  );
}
