import { useContext, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_TEMPLATE_BY_UUID } from "../graphql/queries/GetTemplate.js";
import TemplateSelectContext from "../context/templateSelectContext.js";

export function useApplyTemplate({ noteUuid }) {
  const { selectedTemplateUuid, uuidOfNoteToOverwriteWithTemplate } =
    useContext(TemplateSelectContext);

  const [getTemplate, { data }] = useLazyQuery(GET_TEMPLATE_BY_UUID, {
    fetchPolicy: "network-only",
  });

  const template = data?.template?.note;

  useEffect(() => {
    if (
      selectedTemplateUuid &&
      noteUuid === uuidOfNoteToOverwriteWithTemplate
    ) {
      getTemplate({
        variables: {
          uuid: selectedTemplateUuid,
        },
      });
    }
  }, [selectedTemplateUuid]);

  return {
    template,
  };
}
