import { gql } from "@apollo/client";

const REMOVE_USER_FROM_TEAM = gql`
  mutation removeUserFromOrgTeam($rmTeamUserInput: RmTeamUserInput!) {
    removeUserFromOrgTeam(rmTeamUserInput: $rmTeamUserInput) {
      ok
    }
  }
`;

export default REMOVE_USER_FROM_TEAM;
