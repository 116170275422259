import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { useAccount } from "./use-account";

export function useAccountAuthorizationRedirect() {
  const navigate = useNavigate();
  const location = useLocation();
  const account = useAccount();

  const { isConfirmed } = account;

  useEffect(() => {
    if (location.pathname !== "/registration_confirmation") {
      if (!isConfirmed) {
        navigate("/registration_confirmation");
      }
    }
  }, [account, location]);
}
