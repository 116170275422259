import React, { useEffect, useState } from "react";
import Twemoji from "../../Twemoji";
import RandomImage1 from "../../assets/imgs/user-placeholder-1.svg";
import RandomImage2 from "../../assets/imgs/user-placeholder-2.svg";
import RandomImage3 from "../../assets/imgs/user-placeholder-3.svg";
import RandomImage4 from "../../assets/imgs/user-placeholder-4.svg";
import Halloween1 from "../../assets/imgs/halloween-scribbles.svg";
import Halloween2 from "../../assets/imgs/halloween-otto.svg";
import Christmas1 from "../../assets/imgs/christmas-scribbles.svg";
import Christmas2 from "../../assets/imgs/christmas-otto.svg";
import Valentines1 from "../../assets/imgs/otto-love.svg";
import Valentines2 from "../../assets/imgs/scribbles-love.svg";
import moment from "moment";

function getRandomItem(itemList) {
  const [selectedItem, setSelectedItem] = useState(null);

  const minIndex = 1;
  const maxIndex = itemList.length;

  useEffect(() => {
    const randomIndexInListRange =
      Math.floor(Math.random() * maxIndex) + minIndex;
    setSelectedItem(randomIndexInListRange);
  }, []);

  return <>{itemList[selectedItem - 1]}</>;
}

export function RandomSuccessEmoji() {
  const emojiList = [
    <Twemoji key={0} emoji="😎" />,
    <Twemoji key={1} emoji="🚀" />,
    <Twemoji key={2} emoji="🎉" />,
    <Twemoji key={3} emoji="💪" />,
    <Twemoji key={4} emoji="😍" />,
    <Twemoji key={5} emoji="🤠" />,
    <Twemoji key={6} emoji="🙌" />,
    <Twemoji key={7} emoji="🤩" />,
    <Twemoji key={8} emoji="🥳" />,
    <Twemoji key={9} emoji="⭐️" />,
  ];

  return getRandomItem(emojiList);
}

export function RandomUserImage() {
  let imageList = [];
  let currentYear = new Date().getFullYear();

  if (moment().isSame(`${currentYear}-10-31`, "day")) {
    imageList = [
      <img key={0} src={Halloween1} />,
      <img key={1} src={Halloween2} />,
    ];
  } else if (
    moment().isSame(`${currentYear}-12-24`, "day") ||
    moment().isSame(`${currentYear}-12-25`, "day")
  ) {
    imageList = [
      <img key={0} src={Christmas1} />,
      <img key={1} src={Christmas2} />,
    ];
  } else if (moment().isSame(`${currentYear}-02-14`, "day")) {
    imageList = [
      <img key={0} src={Valentines1} />,
      <img key={0} src={Valentines2} />,
    ];
  } else {
    imageList = [
      <img key={0} src={RandomImage1} />,
      <img key={1} src={RandomImage2} />,
      <img key={2} src={RandomImage3} />,
      <img key={3} src={RandomImage4} />,
    ];
  }

  return getRandomItem(imageList);
}

export function RandomValueMessage() {
  const messageList = [
    "You know... It only takes 12 hours to watch all the Lord of the Rings movies.",
    "Have you gone on a walk or a hike lately?",
    "That's time you could spend having a nap or two. Or three.",
    "By the way, you're looking cute today :)",
    "Do you know that you make the world a brighter place?",
    "Just a friendly reminder that you're absolutely amazing!",
    "You're a star shining in someone's sky. Keep shining bright!",
    "Sending you virtual hugs because you're the best!",
    "Remember to take breaks and treat yourself. You deserve it!",
    "When was the last time you tried baking some cookies?",
    "How about starting a new book?",
  ];

  return getRandomItem(messageList);
}
