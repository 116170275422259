export const getNoteItemSize = (notes, index) => {
  const note = notes[index];
  let baseSize;
  if (note?.title && note?.patient) {
    baseSize = 90;
  } else if (note?.title) {
    baseSize = 86;
  } else if (note?.patient) {
    baseSize = 62;
  } else {
    baseSize = 58;
  }

  const margin = 8;
  return baseSize + margin;
};
