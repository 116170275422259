import React from "react";
import { useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Twemoji from "../../Twemoji";
import { BasicButton } from "../common/buttons/BasicButton";

const GET_ORG_INFO = gql`
  {
    rootUser {
      firstName
      organization {
        name
        numTeams
        numUsers
        teams {
          uuid
        }
      }
    }
  }
`;

export default function OrgValueBanner() {
  const { data, loading, error } = useQuery(GET_ORG_INFO, {
    fetchPolicy: "no-cache",
  });
  const navigate = useNavigate();

  let currentTimeInHours = new Date().getHours();
  const isDay = currentTimeInHours >= 3 && currentTimeInHours <= 16;

  if (!data || !data?.rootUser || !data?.rootUser?.organization) {
    return null;
  }

  const {
    rootUser: { firstName, organization },
  } = data;

  return (
    <div className="rounded-xl bg-gradient-to-br from-indigo-300/60 to-indigo-400/60 via-indigo-200/60 dark:from-indigo-500/30 dark:to-indigo-500/30 dark:via-indigo-500/50 p-6 flex flex-col md:flex-row items-center md:space-x-4 space-y-4 md:space-y-0 divide-y-2 divide-indigo-100 dark:divide-indigo-800 md:divide-y-0 shadow-sm shadow-indigo-200 dark:shadow-indigo-800">
      {loading ? (
        <FontAwesomeIcon
          icon={faSpinner}
          className="animate-spin mx-auto text-black dark:text-white"
        />
      ) : error ? (
        <p>Error: {error?.message}</p>
      ) : (
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-col items-start">
            <h3 className="text-gray-700">
              {isDay ? (
                <span>
                  Hello {firstName}! <Twemoji emoji={"👋"} />
                </span>
              ) : (
                <span>
                  Good Evening {firstName}! <Twemoji emoji={"✨"} />
                </span>
              )}
            </h3>
            <h4>
              You&apos;re an admin of the {organization?.name}{" "}
              organization.
            </h4>
            <h5>
              You currently have {organization.numTeams} team
              {organization?.numTeams !== 1 && "s"} with a total of{" "}
              {organization?.numUsers} user
              {organization?.numUsers !== 1 && "s"} on Scribenote.
            </h5>
          </div>
          <BasicButton onClick={() => navigate("/admin")}>
            Manage Organization
          </BasicButton>
        </div>
      )}
    </div>
  );
}
