import React, { forwardRef } from "react";

const className =
  "appearance-none relative block w-full px-3 py-2 border dark:bg-gray-700 dark:border-gray-700 dark:text-gray-200 border-gray-100 bg-gray-100 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 text-xs md:text-sm";

export const ErrorMessage = ({ message }) => {
  return (
    <span className="text-red-400 text-xs text-center">
      {message}
    </span>
  );
};

export const Input = (props) => {
  return <input className={className} {...props} />;
};

export const InputWithRef = forwardRef(function customInput(
  props,
  ref,
) {
  return <input ref={ref} className={className} {...props} />;
});
