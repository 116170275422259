import { signS3Attachment } from "../../api/SignS3";
import axios from "axios";
import { alert } from "../common/Alert";
import { toast } from "react-toastify";

export async function submitAttachments({
  getValues,
  setPercentageUploaded,
  setCurrentFileName,
}) {
  const { attachments } = getValues();

  if (!attachments || attachments?.length === 0) {
    return;
  }

  alert("note-attachment", "Uploading attachments...");

  const uploadedFiles = [];

  for (let i = 0; i < attachments.length; i++) {
    const formData = new FormData();

    const fileType = attachments[i].name.split(".").pop();

    const signS3Response = await signS3Attachment(
      fileType,
      attachments[0].type,
    );

    const { data, storageObjectName } = signS3Response.data;

    const { fields } = data;

    for (const key in fields) {
      formData.append(key, fields[key]);
    }

    formData.append("file", attachments[i]);
    formData.append("Content-Type", attachments[i].type);

    setCurrentFileName(attachments[i].name);
    setPercentageUploaded(0);

    await axios.post(data.url, formData, {
      headers: {
        "Access-Control-Allow-Origin": true,
      },
      onUploadProgress: (progressEvent) => {
        const percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        setPercentageUploaded(percent);
      },
    });

    uploadedFiles.push({
      fileName: attachments[i].name,
      storageObjectName: storageObjectName,
      contentType: attachments[i].type,
    });
  }

  toast.dismiss("note-attachment");

  return uploadedFiles;
}

export function getFileExtensionType(fileName) {
  if (fileName) {
    return fileName.toLowerCase().split(".").pop();
  } else {
    return;
  }
}
