import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { BasicButton } from "../common/buttons/BasicButton";
import { AccountItem, AccountItemLine } from "./AccountItem";

export default function RevisionsSettings() {
  const navigate = useNavigate();
  return (
    <div id="revisionsSection">
      <AccountItem
        title="Revisions"
        icon={
          <FontAwesomeIcon icon={faHistory} size="lg" color="gray" />
        }
      >
        <AccountItemLine>
          <BasicButton
            onClick={() => {
              navigate(`/audit_trail/user/`);
            }}
          >
            See All Patient / Note Revisions
          </BasicButton>
        </AccountItemLine>
      </AccountItem>
    </div>
  );
}
