import React from "react";

export default function EditDeleteButtons({
  onClickDestructiveButton,
  destructiveButtonText,
  onClickActionButton,
  actionButtonText,
}) {
  return (
    <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
      <div
        onClick={onClickDestructiveButton}
        className={
          "w-full font-medium text-red-400 bg-red-50 dark:bg-gray-600 dark:hover:bg-gray-700 hover:bg-red-100 rounded-full text-sm text-center cursor-pointer py-1 px-3 transition-all focus:outline-none"
        }
      >
        {destructiveButtonText}
      </div>
      <div
        onClick={onClickActionButton}
        className={
          "w-full font-medium text-white bg-indigo-500 hover:bg-indigo-600 rounded-full text-sm text-center cursor-pointer py-1 px-3 transition-all focus:outline-none"
        }
      >
        {actionButtonText}
      </div>
    </div>
  );
}
