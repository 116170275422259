import { gql } from "@apollo/client";

export const NOTE_FIELDS = gql`
  fragment NoteFields on Note {
    uuid
    transcription
    createdAt
    quickNote
    title
    isAutoSoap
    isTagged
    isApproved
    isDraft
    isNewRecording
    isMarkedAsReview
    isMarkedAsProcessing
    isMarkedAsFailed
    isIncomplete
    isUnacceptable
    isHallucinating
    jobType
    jobTypeName
    hasBeenExported
    temperature
    heartRate
    respiratoryRate
    weight
    capillaryRefillTime
    bodyConditionScore
    cardiovascular
    coatAndSkin
    ears
    eyes
    gastrointestinal
    lymphatic
    musculoskeletal
    neurological
    oral
    reproductiveAndUrinary
    respiratory
    overall
    subjective
    objective
    assessment
    plan
    clientSummary
    shouldUseGenericObjective
    shouldUseCustomForm
    additionalNotes
    customObjectiveFields {
      uuid
      name
      value
      symmetricalValueLeft
      symmetricalValueRight
      symmetryType
      symmetryLabels {
        left
        right
      }
    }
    customFormFields {
      uuid
      textValue
    }
  }
`;
