import React from "react";

export function FormatHint({ children }) {
  return (
    <div className="flex flex-row">
      <div className="dark:text-gray-400 italic text-sm pb-2">
        {children}
      </div>
    </div>
  );
}
