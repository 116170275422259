import { gql } from "@apollo/client";

export const GET_USERS_TEMPLATES = gql`
  {
    user {
      templates {
        uuid
        name
        description
        isPreset
        colorScheme
      }
    }
  }
`;
