import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNoteUsage } from "../../hooks";
import Twemoji from "../../Twemoji";
import InviteLink from "../common/InviteLink";
import { RandomValueMessage } from "../common/Randomizer";

import { SCRIBENOTE_WEB } from "../../constants";

export default function ValueBanner({ user }) {
  const { numAppointmentsUsed, isAppointmentsBetaUser } =
    useNoteUsage();

  const REFERRAL_CODE = user?.referral?.referralCode;

  const REFERRAL_LINK = `${SCRIBENOTE_WEB}/auth/register?referralCode=${REFERRAL_CODE}`;

  let currentTimeInHours = new Date().getHours();

  const isDay = currentTimeInHours >= 3 && currentTimeInHours <= 16;
  const hoursSaved = (numAppointmentsUsed * 10) / 60;

  let infoText =
    "It looks like you haven't made any Auto-Notes with Scribenote. Try recording your appointments by clicking the big purple button!";

  if (hoursSaved <= 0.5 && hoursSaved > 0) {
    infoText = `Looks like you've made ${numAppointmentsUsed} Appointments with Scribenote. Keep it up and you'll be saving a whole bunch of time!`;
  } else if (hoursSaved > 0.5) {
    infoText = (
      <span>
        Did you know you&#39;ve saved about{" "}
        <span className="font-bold">
          {Math.ceil(hoursSaved)} hours
        </span>{" "}
        by using Scribenote? <RandomValueMessage />
      </span>
    );
  }

  if (!isAppointmentsBetaUser) {
    return null;
  }

  return (
    <div className="rounded-xl bg-gradient-to-br from-indigo-300 to-indigo-400 via-indigo-200 dark:from-indigo-500/30 dark:to-indigo-500/30 dark:via-indigo-500/50 p-6 flex flex-col md:flex-row items-center md:space-x-4 space-y-4 md:space-y-0 divide-y-2 divide-indigo-100 dark:divide-indigo-800 md:divide-y-0 shadow-sm shadow-indigo-200 dark:shadow-indigo-800">
      <div className="flex-1 space-y-2">
        <h3 className="text-gray-700">
          {isDay ? (
            <span>
              Hello {user?.firstName}! <Twemoji emoji={"👋"} />
            </span>
          ) : (
            <span>
              Good Evening {user?.firstName}! <Twemoji emoji={"✨"} />
            </span>
          )}
        </h3>
        <h5 className="text-gray-700 text-sm lg:text-base">
          <FontAwesomeIcon icon={faInfoCircle} /> {infoText}
        </h5>
      </div>

      {REFERRAL_CODE ? (
        <div className="flex flex-col flex-1 items-center space-y-2 pt-4 md:pt-0">
          <h5 className="text-xs md:text-sm text-center font-medium text-gray-700">
            Share this link with friends, and you&#39;ll get{" "}
            <a
              className="underline"
              target="_blank"
              href="https://www.scribenote.com/invites"
              rel="noreferrer"
            >
              rewarded
            </a>{" "}
            when they sign up or subscribe! 🎁
          </h5>
          <InviteLink referralLink={REFERRAL_LINK} />
        </div>
      ) : null}
    </div>
  );
}
