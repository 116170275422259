import { gql } from "@apollo/client";

export default gql`
  mutation sendExportNoteEmail(
    $toEmail: String
    $grantedNoteUuid: String
    $isForAssistant: Boolean
    $specialInstructions: String
    $areAttachmentsIncluded: Boolean
    $shouldEmbedEmailExports: Boolean
  ) {
    sendExportNoteEmail(
      toEmail: $toEmail
      grantedNoteUuid: $grantedNoteUuid
      isForAssistant: $isForAssistant
      specialInstructions: $specialInstructions
      areAttachmentsIncluded: $areAttachmentsIncluded
      shouldEmbedEmailExports: $shouldEmbedEmailExports
    ) {
      ok
    }
  }
`;
