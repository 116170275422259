import React from "react";
import PropTypes from "prop-types";
import { useController, useFormContext } from "react-hook-form";
import PredictiveInput from "../../../common/inputs/PredictiveInput.js";
import { defaultList } from "../../../../assets/data/Dictionary.js";
import { alert } from "../../../common/Alert.js";
import CopyIndividualField from "./CopyIndividualField.js";
export function SOAPInput({
  fieldName,
  control,
  title,
  headerStyle,
  boxStyle,
  id,
  placeholder = null,
  onMouseUp = null,
}) {
  const { field } = useController({
    name: fieldName,
    control,
  });

  const { getValues } = useFormContext();

  const inputClassName = `border ${boxStyle} p-4 my-2 rounded-xl w-full text-xs md:text-sm h-24 align-top text-gray-700 dark:text-gray-300 resize-none`;

  function copyText() {
    const textToCopy = getValues();

    navigator.clipboard.writeText(textToCopy[fieldName]).then(() => {
      alert("info", "Copied!");
    });
  }

  return (
    <div id={id} className="relative" data-cy={title + "Input"}>
      {title ? (
        <div
          className={`absolute z-10 -top-2 left-0 rounded-tr-full rounded-tl-full rounded-br-full py-1 px-4 ${headerStyle}`}
        >
          <h4 className="text-white dark:text-gray-300">{title}</h4>
        </div>
      ) : null}
      <CopyIndividualField onClick={() => copyText()} />

      <PredictiveInput
        field={field}
        placeholder={
          !placeholder ? `Type ${title} notes here...` : placeholder
        }
        positionStyles="p-4 h-24 my-2 border border-transparent"
        dictionary={defaultList}
        inputClassName={inputClassName}
        onMouseUp={onMouseUp}
      />
    </div>
  );
}
SOAPInput.propTypes = {
  control: PropTypes.any,
  title: PropTypes.any,
  fieldName: PropTypes.string,
  headerStyle: PropTypes.string,
  boxStyle: PropTypes.string,
  placeholder: PropTypes.string,
};
