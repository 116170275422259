import React, { useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  AutosaveProvider,
  UploadProgressProvider,
  useApplyTemplate,
  useSuperSoap,
} from "../../../hooks";
import {
  useTransition,
  animated,
  config,
  useSpring,
  useChain,
  useSpringRef,
} from "react-spring";

import CompletedSOAP from "./CompletedSOAP.js";
import EditableNoteFormWrapper from "./EditableNoteFormWrapper.js";
import { alert } from "../../common/Alert.js";
import { ConfirmModal } from "../../common/modals/PopupModals";
import { useMutation } from "@apollo/client";
import { DELETE_NOTE } from "./api/mutations";

export function EditableNoteSuperSOAP(props) {
  const { note, isSuperSOAP = true, virtualizer, index } = props;

  const [shouldShowDeleteNoteModal, setShouldShowDeleteNoteModal] =
    useState(false);
  const [isForDeletedNote, setIsForDeletedNote] = useState(false);

  const { uuidsOfCompletedNotes, completeNoteSuperSoap } =
    useSuperSoap();

  let isQuicknote =
    props.isQuicknote ||
    (note?.quickNote !== null && note?.quickNote !== undefined);
  let patientUUID = props?.patientUUID || note?.patient?.uuid;

  const noteUUID = note?.uuid;

  const fadeOutRef = useSpringRef();
  const popInRef = useSpringRef();
  const ref = useRef();

  const shouldShowCompletedNote = uuidsOfCompletedNotes[note.uuid]
    ? true
    : false;

  const shouldShowNoteForm = !shouldShowCompletedNote;

  const fadeOutProps = {
    ref: fadeOutRef,
    from: { opacity: 0, transform: "scaleY(1)" },
    enter: { opacity: 1, transform: "scaleY(1)" },
    leave: {
      opacity: 0,
      maxHeight: 0,
      transform: "scaleY(0)",
    },
    config: config.slow,
    onStart: () => {
      if (ref.current) {
        virtualizer.scrollToIndex(index);
      }
    },
  };

  const popInProps = {
    ref: popInRef,
    config: config.wobbly,
    opacity: shouldShowCompletedNote ? 1 : 0,
    transform: shouldShowCompletedNote ? "scale(1)" : "scale(0.8)",
  };

  const fadeOut = useTransition(shouldShowNoteForm, fadeOutProps);
  const popInAnim = useSpring(popInProps);
  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain([fadeOutRef, popInRef]);

  const { template } = useApplyTemplate({ noteUuid: noteUUID });

  return (
    <div className="relative">
      {shouldShowCompletedNote ? (
        <CompletedSOAP
          popInAnim={popInAnim}
          ref={ref}
          note={note}
          isForDeletedNote={isForDeletedNote}
        />
      ) : null}
      {fadeOut(
        (styles, item) =>
          item && (
            <animated.div style={styles}>
              <UploadProgressProvider>
                <AutosaveProvider>
                  <EditableNoteFormWrapper
                    note={note}
                    template={template}
                    isSuperSOAP={isSuperSOAP}
                    isQuicknote={isQuicknote}
                    patientUUID={patientUUID}
                    noteUUID={noteUUID}
                    shouldShowSubmitButton={!shouldShowCompletedNote}
                    shouldShowDeleteNoteModal={
                      shouldShowDeleteNoteModal
                    }
                    setShouldShowDeleteNoteModal={
                      setShouldShowDeleteNoteModal
                    }
                    setIsForDeletedNote={setIsForDeletedNote}
                  />
                </AutosaveProvider>
              </UploadProgressProvider>
            </animated.div>
          ),
      )}
    </div>
  );
}

EditableNoteSuperSOAP.propTypes = {
  note: PropTypes.object,
  isSuperSOAP: PropTypes.bool,
  isQuicknote: PropTypes.bool,
  patientUUID: PropTypes.string,
};
