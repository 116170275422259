import React from "react";
import YarnBall from "../../../assets/imgs/ball-of-yarn.svg";
import ScribblesLoading from "../../../assets/imgs/scribbles-loading.svg";
import styles from "./Loading.module.css";

export function SpinningYarn() {
  return (
    <div className={styles.spinningYarnContainer}>
      <img className={styles.spinningYarn} src={YarnBall} />
    </div>
  );
}

export default function Loading({ shouldShow }) {
  return shouldShow ? (
    <div>
      <SpinningYarn />
      <img className="mx-auto" src={ScribblesLoading} width="200px" />
    </div>
  ) : null;
}
