import React from "react";
import { useNavigate } from "react-router";
import Twemoji from "../../../Twemoji.js";
import { AudioPlayer } from "../../notebook/note/AudioPlayer.js";
import NoteCreatedAt from "../../notebook/note/NoteCreatedAt";
import NoteStatus from "../../notebook/note/NoteStatus.js";
import PatientTitle from "../../notebook/PatientTitle.js";
import { useWindowDimensions } from "../../../hooks/use-window-dimensions.js";
import { truncate } from "../../../utils.js";

function NoteItem(props) {
  const {
    dashboard = false,
    note,
    shouldShowAudioPlayer = false,
    style,
  } = props;
  const { width } = useWindowDimensions();
  const isMobile = width <= 768;
  const navigate = useNavigate();

  function handleNoteItemClick() {
    navigate(`/edit_note/${note.uuid}`);
  }

  return (
    <div style={style} className="w-full">
      <div
        className={`cursor-pointer hover:bg-indigo-100 hover:border-indigo-100 dark:border-gray-600 dark:hover:bg-gray-600 p-3 bg-gray-50 border border-gray-200 dark:bg-gray-700 rounded-lg self-center flex flex-col transition-all shadow-sm`}
        onClick={handleNoteItemClick}
      >
        <div className="flex flex-col items-start w-full">
          <div className="flex flex-row items-center w-full">
            <div className="flex-1 mr-2">
              <div className="text-xs text-gray-500 dark:text-gray-300">
                {note?.patient?.name ? (
                  <div>
                    {note?.jobType
                      ? `${note?.jobTypeName} for `
                      : "Note for "}
                    <PatientTitle
                      sex={note?.patient?.sex}
                      species={note?.patient?.species}
                      name={note?.patient?.name}
                      patientUUID={note?.patient?.uuid}
                      lastNameOfOwner={note?.patient?.lastNameOfOwner}
                      size="sm"
                      jobType={note?.jobType}
                    />
                  </div>
                ) : (
                  <div className="text-xs text-gray-500 dark:text-gray-300">
                    {note?.jobTypeName || "Quicknote"}
                  </div>
                )}
                <NoteCreatedAt
                  createdAt={note?.createdAt}
                  size="sm"
                />
              </div>
            </div>
            <NoteStatus note={note} />
          </div>

          {note?.title ? (
            <h5 className="text-xs md:text-sm font-medium mt-2 truncate">
              <Twemoji emoji="📝" /> {truncate(note?.title, 64)}
            </h5>
          ) : null}
        </div>

        {shouldShowAudioPlayer && note?.audioStorageLink ? (
          <AudioPlayer
            noteUuid={note?.uuid}
            shouldShowDeleteButton={false}
            shouldShowAddButton={false}
          />
        ) : null}
      </div>
    </div>
  );
}

export default NoteItem;
