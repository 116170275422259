import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function AddFieldButton({ onClick }) {
  return (
    <button
      id="addNewCustomFieldButton"
      className="group w-full py-1 px-2 bg-pink-50 dark:bg-gray-700 rounded-full mt-3 hover:bg-pink-100 dark:hover:bg-gray-600 transition-all"
      onClick={onClick}
    >
      <h4 className="text-gray-400 dark:text-gray-300 text-sm group-hover:text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-200 transition-all">
        <FontAwesomeIcon
          icon={faPlusCircle}
          className="fill-current  transition-all"
        />{" "}
        Add New Field...{" "}
      </h4>
    </button>
  );
}
