import { useQuery, useMutation } from "@apollo/client";
import {
  faInfoCircle,
  faTrash,
  faTruckMoving,
  faTimes,
  faPeopleArrows,
} from "@fortawesome/free-solid-svg-icons";
import { captureException } from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { config, animated, useTransition } from "react-spring";
import {
  useNoteMultiselect,
  useNoteMultiselectContext,
} from "../../../hooks";
import { MultiSelectActionButton } from "./MultiSelectActionButton";
import {
  copyNotesToPatientRedirect,
  moveNotesToPatientRedirect,
} from "./utils";
import getPatientsQuery from "../../../graphql/queries/GetPatients.js";
import { useFlags } from "launchdarkly-react-client-sdk";
import { TRANSFER_NOTES } from "../../../graphql/mutations/TransferNotes";
import { alert } from "../Alert";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import { TransferMultiModal } from "./TransferMultiModal";
import { useTeam } from "../../../hooks/use-team";

export function MultiSelectActionContainer({ notes, refetch }) {
  const navigate = useNavigate();
  const { currentTeam } = useTeam();
  const [error, setError] = useState(null);
  const [transferNotes] = useMutation(TRANSFER_NOTES, {
    onError: (error) => {
      setError(error.message);
    },
  });

  const isPatientPage =
    window.location.pathname.includes("/notebook/");

  const {
    numSelectedNotes,
    selectedNoteUuids,
    setShouldShowDeleteNoteModal,
    shouldShowDeleteNoteModal,
    resetSelectedNotesList,
    numPatientNotes,
  } = useNoteMultiselectContext();

  const { shouldAllowCopyAction } = useNoteMultiselect({
    notes,
    refetch,
  });

  const [shouldShow, setShouldShow] = useState(false);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [newUser, setNewUser] = useState("");
  const { transferNotes: isTransferNotesEnabled } = useFlags();

  const { data, loading } = useQuery(getPatientsQuery, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (numSelectedNotes > 0) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
  }, [numSelectedNotes]);

  const transitions = useTransition(shouldShow, {
    from: { opacity: 0, y: 120 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 120 },
    config: config.default,
  });

  if (shouldShowDeleteNoteModal) {
    return null;
  }

  if (loading) {
    return null;
  }

  const confirmAction = async () => {
    if (numPatientNotes > 0) {
      alert(
        "error",
        "Unsuccessful Note Transfer: You can't transfer notes associated with one of your patients.",
      );
    } else {
      //if the user is different than the current user, transfer the notes
      if (newUser && newUser !== localStorage.getItem("user_uuid")) {
        try {
          await transferNotes({
            variables: {
              noteUuids: Object.keys(selectedNoteUuids),
              newOwnerUuid: newUser,
            },
          });
          alert("success", "Notes transferred successfully!");
          refetch();
        } catch (e) {
          alert(
            "error",
            "Unsuccessful Note Transfer. Please try again later. If the issue persists, please contact support.",
          );
          captureException(e);
        }
      }
      setIsModalShowing(false);
      resetSelectedNotesList();
    }
  };

  return transitions((styles, item) =>
    item ? (
      <animated.div
        style={styles}
        className="fixed bottom-0 left-0 z-30 w-full flex flex-col items-center justify-center pointer-events-none"
      >
        <TransferMultiModal
          notes={notes}
          shouldShowTransferModal={isModalShowing}
          setShouldShowTransferModal={setIsModalShowing}
          refetch={refetch}
          confirmAction={confirmAction}
          loading={loading}
          setNewUser={setNewUser}
        />
        <div
          style={{
            boxShadow: "0px -5px 50px -10px rgba(0,0,0,0.40)",
          }}
          className="relative flex flex-col items-center bg-white dark:bg-gray-800 pt-4 pb-2 px-10 rounded-t-2xl pointer-events-auto"
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="absolute top-4 right-4 text-red-500 cursor-pointer"
            onClick={resetSelectedNotesList}
          />
          <div className="flex flex-row items-center justify-between w-full">
            <h4 className="flex-1 text-center mb-3 text-gray-500 dark:text-gray-400">
              ⚡️ {numSelectedNotes} note
              {numSelectedNotes > 1 ? "s" : ""} selected
            </h4>
          </div>
          <div className="flex flex-wrap md:flex-row space-x-2 space-y-2 md:space-y-0 items-center justify-center">
            {data?.patients?.length > 0 && (
              <>
                <MultiSelectActionButton
                  onClickActionDescription={"Move"}
                  onClick={() =>
                    moveNotesToPatientRedirect({
                      selectedNoteUuids,
                      navigate,
                    })
                  }
                  icon={faTruckMoving}
                  disabled={!shouldAllowCopyAction}
                />
              </>
            )}

            <MultiSelectActionButton
              onClickActionDescription={"Duplicate"}
              onClick={() =>
                copyNotesToPatientRedirect({
                  selectedNoteUuids,
                  navigate,
                })
              }
              icon={faClone}
              disabled={!shouldAllowCopyAction}
            />

            {isTransferNotesEnabled && currentTeam?.uuid && (
              <MultiSelectActionButton
                onClickActionDescription={"Transfer"}
                isForPatientSelectionAction={false}
                isForTeamsTransferAction={true}
                onClick={() => setIsModalShowing(true)}
                icon={faPeopleArrows}
                disabled={!shouldAllowCopyAction || isPatientPage}
              />
            )}
            <MultiSelectActionButton
              onClickActionDescription={"Delete"}
              onClick={() => setShouldShowDeleteNoteModal(true)}
              icon={faTrash}
              isForPatientSelectionAction={false}
              buttonColor="bg-red-400 hover:bg-red-500"
            />
          </div>
          {!shouldAllowCopyAction && (
            <h5 className="text-xs text-indigo-400 dark:text-gray-500 pt-2 text-center">
              <FontAwesomeIcon icon={faInfoCircle} /> You can&#39;t
              move, duplicate, or transfer notes labeled
              &#39;Processing&#39; or &#39;New Recording&#39;.
            </h5>
          )}
          {isPatientPage && (
            <h5 className="text-xs text-indigo-400 dark:text-gray-500 pt-2 text-center">
              <FontAwesomeIcon icon={faInfoCircle} /> You can&#39;t
              transfer notes that are assigned to a patient.
            </h5>
          )}
        </div>
      </animated.div>
    ) : null,
  );
}
