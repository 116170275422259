import {
  faCheckCircle,
  faCopy,
  faGrinStars,
  faStar,
} from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAccount } from "../../hooks";
import { alert } from "../common/Alert";
import InviteLink from "../common/InviteLink";
import {
  AccountItem,
  AccountItemBlock,
  AccountItemLine,
} from "./AccountItem";
import { SCRIBENOTE_WEB } from "../../constants";

export default function ReferralSettings({ user }) {
  const account = useAccount();

  const REFERRAL_CODE = user?.referral?.referralCode;
  const NUM_REFERRALS = user?.numReferralsMade;
  const REFERRAL_LINK = `${SCRIBENOTE_WEB}/auth/register?referralCode=${REFERRAL_CODE}`;

  return (
    <AccountItem
      title="Invites"
      icon={
        <FontAwesomeIcon icon={faGrinStars} size="lg" color="gray" />
      }
    >
      <div className="flex-1 flex flex-col mr-4">
        {REFERRAL_CODE ? (
          <>
            {account?.isSubscribed ? (
              <h5 className="text-xs md:text-sm">
                Each friend who signs up for Scribenote using your
                link will get 50 bonus Auto-Notes added to their note
                cap as a token of our appreciation. If they subscribe
                to a paid plan, you&#39;ll both get a{" "}
                <a
                  className="underline"
                  target="_blank"
                  href="https://www.scribenote.com/invites"
                  rel="noreferrer"
                >
                  free gift
                </a>{" "}
                🎁
              </h5>
            ) : (
              <h5 className="text-xs md:text-sm">
                Invite friends to join Scribenote to earn bonus notes!
                For each friend who signs up for Scribenote using your
                link, you will both get 25 bonus Auto-Notes added to
                your note cap as a token of our appreciation.
              </h5>
            )}

            <AccountItemLine title="Your Unique Link:">
              <InviteLink referralLink={REFERRAL_LINK} />
            </AccountItemLine>

            {account?.isSubscribed ? (
              <AccountItemLine>
                {NUM_REFERRALS > 0 ? (
                  <span className="font-medium">
                    Your link has been used {NUM_REFERRALS} time(s)
                    to-date. This means you&#39;ve helped{" "}
                    {NUM_REFERRALS} peer(s) save time on their
                    records!
                  </span>
                ) : (
                  <span className="font-medium">
                    Your invite link has not been used by anyone to
                    sign up. Try sharing it with more of your peers!
                  </span>
                )}
              </AccountItemLine>
            ) : (
              <AccountItemLine>
                {NUM_REFERRALS > 0 ? (
                  <span className="font-medium">
                    Your link has been used {NUM_REFERRALS} times
                    to-date. You&#39;ve earned {NUM_REFERRALS * 25}{" "}
                    bonus Auto-Notes.
                  </span>
                ) : (
                  <span className="font-medium">
                    Your invite link has not been used by anyone to
                    sign up. Try sharing it with more of your peers!
                  </span>
                )}
              </AccountItemLine>
            )}
          </>
        ) : (
          <h5 className="text-xs md:text-sm">
            It appears you have no invite link set for your account.
            Please try logging out and logging back into your account.
          </h5>
        )}
      </div>
    </AccountItem>
  );
}
