import React from "react";
import { ExistingNoteFields } from "./ExistingNoteFields.js";
import AutosuggestTextToggle from "../../common/AutocompleteTextToggle.js";
import NoteFormOptions from "./NoteFormOptions.js";

export function NoteHeader({
  note,
  control,
  isTemplate,
  isQuicknote,
  isSuperSOAP,
  isScribeEdit,
}) {
  const isOnboarding = location.pathname === "/dashboard";

  if (isTemplate) {
    return (
      <>
        <div className="flex-1 flex flex-row items-center justify-end">
          <div className="justify-self-end">
            <NoteFormOptions />
          </div>
        </div>
      </>
    );
  }

  return (
    <ExistingNoteFields
      note={note}
      control={control}
      isSuperSOAP={isSuperSOAP}
      isScribeEdit={isScribeEdit}
    />
  );
}
