import {
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";
import { useMutation, useQuery } from "@apollo/client";
import currentUserQuery from "../graphql/queries/CurrentUser.js";
import EDIT_USER from "../graphql/mutations/EditUser.js";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "./use-current-user.js";

const onboardingContext = createContext();

export function useOnboardingContext() {
  return useContext(onboardingContext);
}

function useProvideOnboarding() {
  const [shouldShowFirstLoginTour, setShouldShowFirstLoginTour] =
    useState(null);

  const [
    shouldShowDashboardHelperText,
    setShouldShowDashboardHelperText,
  ] = useState(false);

  return {
    shouldShowFirstLoginTour,
    setShouldShowFirstLoginTour,
    shouldShowDashboardHelperText,
    setShouldShowDashboardHelperText,
  };
}

export function OnboardingProvider({ children }) {
  const onboarding = useProvideOnboarding();

  return (
    <onboardingContext.Provider value={onboarding}>
      {children}
    </onboardingContext.Provider>
  );
}

export function useOnboarding() {
  const { data, loading, refetch } = useCurrentUser();
  const [editUser] = useMutation(EDIT_USER);

  const navigate = useNavigate();

  const {
    shouldShowFirstLoginTour,
    setShouldShowFirstLoginTour,
    shouldShowDashboardHelperText,
    setShouldShowDashboardHelperText,
  } = useOnboardingContext();

  const user = data?.user;

  useEffect(() => {
    if (!loading && user) {
      if (
        !user?.isOnboardingComplete &&
        !user?.account?.isSubscribed &&
        shouldShowFirstLoginTour === null
      ) {
        // first time a new user logs in, show them the tour
        setShouldShowFirstLoginTour(true);
        navigate("/tutorial");
      } else if (
        !user?.isOnboardingComplete &&
        !user?.account?.isSubscribed &&
        shouldShowFirstLoginTour === false
      ) {
        // new user has just finished the tour
        setShouldShowDashboardHelperText(true);
      }

      if (user?.isOnboardingComplete) {
        setShouldShowFirstLoginTour(false);
      }
    }
  }, [user, loading]);

  // Non subscribed users complete onboarding at the dashboard
  // subscribed users should complete on "Finish Tutorial Clicl"
  function subscribedUserCompleteOnboarding() {
    if (user?.account?.isSubscribed || user?.isAppointmentsBetaUser) {
      editUser({
        variables: { isOnboardingComplete: true },
      }).then(() => {
        refetch();
      });
    }
  }

  return {
    isOnboardingComplete: user?.isOnboardingComplete,
    isAppointmentsBetaUser: user?.isAppointmentsBetaUser,
    refetchUserOnboardingInfo: refetch,
    subscribedUserCompleteOnboarding,
    shouldShowFirstLoginTour,
    setShouldShowFirstLoginTour,
    shouldShowDashboardHelperText,
    setShouldShowDashboardHelperText,
  };
}
