import React from "react";
import { DateRangePicker } from "rsuite";
import "./styles.css";

const DateRangeSelection = ({
  selectedRange,
  handleApplyDateRange,
}) => {
  const handleDateRangeConfirm = (range) => {
    if (range && range[0] && range[1]) {
      const startDate = range[0];
      const endDate = range[1];
      handleApplyDateRange(startDate, endDate);
    }
  };

  const handleClean = () => {
    handleApplyDateRange(null, null);
  };

  return (
    <div className="">
      <DateRangePicker
        format="MMM dd, yyyy"
        character=" to "
        onOk={handleDateRangeConfirm}
        onClean={handleClean}
        value={[
          selectedRange.startDate
            ? new Date(selectedRange.startDate)
            : null,
          selectedRange.endDate
            ? new Date(selectedRange.endDate)
            : null,
        ]}
        placeholder="Filter by Date Range"
        ranges={[]}
        showOneCalendar
      />
    </div>
  );
};

export default DateRangeSelection;
