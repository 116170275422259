import Twemoji from "./Twemoji";

export const ENVIRONMENT = process.env.REACT_APP_DEV_ENV;

let graphql, flask, web, intercom_id, intercom_enabled;

// Stripe DEV / QA Price IDs
let basic_price_id_monthly = "price_1Kcx5gI2vDgGH9ZgFUSmHHuU";
let basic_price_id_yearly = "price_1MBjvKI2vDgGH9ZgT2QTGUsY";

let pro_price_id_monthly = "price_1Lv62VI2vDgGH9Zg0Aj0IASr";
let pro_price_id_yearly = "price_1Lv62VI2vDgGH9ZgocziVzvI";

let unlimited_price_id_monthly = "price_1N5sjEI2vDgGH9ZgzmSOZkW9";
let unlimited_price_id_yearly = "price_1N5sjEI2vDgGH9ZgNOnYdArP";

if (ENVIRONMENT === "prod") {
  graphql = "https://api-prod.scribenote.com/graphql";
  flask = "https://api-prod.scribenote.com";
  web = "https://app.scribenote.com";

  basic_price_id_monthly = "price_1KcxCeI2vDgGH9ZgBhx7UqFp";
  basic_price_id_yearly = "price_1MAerNI2vDgGH9ZgAlOjSZT9";

  pro_price_id_monthly = "price_1MAejzI2vDgGH9ZgRxzn9auK";
  pro_price_id_yearly = "price_1MAf30I2vDgGH9ZgFo9FVskR";

  unlimited_price_id_monthly = "price_1NssxbI2vDgGH9ZgssAWWA2g"; // updated oct 1 2023 (includes tax)
  unlimited_price_id_yearly = "price_1NssyHI2vDgGH9ZgatoTNqs6"; // updated oct 1 2023 (includes tax)
  intercom_id = process.env.REACT_APP_INTERCOM_APP_ID;
  intercom_enabled = true;
} else if (ENVIRONMENT === "qa") {
  graphql = "https://api-qa.scribenote.com/graphql";
  flask = "https://api-qa.scribenote.com";
  web = "https://qa.scribenote.com";

  intercom_id = "v8but558";
  intercom_enabled = true;
} else if (ENVIRONMENT === "ci") {
  graphql = "https://api-qa.scribenote.com/graphql";
  flask = "https://api-qa.scribenote.com";
  web = "https://qa.scribenote.com";

  intercom_id = process.env.REACT_APP_INTERCOM_APP_ID;
  intercom_enabled = false;
} else {
  graphql = "http://127.0.0.1:80/graphql";
  flask = "http://127.0.0.1:80";
  web = "http://localhost:3000";

  intercom_id = process.env.REACT_APP_INTERCOM_APP_ID;
  intercom_enabled = true;
}

// OUR SERVER EXPORTS
export const SCRIBENOTE_GRAPHQL = graphql;
export const SCRIBENOTE_FLASK = flask;
export const SCRIBENOTE_WEB = web;

// ****************** START STRIPE EXPORTS ******************

export const BASIC_PRICE_ID_MONTHLY = basic_price_id_monthly;
export const BASIC_PRICE_ID_YEARLY = basic_price_id_yearly;

export const PRO_PRICE_ID_MONTHLY = pro_price_id_monthly;
export const PRO_PRICE_ID_YEARLY = pro_price_id_yearly;

export const UNLIMITED_PRICE_ID_MONTHLY = unlimited_price_id_monthly;
export const UNLIMITED_PRICE_ID_YEARLY = unlimited_price_id_yearly;

export const BASIC_PRICING_MONTHLY = "29";
export const BASIC_PRICING_YEARLY = "299";
export const PRO_PRICING_MONTHLY = "89";
export const PRO_PRICING_YEARLY = "899";
export const UNLIMITED_PRICING_MONTHLY = "249";
export const UNLIMITED_PRICING_YEARLY = "1990";

export const BASIC_PRODUCT_NAME = "basic";
export const AUTO_PRODUCT_NAME = "auto";
export const PRO_PRODUCT_NAME = "pro";
export const UNLIMITED_PRODUCT_NAME = "unlimited";

export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// **************** END STRIPE EXPORTS ************************

// ****************** START INTERCOM EXPORTS ******************
export const INTERCOM_ENABLED = intercom_enabled;
export const INTERCOM_ID = intercom_id;
// ****************** END INTERCOM EXPORTS ******************

export const NORMAL_ON_EXAM = "Normal on exam";
export const BAR = "BAR, stable on exam";

export const OBJECTIVE_FIELDS = [
  {
    name: "temperature",
    label: "Temperature",
    type: "vital",
  },
  {
    name: "heartRate",
    label: "Heart Rate",
    type: "vital",
  },
  {
    name: "respiratoryRate",
    label: "Respiratory Rate",
    type: "vital",
  },
  {
    name: "capillaryRefillTime",
    label: "Capillary Refill Time",
    type: "vital",
  },
  {
    name: "bodyConditionScore",
    label: "Body Condition Score",
    type: "vital",
  },

  // ----------------- SYSTEMS --------------------

  {
    name: "eyes",
    label: "Eyes",
    type: "system",
  },
  {
    name: "ears",
    label: "Ears",
    type: "system",
  },
  {
    name: "oral",
    label: "Oral",
    type: "system",
  },
  {
    name: "cardiovascular",
    label: "Cardiovascular",
    type: "system",
  },
  {
    name: "respiratory",
    label: "Respiratory",
    type: "system",
  },
  {
    name: "neurological",
    label: "Neurological",
    type: "system",
  },
  {
    name: "musculoskeletal",
    label: "Musculoskeletal",
    type: "system",
  },
  {
    name: "lymphatic",
    label: "Lymphatic",
    type: "system",
  },
  {
    name: "gastrointestinal",
    label: "Gastrointestinal",
    type: "system",
  },
  {
    name: "reproductiveAndUrinary",
    label: "Reproductive & Urinary",
    type: "system",
  },
  {
    name: "coatAndSkin",
    label: "Coat & Skin",
    type: "system",
  },
  {
    name: "overall",
    label: "Overall",
    type: "overall",
  },
];

export const APPOINTMENT_OBJECTIVE_NORMAL_DEFAULTS = [
  {
    name: "temperature",
    label: "Temperature",
  },
  {
    name: "heartRate",
    label: "Heart Rate",
  },
  {
    name: "weight",
    label: "Weight",
  },
  {
    name: "respiratoryRate",
    label: "Respiratory Rate",
  },
  {
    name: "capillaryRefillTime",
    label: "Capillary Refill Time",
  },
  {
    name: "mucousMembranes",
    label: "Mucous Membranes",
  },
  {
    name: "hydrationStatus",
    label: "Hydration Status",
  },
  {
    name: "bodyConditionScore",
    label: "Body Condition Score",
  },
  {
    name: "painScore",
    label: "Pain Score",
  },
  {
    name: "fearAnxietyStressScore",
    label: "Fear Anxiety Stress Score",
  },
  {
    name: "eyes",
    label: "Eyes",
  },
  {
    name: "ears",
    label: "Ears",
  },
  {
    name: "nasal",
    label: "Nasal",
  },
  {
    name: "oralDental",
    label: "Oral",
  },
  {
    name: "cardiovascular",
    label: "Cardiovascular",
  },
  {
    name: "respiratory",
    label: "Respiratory",
  },
  {
    name: "neurological",
    label: "Neurological",
  },
  {
    name: "musculoskeletal",
    label: "Musculoskeletal",
  },
  {
    name: "lymphatic",
    label: "Lymphatic",
  },
  {
    name: "gastrointestinal",
    label: "Gastrointestinal",
  },
  {
    name: "reproductiveUrinary",
    label: "Reproductive & Urinary",
  },
  {
    name: "furSkin",
    label: "Integument",
  },
  {
    name: "masses",
    label: "Masses",
  },
];

export const TEMPLATE_COLOR_SCHEMES = {
  rose: {
    backgroundColor: "bg-rose-200 dark:bg-gray-600/20",
    headerColor: "bg-rose-400",
  },
  orange: {
    backgroundColor: "bg-orange-200 dark:bg-gray-600/20",
    headerColor: "bg-orange-400",
  },
  yellow: {
    backgroundColor: "bg-yellow-200 dark:bg-gray-600/20",
    headerColor: "bg-yellow-400",
  },
  lime: {
    backgroundColor: "bg-lime-200 dark:bg-gray-600/20",
    headerColor: "bg-lime-400",
  },
  teal: {
    backgroundColor: "bg-teal-200 dark:bg-gray-600/20",
    headerColor: "bg-teal-400",
  },
  cyan: {
    backgroundColor: "bg-cyan-200 dark:bg-gray-600/20",
    headerColor: "bg-cyan-400",
  },
  blue: {
    backgroundColor: "bg-blue-200 dark:bg-gray-600/20",
    headerColor: "bg-blue-400",
  },
  purple: {
    backgroundColor: "bg-purple-200 dark:bg-gray-600/20",
    headerColor: "bg-purple-400",
  },
};

export const DEFAULT_CLIENT_SUMMARY_PREFERENCES = {
  visitSummary: {
    include: true,
    description: "Brief Summary of Visit",
  },
  vitals: {
    include: false,
    description: "Vitals",
  },
  abnormalPhysicalFindings: {
    include: false,
    description: "Abnormal Physical Exam Findings",
  },
  allFindings: {
    include: false,
    description: "All Physical Exam Findings",
  },
  pointFormSummary: {
    include: false,
    description: "Assessment: Point Form",
  },
  oneLineSummary: {
    include: false,
    description: "Assessment: One Line Summary",
  },
  ownerInstructions: {
    include: false,
    description: (
      <span>
        Instructions for Owner <Twemoji emoji={"📋"} />
      </span>
    ),
  },
  medications: {
    include: false,
    description: (
      <span>
        Medications w/Instructions <Twemoji emoji={"💊"} />
      </span>
    ),
  },
  vaccinations: {
    include: false,
    description: (
      <span>
        Vaccinations <Twemoji emoji={"💉"} />
      </span>
    ),
  },
  diagnostics: {
    include: false,
    description: (
      <span>
        Diagnostics <Twemoji emoji={"🧪"} />
      </span>
    ),
  },
  diet: {
    include: false,
    description: (
      <span>
        Diet Recommendations <Twemoji emoji={"🍽️"} />
      </span>
    ),
  },
  followUpAppointments: {
    include: false,
    description: (
      <span>
        Follow-Up Appointments <Twemoji emoji={"📅"} />
      </span>
    ),
  },
  homeCare: {
    include: false,
    description: (
      <span>
        Home Care Recommendations <Twemoji emoji={"🏠"} />
      </span>
    ),
  },
};

//make this a constant so it doesn't rerender
export const TAB_ARRAY = [
  {
    id: "savedDrafts",
    serverFilterKey: "draft",
    title: "Drafts",
    color: "bg-indigo-300 dark:bg-indigo-500",
    colorStyles:
      "border-indigo-300 dark:border-indigo-500 from-indigo-50 to-transparent dark:from-indigo-500/10",
    isExportWindow: false,
    description:
      "These are the notes you've started working on. Click on any note to keep editing it.",
    countKey: "numSavedDrafts",
  },
  {
    id: "nowProcessing",
    serverFilterKey: "processing",
    title: "Processing",
    color: "bg-yellow-300 dark:bg-yellow-500",
    colorStyles:
      "border-yellow-300 dark:border-yellow-500 from-yellow-50 to-transparent dark:from-yellow-500/10",
    isExportWindow: false,
    description:
      "These are your recordings that are currently being processed and will automatically produce a note for review within a few minutes.",
    countKey: "numNowProcessing",
  },
  {
    id: "forReview",
    serverFilterKey: "review",
    title: "Available for Review",
    color: "bg-green-300 dark:bg-green-500",
    colorStyles:
      "border-green-300 dark:border-green-500 from-green-50 to-transparent dark:from-green-500/10",
    isExportWindow: false,
    description:
      "Once your recordings are processed into notes, they will appear here for you to review and edit.",
    countKey: "numForReview",
  },
  {
    id: "forExport",
    serverFilterKey: "export",
    title: "Ready to Export",
    color: "bg-blue-300 dark:bg-blue-500",
    colorStyles:
      "border-blue-300 dark:border-blue-500 from-blue-50 to-transparent dark:from-blue-500/10",
    isExportWindow: true,
    isAlreadyExported: false,
    description:
      "Exporting a note or clicking the checkmark icon will mark it as exported and move it to your exported notes tab.",
    countKey: "numForExport",
  },
  {
    id: "exportedItems",
    serverFilterKey: "exported",
    title: "Exported",
    color: "bg-purple-300 dark:bg-purple-500",
    colorStyles:
      "border-purple-300 dark:border-purple-500 from-purple-50 to-transparent dark:from-purple-500/10",
    isExportWindow: true,
    isAlreadyExported: true,
    description: "These are notes that have already been exported.",
    countKey: "numExportedItems",
  },
];
