import React, { useState, createContext, useContext } from "react";

const superSoapContext = createContext();

export const useSuperSoap = () => {
  return useContext(superSoapContext);
};

export function useProvideSuperSoap() {
  const [uuidsOfCompletedNotes, setUuidsOfCompletedNotes] = useState(
    {},
  );

  function completeNoteSuperSoap(noteUuid) {
    const updatedSelection = {
      ...uuidsOfCompletedNotes,
      [noteUuid]: true,
    };
    setUuidsOfCompletedNotes(updatedSelection);
  }

  return {
    uuidsOfCompletedNotes,
    setUuidsOfCompletedNotes,
    completeNoteSuperSoap,
  };
}

export function SuperSoapProvider({ children }) {
  const superSoap = useProvideSuperSoap();

  return (
    <superSoapContext.Provider value={superSoap}>
      {children}
    </superSoapContext.Provider>
  );
}
