import React, { useEffect, useRef, useState } from "react";
import { Controller, useController } from "react-hook-form";
import Twemoji from "../../../Twemoji";
import { useUploadProgress } from "../../../hooks";
import { ErrorMessage } from "./Input";
import mixpanel from "mixpanel-browser";

export function ProgressBar({ uploadProgress, width = "w-44" }) {
  return (
    <div
      className={`${
        width ? width : "w-44"
      } bg-gray-200 rounded-full h-2 dark:bg-gray-600`}
    >
      <div
        className="bg-indigo-600 h-2 rounded-full"
        style={{ width: `${uploadProgress}%` }}
      ></div>
    </div>
  );
}

export default function AttachmentSelection({
  isForNoteForm = true,
  control,
  acceptedFiles = "image/*, video/*, .pdf, .doc, .docx, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  shouldAcceptMultipleFiles = true,
  inputAction = null,
  clearAction = null,
  shouldClearUploads = null,
  setShouldClearUploads = null,
}) {
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const [err, setErr] = useState(false);
  const inputFile = useRef(null);

  const { field } = useController({
    name: "attachments",
    control,
  });

  const { currentFileName, percentageUploaded } = useUploadProgress();

  useEffect(() => {
    if (field && setShouldClearUploads !== null) {
      clearInput(field.onChange);
      setShouldClearUploads(false);
    }
  }, [shouldClearUploads]);

  function handleFiles(files) {
    mixpanel.track("Uploaded Attachment(s)");
    setErr(null);
    if (!files?.length) {
      return false;
    }
    const fileTypeArray = acceptedFiles.split(", ");

    let nextSelectedFileNames = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i]?.size / 1024 / 1024 > 2048) {
        setErr("File size exceeds the limit of 2 GB");
        return false;
      }
      // check if file is an image or a video, if so, format so it matches accepted files "image/*" or "video/*"
      let fileTypeCheck = files[i]?.type;

      if (files[i]?.type.split("/")[0] === "image") {
        fileTypeCheck = "image/*";
      }

      if (files[i]?.type.split("/")[0] === "video") {
        fileTypeCheck = "video/*";
      }

      if (!fileTypeArray.includes(fileTypeCheck)) {
        setErr(`File type not accepted: ${files[i]?.type}`);
        return false;
      }

      nextSelectedFileNames.push(files[i]?.name);
    }

    setSelectedFileNames(nextSelectedFileNames);

    if (inputAction) {
      inputAction();
    }
  }

  function clearInput(onChange) {
    inputFile.current.value = null;
    onChange(null);
    setSelectedFileNames([]);

    if (clearAction) {
      clearAction();
    }
  }

  const onDrop = (e, onChange) => {
    if (isForNoteForm && percentageUploaded) {
      // this is to prevent adding more files via drag and drop after the form has been submitted.
      return null;
    } else {
      e.preventDefault();
      const {
        dataTransfer: { files },
      } = e;
      handleFiles(files);
      onChange(files);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const handleClick = (e) => {
    inputFile.current.click();
  };

  function renderProgressBar({
    formattedFileName,
    indexInUploadsList,
    uploadListLength,
  }) {
    if (indexInUploadsList > uploadListLength) {
      return <ProgressBar uploadProgress={1} />;
    }

    if (formattedFileName === currentFileName) {
      return <ProgressBar uploadProgress={percentageUploaded} />;
    }

    return null;
  }

  return (
    <>
      <div className="relative">
        <Controller
          control={control}
          render={({ field: { onChange } }) => (
            <>
              {selectedFileNames?.length ? (
                <div
                  className="rounded-full bg-red-400 hover:bg-red-500 px-2 py-1 absolute z-20 -top-2 -right-2 transition-colors pointer-events-auto cursor-pointer"
                  onClick={() => {
                    clearInput(onChange);
                  }}
                >
                  <h4 className="text-white dark:text-white text-xs">
                    Clear
                  </h4>
                </div>
              ) : null}

              <div
                className={`border-2 ${
                  selectedFileNames?.length
                    ? "border-solid border-green-300 bg-green-50 dark:border-green-600 dark:bg-gray-700"
                    : "border-dashed border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700"
                }  rounded-xl p-4 cursor-pointer`}
                onDrop={(e) => onDrop(e, onChange)}
                onDragOver={(e) => onDragOver(e)}
                onClick={(e) => handleClick(e)}
              >
                <div className="dark:text-gray-400 text-center text-xs md:text-sm">
                  {selectedFileNames?.length
                    ? selectedFileNames.map((fileName, index) => {
                        let formattedFileName = fileName;
                        return (
                          <div
                            key={fileName + "-" + index}
                            className="flex flex-row items-center justify-between space-x-3"
                          >
                            <h5 className="text-sm flex-1 max-w-sm overflow-hidden overflow-ellipsis whitespace-nowrap">
                              {formattedFileName}
                            </h5>

                            {renderProgressBar({
                              formattedFileName,
                              indexInUploadsList: index,
                              uploadListLength:
                                selectedFileNames.length,
                            })}
                          </div>
                        );
                      })
                    : `Drag and drop ${
                        !shouldAcceptMultipleFiles
                          ? "a file"
                          : "one or more files"
                      } here, or click to browse`}
                </div>
                <input
                  type="file"
                  disabled={isForNoteForm && percentageUploaded}
                  ref={inputFile}
                  multiple={shouldAcceptMultipleFiles}
                  style={{ display: "none" }}
                  accept={acceptedFiles}
                  onInput={(e) => {
                    handleFiles(e.target.files);
                  }}
                  onChange={(e) => {
                    onChange(e.target.files);
                  }}
                />
              </div>
            </>
          )}
          name="attachments"
        />
      </div>

      {err && <ErrorMessage message={err} />}
    </>
  );
}
