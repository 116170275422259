export const getStrokeColor = ({
  toothConditions = {},
  toothId = "",
} = {}) => {
  const condition = toothConditions[toothId];
  if (condition && condition.toLowerCase().includes("missing")) {
    return "#475569";
  } else {
    return "#FFFFFF";
  }
};

export const getFillColor = ({
  toothConditions = {},
  toothId = "",
} = {}) => {
  const condition = toothConditions[toothId]?.toLowerCase(); // Convert condition to lowercase

  if (condition) {
    if (condition.includes("missing")) {
      return "#ffffff";
    }
    if (
      condition.includes("extracted") ||
      condition.includes("extraction")
    ) {
      return "#2dd4bf";
    }
    switch (condition) {
      case "normal":
      case "stable":
      case "not mentioned":
        return "#d4d4d8";
      case "unerupted":
        return "#fb7185";
      default:
        return "#fbbf24";
    }
  }
  return "#d4d4d8"; // Default color if condition is undefined or not provided
};
