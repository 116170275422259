import React from "react";
import { AuditTrailListItem } from "./AuditTrailListItem";

export function AuditTrailList({ auditTrail }) {
  if (!auditTrail?.length) {
    return null;
  }

  return (
    <div className="space-y-2 mt-6">
      {auditTrail.map((audit, index) => {
        return <AuditTrailListItem key={index} audit={audit} />;
      })}
    </div>
  );
}
