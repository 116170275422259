import React, { useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { Select, MenuItem, FormControl } from "@mui/material";
import { useDarkMode } from "../../../../../../hooks";

const AddUsersTable = ({
  users = [],
  isExisting,
  setUsers,
  onRemove,
  error,
}) => {
  const [inputValues, setInputValues] = useState(users);
  const [enabled] = useDarkMode();

  useEffect(() => {
    if (setUsers) {
      setUsers(inputValues);
    }
  }, [inputValues, setUsers]);

  const handleInputChange = (e, index, field) => {
    const value = e?.target?.value;
    setInputValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = {
        ...newValues[index],
        [field]: value,
      };
      return newValues;
    });
  };

  const selectStyles = useMemo(
    () => ({
      "& .MuiSelect-select": {
        padding: "0.5px 8px",
        color: enabled ? "rgb(209 213 219)" : "black",
        backgroundColor: enabled ? "rgb(55 65 81)" : "white",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    }),
    [enabled],
  );

  const handleRemove = (index, isExisting) => {
    onRemove(index, isExisting);
    setInputValues((prevValues) => {
      const newValues = [...prevValues];
      newValues.splice(index, 1);
      return newValues;
    });
  };

  return (
    <table className="table-auto w-full dark:text-gray-300 text-left">
      <thead>
        <tr className="border-b dark:border-gray-700/50">
          <th className="font-semibold text-sm w-1/3">Email</th>
          <th className="font-semibold text-sm w-1/5">First Name</th>
          <th className="font-semibold text-sm w-1/5">Last Name</th>
          <th className="font-semibold text-sm w-1/5">
            Role (optional)
          </th>
          <th className="w-1/20"></th>
        </tr>
      </thead>
      <tbody>
        {inputValues?.map((user, index) => (
          <tr key={index}>
            <td className={`w-1/3 ${index === 0 ? "pt-2" : ""}`}>
              <p className="w-full bg-transparent text-gray-900 dark:text-gray-300">
                {user?.email ?? ""}
              </p>
            </td>
            <td className={`w-1/5 ${index === 0 ? "pt-2" : ""}`}>
              {isExisting ? (
                <p className="w-full bg-transparent text-gray-900 dark:text-gray-300">
                  {user?.firstName ?? ""}
                </p>
              ) : (
                <input
                  type="text"
                  defaultValue={user?.firstName ?? ""}
                  className={`w-full bg-white dark:bg-gray-700 border ${
                    error && !inputValues[index]?.firstName
                      ? "border-red-500"
                      : "border-none"
                  } text-gray-900 dark:text-gray-300 pl-2 rounded-sm`}
                  onChange={(e) =>
                    handleInputChange(e, index, "firstName")
                  }
                />
              )}
            </td>
            <td className={`w-1/5 ${index === 0 ? "pt-2" : ""}`}>
              {isExisting ? (
                <p className="w-full bg-transparent text-gray-900 dark:text-gray-300">
                  {user?.lastName ?? ""}
                </p>
              ) : (
                <input
                  type="text"
                  defaultValue={user?.lastName ?? ""}
                  className={`w-full bg-white dark:bg-gray-700 border ${
                    error && !inputValues[index]?.lastName
                      ? "border-red-500"
                      : "border-none"
                  } text-gray-900 dark:text-gray-300 pl-2 rounded-sm`}
                  onChange={(e) =>
                    handleInputChange(e, index, "lastName")
                  }
                />
              )}
            </td>
            <td className={`w-1/5 ${index === 0 ? "pt-2" : ""}`}>
              <FormControl
                variant="outlined"
                className="w-full bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-300 rounded-sm"
              >
                <Select
                  value={inputValues[index]?.roleInt}
                  onChange={(e) =>
                    handleInputChange(e, index, "roleInt")
                  }
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        backgroundColor: enabled
                          ? "rgb(55 65 81)"
                          : "white",
                        color: enabled ? "rgb(209 213 219)" : "black",
                      },
                    },
                  }}
                  sx={selectStyles}
                >
                  <MenuItem value={1}>Doctor</MenuItem>
                  <MenuItem value={2}>Care Team</MenuItem>
                </Select>
              </FormControl>
            </td>
            <td className="w-1/20 text-center">
              <FontAwesomeIcon
                icon={faMinusCircle}
                className="text-red-500 hover:text-red-700 cursor-pointer"
                onClick={() => handleRemove(index, isExisting)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AddUsersTable;
