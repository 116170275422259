import React from "react";

export function AccountItemLine({ title, children }) {
  return (
    <div className="dark:text-gray-300 py-1 text-xs md:text-sm flex flex-row space-x-2 items-center">
      {title ? <strong className="mr-2">{title}</strong> : null}{" "}
      {children}
    </div>
  );
}

export function AccountItemBlock({ title, desc, children }) {
  return (
    <div className="dark:text-gray-300 py-1 flex flex-col md:flex-row space-y-2 w-full">
      {(title || desc) && (
        <span className="text-xs md:text-sm my-2 md:max-w-xs">
          {title ? <strong className="mr-2">{title}</strong> : null}
          {desc ? desc : null}
        </span>
      )}

      <div className="flex-1 md:ml-10">{children}</div>
    </div>
  );
}

export function AccountItem({ title, children, icon }) {
  return (
    <div
      className={
        "flex flex-col lg:flex-row border rounded-lg dark:border-gray-700 p-3 shadow-sm lg:items-center"
      }
    >
      <div
        className={
          "mr-2 mb-4 lg:mb-0 lg:mr-10 self-start lg:w-44 flex flex-row items-baseline"
        }
      >
        {icon}
        <h3 className="ml-2 md:ml-4 text-base md:text-xl">{title}</h3>
      </div>
      <div className="flex flex-1 flex-col lg:flex-row lg:items-center space-y-2 lg:space-y-0 space-x-4">
        {children}
      </div>
    </div>
  );
}
