import {
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  config,
  animated,
  useTransition,
  useSpring,
} from "react-spring";

export function RecordingTranscriptDrawer({
  transcript,
  isMedicalRecord,
}) {
  const [shouldShow, setShouldShow] = useState(false);

  const slideAnim = useSpring({
    transform: shouldShow ? "translateY(10px)" : "translateY(425px)",
    config: config.default,
  });

  return (
    <animated.div
      style={slideAnim}
      className={`fixed bottom-0 ${
        isMedicalRecord ? "left-28" : "left-0"
      } z-30 w-full flex flex-col items-center justify-center pointer-events-none ${
        shouldShow ? "z-40" : null
      }`}
    >
      <div className="flex flex-col items-center pointer-events-auto">
        <div
          className="relative flex flex-col items-center bg-white dark:bg-gray-700 pt-6 pb-4 px-6 rounded-t-2xl"
          style={{
            boxShadow: "0px -5px 50px -10px rgba(0,0,0,0.40)",
          }}
        >
          <button
            onClick={() => setShouldShow(!shouldShow)}
            className="absolute -top-10 z-40 bg-indigo-400 dark:bg-indigo-500 py-2 px-8 rounded-t-2xl"
          >
            <h4 className="text-white">
              {shouldShow ? "Hide Transcript" : "Show Transcript"}{" "}
              <FontAwesomeIcon
                icon={shouldShow ? faChevronDown : faChevronUp}
              />
            </h4>
          </button>
          <h5 className="text-xs md:text-sm w-[300px] md:w-[600px] h-96 overflow-y-scroll ">
            {transcript}
          </h5>
        </div>
      </div>
    </animated.div>
  );
}
