import moment from "moment";

export function getDaysLeftInFreeTrial(freeTrialExpiresAt) {
  const daysLeftInFreeTrial = moment(freeTrialExpiresAt).diff(
    moment(),
    "days",
  );

  if (daysLeftInFreeTrial < 0) {
    return 0;
  }

  return daysLeftInFreeTrial;
}
