import { NORMAL_ON_EXAM, OBJECTIVE_FIELDS } from "../../../constants";

export function getObjectiveSectionText(note) {
  let objectiveText = note?.objective || "";

  if (note?.weight && note?.weight !== NORMAL_ON_EXAM) {
    return "Weight: " + note?.weight + "\n" + objectiveText;
  } else {
    return objectiveText;
  }
}

export function getObjectiveFieldsToShow(note) {
  let OBJECTIVE_FIELDS_TO_SHOW = [];

  // this will filter out the objective fields that are not supposed to be shown
  OBJECTIVE_FIELDS.filter((field) => {
    if (note[field.name] !== null) {
      OBJECTIVE_FIELDS_TO_SHOW.push(field);
    }
  });

  return OBJECTIVE_FIELDS_TO_SHOW;
}

export function getObjectiveFieldsString(note) {
  let objectiveFieldsString = "";

  if (note?.weight && note?.weight !== NORMAL_ON_EXAM) {
    objectiveFieldsString += `Weight: ${note?.weight} \n`;
  }

  getObjectiveFieldsToShow(note).forEach((field) => {
    objectiveFieldsString += `${field.label}: ${note[field.name]} \n`;
  });

  // get custom objective field text
  if (note?.customObjectiveFields?.length > 0) {
    note?.customObjectiveFields.forEach(
      ({
        name,
        value,
        symmetricalValueLeft,
        symmetricalValueRight,
        symmetryType,
        symmetryLabels,
      }) => {
        if (symmetryType === "None") {
          objectiveFieldsString += `${name}: ${value} \n`;
        } else {
          objectiveFieldsString += `${name}: (${symmetryLabels.left}) ${symmetricalValueLeft} / (${symmetryLabels.right}) ${symmetricalValueRight} \n`;
        }
      },
    );
  }

  return objectiveFieldsString;
}
