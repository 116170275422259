import {
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { alert } from "../../common/Alert";
import { TextareaAutosize } from "@mui/base";
import { ConfirmModal } from "../../common/modals/PopupModals";
import ADD_FEEDBACK_TO_LOG from "../../../graphql/mutations/AddFeedbackToLog";
import { useMutation } from "@apollo/client";

export default function PromptFeedbackWidget({
  additionalClasses,
  jobType,
  noteUuid,
  userUuid,
  thumbsUp,
  feedback,
}) {
  const [submittedResult, setSubmittedResult] = useState(
    thumbsUp === true ? "good" : thumbsUp === false ? "bad" : null,
  );
  const [additionalFeedback, setAdditionalFeedback] =
    useState(feedback);
  const [showModal, setShowModal] = useState(false);
  const [addFeedbackToLog] = useMutation(ADD_FEEDBACK_TO_LOG);
  function handleSubmit(result) {
    if (result === "good") {
      setSubmittedResult("good");
      alert(
        "success",
        "Glad to hear that! We'll use this feedback to improve future auto-note results.",
      );
      // not going to hit endpoint for good results until user decides whether to provide additional feedback in modal
      setShowModal(true);
    }
    if (result === "bad") {
      setSubmittedResult("bad");
      alert(
        "warning",
        "Oops, sorry this result wasn't quite good! We'll use this feedback to improve future auto-note results.",
      );
      // not going to hit endpoint for bad results until user decides whether to provide additional feedback in modal
      setShowModal(true);
    }
  }

  const handleFeedbackSubmission = (
    includeAdditionalFeedback = false,
  ) => {
    // feedback data payload
    const feedbackData = {
      variables: {
        userUuid: userUuid,
        noteUuid: noteUuid,
        rating: submittedResult,
        noteType: jobType,
      },
    };

    // If additional feedback should be included, add it to the feedbackData
    if (includeAdditionalFeedback) {
      feedbackData.variables.feedback = additionalFeedback;
    }

    // Submit feedback mutation
    addFeedbackToLog(feedbackData);

    // Close modal
    setShowModal(false);
  };
  return (
    <div
      className={`bg-indigo-50 dark:bg-indigo-500/50 rounded-xl py-2 px-4 flex flex-row items-center justify-between w-full ${additionalClasses}`}
    >
      <h5 className="font-medium text-indigo-500 dark:text-indigo-100">
        What did you think of this auto-note result?
      </h5>
      <div className="space-x-1">
        <button
          onClick={() => handleSubmit("good")}
          className={`${
            submittedResult === "good"
              ? "bg-green-400 text-white"
              : "text-gray-50 dark:text-indigo-700 hover:text-white bg-green-200 hover:bg-green-400"
          }  rounded-full h-8 w-8 shadow-sm transition-colors`}
        >
          <FontAwesomeIcon icon={faThumbsUp} />
        </button>
        <button
          onClick={() => handleSubmit("bad")}
          className={`${
            submittedResult === "bad"
              ? "bg-red-400 text-white"
              : "text-gray-50 dark:text-indigo-700 hover:text-white bg-red-200 hover:bg-red-400"
          }  rounded-full h-8 w-8 shadow-sm transition-colors`}
        >
          <FontAwesomeIcon icon={faThumbsDown} />
        </button>
      </div>
      <ConfirmModal
        shouldShow={showModal}
        hideModal={() => handleFeedbackSubmission(false)}
        title={"Additional Feedback"}
        cancelText={"Skip"}
        cancelAction={() => handleFeedbackSubmission(false)}
        confirmAction={() => handleFeedbackSubmission(true)}
      >
        <div className="flex flex-col">
          <TextareaAutosize
            minRows={3}
            placeholder={
              "Let us know how we can improve this note (optional)"
            }
            value={additionalFeedback}
            onChange={(e) => setAdditionalFeedback(e.target.value)}
            className="resize-none appearance-none relative block w-full px-2 py-1 border dark:bg-gray-700 dark:border-gray-700 dark:text-gray-200 border-gray-100 bg-gray-100 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 text-xs md:text-sm h-8"
          />
        </div>
      </ConfirmModal>
    </div>
  );
}
