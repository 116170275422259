import { gql } from "@apollo/client";

export default gql`
  mutation flagNoteToSupport(
    $noteUuid: String
    $comment: String
    $customerName: String
    $email: String
    $title: String
  ) {
    flagNoteToSupport(
      noteUuid: $noteUuid
      comment: $comment
      customerName: $customerName
      email: $email
      title: $title
    ) {
      ok
    }
  }
`;
