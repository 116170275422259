import React from "react";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function QuickActionInstructionText() {
  return (
    <h5 className="text-gray-400 dark:text-gray-500 my-2">
      Click the <FontAwesomeIcon icon={faBolt} size="xs" /> icons to
      select one or more notes for Quick Action.
    </h5>
  );
}
