import {
  faCheck,
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { useAutosaveContext } = require("../../../../hooks");

export default function AutosaveState() {
  const { isSaved, isFormErrors } = useAutosaveContext();

  if (isFormErrors) {
    return (
      <h5 className="text-yellow-400 dark:text-yellow-500 text-sm">
        <FontAwesomeIcon icon={faExclamationTriangle} /> Couldn&#39;t
        autosave. Please check form!
      </h5>
    );
  }

  return (
    <h5 className="text-gray-400 dark:text-gray-500 text-sm">
      {!isSaved ? (
        <span>
          <FontAwesomeIcon
            icon={faSpinner}
            className="animate-spin"
          />{" "}
          Saving...
        </span>
      ) : (
        <span>
          <FontAwesomeIcon icon={faCheck} /> Saved
        </span>
      )}
    </h5>
  );
}
