import React from "react";
import { useNavigate } from "react-router-dom";

/** A pretty cancel button for forms.
 * Sends the back user to the screen they came from on click. */
export function CancelButton({ disabled }) {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        navigate(-1);
      }}
      disabled={disabled}
      className="border-2 border-gray-400 rounded-full px-4 py-2 text-lg font-semibold text-gray-600 dark:text-white disabled:hover:bg-gray-200 hover:dark:bg-gray-400 hover:bg-gray-200 max-w-xs text-center my-2 transition-all focus:outline-none"
    >
      Cancel
    </button>
  );
}
