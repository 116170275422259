import React, { useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { Controller } from "react-hook-form";

export default function HeaderTextBox({
  control,
  defaultValue,
  setIsEditing,
}) {
  return (
    <div className="flex flex-1 flex-row items-center space-x-4">
      <Controller
        name={"headerInfoPdf"}
        control={control}
        rules={{ required: false, minLength: 1 }}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextareaAutosize
            minRows={3}
            id="headerTextBox"
            className="appearance-none relative block w-full px-3 py-2 h-20 border dark:bg-gray-700 dark:border-gray-700 dark:text-gray-200 border-gray-100 bg-gray-100 placeholder-gray-400 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:z-10 text-xs md:text-sm resize-none"
            type="text"
            placeholder="Enter clinic name, address, email, phone number, etc."
            onChange={(value) => onChange(value)}
            defaultValue={defaultValue}
            onFocus={() => setIsEditing(true)}
          />
        )}
      />
    </div>
  );
}
