import { gql, useMutation } from "@apollo/client";
import {
  faExclamationCircle,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { alert } from "../../common/Alert";

export const RETRY_FAILED_AUTO_SOAP = gql`
  mutation retryFailedAutoSoap($noteUuid: String, $jobType: String) {
    retryFailedAutoSoap(noteUuid: $noteUuid, jobType: $jobType) {
      ok
    }
  }
`;

export default function NoteStatus({ note }) {
  const [retryFailedAutoSoap, { data, error }] = useMutation(
    RETRY_FAILED_AUTO_SOAP,
  );
  const [isHovering, setIsHovering] = useState(false);
  const [shouldRetryProcessing, setShouldRetryProcessing] =
    useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      moment() > moment(note?.createdAt).add(7, "minutes") &&
      note?.isMarkedAsProcessing
    ) {
      setShouldRetryProcessing(true);
    }
  }, []);
  return (
    <div className="flex flex-row items-center space-x-1">
      {note?.isNewRecording && !note?.isMarkedAsProcessing && (
        <div
          title="This is a brand new recording. You haven't made a note
              from it yet."
          className="px-2 py-1 text-[10px] border border-cyan-500 text-cyan-500 rounded-full self-start hover:cursor-help"
        >
          NEW RECORDING
        </div>
      )}
      {note?.isMarkedAsReview &&
        !note?.isApproved &&
        !note?.isMarkedAsFailed &&
        !note?.isUnacceptable && (
          <div
            title="Your SOAP note has been completed and is
              ready for your review."
            className="px-2 py-1 text-[10px] border border-green-500 text-green-500 rounded-full self-start hover:cursor-help"
          >
            REVIEW
          </div>
        )}
      {note?.isDraft && (
        <div
          title="You&#39;ve started working on this note already."
          className="px-2 py-1 text-[10px] border border-indigo-500 text-indigo-500 rounded-full self-start hover:cursor-help"
        >
          DRAFT
        </div>
      )}
      {note?.hasBeenExported && (
        <div
          title="You&#39;ve exported this note before."
          className="px-2 py-1 text-[10px] border border-blue-500 text-blue-500 rounded-full self-start hover:cursor-help"
        >
          EXPORTED
        </div>
      )}
      {note?.isUnacceptable && !note?.isApproved && (
        <>
          <div
            title="This recording failed to be processed into a note."
            className="px-2 py-1 text-[10px] border border-red-500 text-red-500 rounded-full self-start hover:cursor-help mr-1"
          >
            FAILED
          </div>
          <FontAwesomeIcon
            size="1x"
            className="text-red-500 hover:cursor-help"
            icon={faExclamationCircle}
          />
        </>
      )}
      {note?.isTagged && note?.isApproved && (
        <div
          title="This note is complete."
          className="px-2 py-1 text-[10px] border border-green-500 text-green-500 rounded-full self-start hover:cursor-help"
        >
          COMPLETED
        </div>
      )}
      {note?.isMarkedAsProcessing && (
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={(event) => {
            // Prevents click from propegating through to the component underneath
            // Ie, in dashboard, without event.stopPropagation(), the click would transition to the note
            // due to the Inbox Item recieving the click.
            event.stopPropagation();
            if (shouldRetryProcessing) {
              retryFailedAutoSoap({
                variables: {
                  noteUuid: note?.uuid,
                  jobType: note?.jobType,
                },
              })
                .then(() => {
                  alert(
                    "warning",
                    "Retrying failed Automatic Note...",
                  );
                  setShouldRetryProcessing(false);
                  if (location.pathname != "/dashboard") {
                    navigate("/dashboard");
                  }
                })
                .catch((error) => {
                  alert("error", error.message);
                });
            }
          }}
          title={
            shouldRetryProcessing
              ? "Processing taking too long? Click to retry."
              : "This recording is now automatically being processed into a SOAP."
          }
          className={`${
            shouldRetryProcessing
              ? "hover:cursor-pointer hover:bg-red-500 hover:border-red-500 hover:text-white "
              : "hover:cursor-help"
          } px-2 py-1 text-[10px] border border-yellow-500 text-yellow-500 rounded-full self-start transition-all`}
          data-cy="processingStatus"
        >
          {isHovering && shouldRetryProcessing
            ? "RETRY"
            : "PROCESSING"}
        </div>
      )}
      {note?.isMarkedAsFailed && !note?.isIncomplete ? (
        <div
          onClick={(event) => {
            // Prevents click from propagating through to the component underneath
            // Ie, in dashboard, without event.stopPropagation(), the click would transition to the note
            // due to the Inbox Item receiving the click.
            event.stopPropagation();

            retryFailedAutoSoap({
              variables: {
                noteUuid: note?.uuid,
                jobType: note?.jobType,
              },
            })
              .then(() => {
                alert("warning", "Retrying failed Automatic Note...");

                if (location.pathname != "/dashboard") {
                  navigate("/dashboard");
                }
              })
              .catch((error) => {
                alert("error", error.message);
              });
          }}
          title="This recording could not be processed into a note. Click to retry."
          className="px-2 py-1 text-xs shadow-sm hover:shadow-none rounded-full self-start bg-red-500 hover:bg-red-600 text-white hover:cursor-pointer transition-all"
        >
          RETRY <FontAwesomeIcon icon={faRedo} />
        </div>
      ) : null}
      {note?.isIncomplete ? (
        <div title="This recording may be incomplete. Click for details.">
          <FontAwesomeIcon
            size="1x"
            className="text-yellow-400 hover:cursor-help"
            icon={faExclamationCircle}
          />
        </div>
      ) : null}
    </div>
  );
}
