import React, { useState } from "react";
import {
  useSpring,
  config,
  animated,
  useTransition,
} from "react-spring";
import { RecordButton } from "../../common/buttons/RecordButton.js";
import { StopButton } from "../../common/buttons/StopButton.js";
import AudioAnalyser from "./AudioAnalyser.js";
import { Timer } from "./Timer.js";
import { UploadingRecordingButton } from "./UploadingRecordingButton.js";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faPlay,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { CancelRecordingModal } from "./CancelRecordingModal.js";

export function RecordingSection({
  id,
  isRecording,
  isPaused,
  isLoading,
  audio,
  uploadStatus,
  startRecording,
  stopRecording,
  pauseRecording,
  cancelRecording,
  startPausedRecording,
  trackColor,
}) {
  const [
    shouldShowCancelRecordingModal,
    setShouldShowCancelRecordingModal,
  ] = useState(false);

  const isCurrentlyRecording = isRecording && audio;
  const isCurrentlyUploading =
    isRecording && !audio && uploadStatus !== 100;

  const fadeAnalyzer = useSpring({
    config: config.default,
    opacity: isCurrentlyRecording && !isPaused ? 1 : 0,
  });
  const fadeTimer = useSpring({
    config: config.default,
    opacity: isCurrentlyRecording ? 1 : 0,
  });
  const pauseButtonClass =
    "w-16 h-16 flex flex-row items-center justify-center bg-indigo-500/70 hover:bg-indigo-600/70  rounded-full cursor-pointer shadow-md";

  function renderPausePlayButton() {
    return (
      <div>
        {isPaused ? (
          <div
            className={pauseButtonClass}
            onClick={() => startPausedRecording()}
          >
            <FontAwesomeIcon
              icon={faPlay}
              size="lg"
              color="white"
              className="mx-auto"
            />
          </div>
        ) : (
          <div
            className={pauseButtonClass}
            onClick={() => pauseRecording()}
          >
            <FontAwesomeIcon
              icon={faPause}
              size="lg"
              color="white"
              className="mx-auto"
            />
          </div>
        )}
      </div>
    );
  }

  function renderNonRecordingStateButtons() {
    if (isCurrentlyUploading) {
      return (
        <UploadingRecordingButton
          uploadStatus={uploadStatus}
          trackColor={trackColor}
        />
      );
    }

    if (isLoading) {
      return (
        <div
          className={`from-indigo-400 to-indigo-800 absolute z-20 w-3/4 h-3/4 cursor-pointer flex text-center rounded-full transition-all bg-gradient-to-br place-items-center place-content-center focus:outline-none shadow-lg`}
        >
          <FontAwesomeIcon
            icon={faSpinner}
            size="2x"
            color="white"
            className="mx-auto animate-spin"
          />
        </div>
      );
    }

    return (
      <RecordButton
        id={id}
        onClick={() => {
          startRecording();
        }}
      />
    );
  }

  return (
    <>
      <CancelRecordingModal
        shouldShowCancelRecordingModal={
          shouldShowCancelRecordingModal
        }
        setShouldShowCancelRecordingModal={
          setShouldShowCancelRecordingModal
        }
        cancelRecording={cancelRecording}
      />
      {isCurrentlyRecording ? (
        <div className="flex flex-row justify-center items-center relative h-full w-full">
          <animated.div
            style={fadeAnalyzer}
            className="absolute z-10 w-96"
          >
            <AudioAnalyser audio={audio} />
          </animated.div>
          <animated.div
            style={fadeTimer}
            className="absolute z-20 self-center -bottom-4 flex flex-row items-end justify-between"
          >
            {renderPausePlayButton()}
            <Timer start={moment.utc()} isPaused={isPaused} />
            <div
              className="w-16 h-16 flex flex-row items-center justify-center bg-red-500/70 hover:bg-red-600/70  rounded-full cursor-pointer shadow-md"
              onClick={() => setShouldShowCancelRecordingModal(true)}
            >
              <FontAwesomeIcon
                icon={faTimes}
                size="2x"
                color="white"
                className="mx-auto"
              />
            </div>
          </animated.div>
          <StopButton
            isPaused={isPaused}
            onClick={() => stopRecording()}
          />
        </div>
      ) : (
        renderNonRecordingStateButtons()
      )}
    </>
  );
}
