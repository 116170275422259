export const defaultList = [
  "Patient presented with",
  "No V/D/C/S",
  "Healthy, stable for",
  "Doing well on all medications",
  "Dietary indiscretion",
  "Gentamicin",
  "Clindamycin",
  "Amoxicillin",
  "Enrofloxacin",
  "Cephalexin",
  "Cefpodoxime",
  "Cefazolin",
  "Trimethoprim-Sulfa",
  "Doxycycline",
  "Metronidazole",
  "Prednisone",
  "Prednisolone",
  "Triamcinolone",
  "Methylprednisolone",
  "Fentanyl",
  "Morphine",
  "Hydromorphone",
  "Buprenorphine",
  "Butorphanol",
  "Tramadol",
  "Carprofen",
  "Meloxicam",
  "Ketoprofen",
  "Firocoxib",
  "Deracoxib",
  "Flunixin Meglumine",
  "Phenylbutazone",
  "Ketamine",
  "Tiletamine",
  "Valium",
  "Propofol",
  "Lidocane",
  "Bupivicaine",
  "Dexmedetomidine",
  "Apomorphine",
  "Thiopental",
  "Isoflurane",
  "Sevoflurane",
  "Furosemide",
  "Enalapril",
  "Benazapril",
  "Pimobendan",
  "Spironolactone",
  "Amlodipine",
  "Atropine",
  "Fenbendazole",
  "Sulfadimethoxine",
  "Pyrantel",
  "Emodepside",
  "Praziquantel",
  "Pyrantel",
  "Febantel",
  "Selamectin",
  "Ivermectin",
  "Milbemycin",
  "Famotidine",
  "Carafate",
  "Metoclopramide",
  "Maropitant",
  "Cisapride",
  "Omeperazole",
  "Misoprostol",
  "Methocarbamol",
  "Fluconazole",
  "Trilostane",
  "Levothyroxine",
  "Methimazole",
  "Diphenhydramine",
  "Benadryl",
  "Tapazole",
  "Vetoryl",
  "Epinephrine",
  "Guafenisen",
  "Pentobarbital",
  "Phenobarbital",
  "Mirtazapine",
  "Gabapentin",
];

export const chars = [
  "ad lib",
  "ACTH",
  "ADP",
  "ALT",
  "AST",
  "ATP",
  "bid",
  "bpm",
  "BUN",
  "C",
  "cal",
  "CBC(s)",
  "CDC",
  "CFU",
  "CK",
  "cm",
  "CNS",
  "CPK",
  "CPR",
  "CSF",
  "CT",
  "cu",
  "DIC",
  "dL",
  "DNA",
  "ECG",
  "eg",
  "ELISA",
  "EPA",
  "EPG",
  "et",
  "EU",
  "F",
  "FDA",
  "fL",
  "ft",
  "g",
  "gal.",
  "GI",
  "GnRH",
  "H&E",
  "Hgb",
  "hr",
  "ie",
  "Ig",
  "IL",
  "IM",
  "in.",
  "IP",
  "IU",
  "IV",
  "kcal",
  "kg",
  "L",
  "LDH",
  "lb",
  "m",
  "M",
  "Mcal",
  "mcg",
  "mcm",
  "ME",
  "mEq",
  "mg",
  "min",
  "mL",
  "mm",
  "mo",
  "mol",
  "mOsm",
  "nm",
  "MRI",
  "NRC",
  "NSAID(s)",
  "OIE",
  "oz",
  "p/pp",
  "PCR",
  "PCV",
  "pH",
  "PO",
  "ppb",
  "ppm",
  "qid",
  "qs",
  "RBC(s)",
  "RNA",
  "RT-PCR",
  "SC",
  "sec",
  "SPF",
  "sq",
  "tbsp",
  "TDN",
  "tid",
  "tsp",
  "U",
  "WBC(s)",
  "wk",
  "wt",
  "yr year(s)",
];

export const GLOSSARY = [
  "abdomen",
  "abdominal cavity",
  "abdominocentesis",
  "abortion",
  "abscess",
  "acariasis",
  "acaricide",
  "ACE inhibitor",
  "acidic",
  "acquired",
  "acromegaly",
  "actinobacillosis",
  "actinomycosis",
  "acupuncture",
  "acute",
  "acute pain",
  "Addison's disease",
  "adenocarcinoma",
  "adenoma",
  "adenovirus",
  "adrenal gland",
  "aerosol",
  "aflatoxicosis",
  "African horse sickness",
  "agalactia",
  "agent",
  "aggression",
  "agonist",
  "agouti",
  "airway",
  "algal poisoning",
  "alkaline",
  "alkaloidosis",
  "allergy",
  "alloimmune reaction",
  "alopecia",
  "alternative therapy",
  "alveoli",
  "alveolus",
  "amino acid",
  "amoeba",
  "amphetamine",
  "amphibian",
  "amputation",
  "amyloid",
  "amyloidosis",
  "anabolic",
  "analgesia",
  "analgesic",
  "anaphylactic shock",
  "anemia",
  "anesthesia",
  "anesthetic",
  "anestrus",
  "aneurysm",
  "animal-assisted therapy",
  "animal welfare",
  "anorexia",
  "anoxia",
  "antacid",
  "antagonist",
  "anterior",
  "anterior uvea",
  "anthelmintic",
  "anthrax",
  "antibacterial",
  "antibiotic",
  "antibiotic resistance",
  "antibody",
  "anticholinergic",
  "anticoagulant",
  "anticonvulsant",
  "antidepressant",
  "antidote",
  "antiemetic",
  "antifungal",
  "antigen",
  "antihistamine",
  "anti-inflammatory",
  "antimicrobial",
  "antineoplastic",
  "antioxidant",
  "antiparasitic",
  "antiseptic solution",
  "antitoxin",
  "antivenin",
  "antiviral",
  "anus",
  "aorta",
  "aortic arch",
  "aortic valve",
  "aplasia",
  "aquatic",
  "arboreal",
  "arrhythmia",
  "arsenical",
  "arterial dilator",
  "artery",
  "arthritis",
  "arthroscopy",
  "artificial insemination",
  "ascarid",
  "ascites",
  "as-needed dosing",
  "aspergillosis",
  "asphyxiation",
  "aspiration",
  "aspiration pneumonia",
  "asthma",
  "astringent",
  "atherosclerosis",
  "atopy",
  "atria",
  "atrial fibrillation",
  "atrial standstill",
  "atrial thrombosis",
  "atrioventricular valve",
  "atrium",
  "aural plaque",
  "autoimmune",
  "autonomic nervous system",
  "avian tuberculosis",
  "awn",
  "axon",
  "B cell",
  "bacteremia",
  "bacteria",
  "bacterial disease",
  "bacterium",
  "barbering",
  "baroreceptors",
  "basal cells",
  "basking",
  "basophil",
  "benign",
  "besnoitiosis",
  "beta cells",
  "biceps brachii tendon",
  "biopsy",
  "bladder",
  "blastoma",
  "blastomycosis",
  "blepharitis",
  "blind spot",
  "blister",
  "blood cell count",
  "blood cells",
  "blood clot",
  "blood poisoning",
  "blood pressure",
  "blood transfusion",
  "blood typing",
  "bloodborne",
  "bloodstream",
  "body cavity",
  "boid snakes",
  "bone marrow",
  "borreliosis",
  "botulism",
  "brachycephalic",
  "brachygnathia",
  "brachytherapy",
  "brackish",
  "bradycardia",
  "brain",
  "brain stem",
  "brain stem auditory evoked response (BAER)",
  "breed",
  "breeding",
  "broad-spectrum antibiotics",
  "bronchial",
  "bronchitis",
  "bronchoscopy",
  "buck",
  "budgerigar",
  "bumblefoot",
  "bursa",
  "calcification",
  "calcinosis",
  "calcinosis cutis",
  "calcitonin",
  "calcium deficiency",
  "caloric",
  "cancer",
  "cancer pain",
  "candidiasis",
  "Canidae",
  "canine",
  "canine distemper (hardpad disease)",
  "canine herpesviral infection",
  "canine thrombopathia",
  "canine tooth",
  "cannibalism",
  "capillary",
  "capsule",
  "carcass",
  "carcinogen",
  "carcinoma",
  "cardiac",
  "cardiac output",
  "cardiac shunts",
  "cardiac tamponade",
  "cardiomyopathy",
  "cardiopulmonary resuscitation (CPR)",
  "cardiovascular system",
  "carnivore",
  "carpal",
  "carpal joint",
  "carrier",
  "cartilage",
  "castration",
  "cataract",
  "cathartic",
  "catheter",
  "cationic",
  "ceiling effect",
  "cell",
  "cell body",
  "cellular infiltrates",
  "centesis",
  "central nervous system",
  "cercariae",
  "cerebellar hypoplasia",
  "cerebellum",
  "cerebrospinal fluid",
  "cerebrum",
  "cesarean section",
  "cestode",
  "chelonians",
  "chemotherapy",
  "cherry eye",
  "chlamydiosis",
  "Chlamydophila",
  "cholesteatomas",
  "chondrosarcoma",
  "chordae tendineae",
  "choroid",
  "chromosome",
  "chronic",
  "chronic pain",
  "cilia",
  "cilia-associated respiratory Bacillus",
  "ciliary muscles",
  "cleft palate",
  "cloaca",
  "cloacal prolapse",
  "cloacitis",
  "clostridia",
  "clot",
  "coagulation",
  "cobalt therapy",
  "Coccidia",
  "coccidioidomycosis",
  "coccidiosis",
  "cochlea",
  "coelomitis",
  "cognitive dysfunction",
  "cold-blooded",
  "colibacillosis",
  "colic",
  "colitis",
  "collagen",
  "colon",
  "colonoscopy",
  "colony",
  "colostrum",
  "colt",
  "colubrid",
  "combination chemotherapy",
  "companion animal",
  "compensatory mechanism",
  "complementary therapy",
  "compulsive behavior",
  "computed tomography (CT)",
  "conduction",
  "conformation",
  "congenital defect",
  "congestion",
  "congestive heart failure",
  "conjunctiva",
  "conjunctivitis",
  "constipation",
  "constricted toe syndrome",
  "contagious",
  "contaminated",
  "contraction",
  "contrast procedure",
  "convulsion",
  "coprophagia",
  "cornea",
  "coronavirus",
  "corticosteroid",
  "cortisol",
  "counterconditioning",
  "counterirritant",
  "counterpressure",
  "cranial",
  "cranium",
  "cream",
  "crop",
  "cross matching",
  "crotalids",
  "cryptococcosis",
  "cryptorchidism",
  "cryptosporidiosis",
  "Cryptosporidium",
  "CT scan",
  "culture",
  "Cushing's disease",
  "cutaneous",
  "cyst",
  "cystitis",
  "cystocentesis",
  "cytokine",
  "cytology",
  "cytotoxic",
  "debilitated",
  "debulking",
  "decongestant",
  "defecate",
  "defibrillation",
  "deficiency",
  "degeneration",
  "degenerative joint disease",
  "dehydration",
  "dendrite",
  "dental abscess",
  "dental malocclusion",
  "depression",
  "dermatitis",
  "dermatologist",
  "dermatophytosis",
  "dermis",
  "descending aorta",
  "desensitization",
  "detergent",
  "dewlap",
  "diabetes insipidus",
  "diabetes mellitus",
  "diagnosis",
  "diaphragm",
  "diarrhea",
  "diastole",
  "diestrus",
  "dietary indiscretion",
  "digestion",
  "digestive tract",
  "dilate",
  "dilated cardiomyopathy",
  "direct life cycle",
  "discharge",
  "disinfectant",
  "dislocation",
  "displacement activity",
  "disseminated intravascular coagulation",
  "dissemination",
  "distemper",
  "distended",
  "distillation",
  "diuretic",
  "DNA",
  "doe",
  "dormant",
  "duodenum",
  "dustborne",
  "dysecdysis",
  "dysplasia",
  "dystocia",
  "dystrophy",
  "ear canal",
  "ear mites",
  "eardrum",
  "ecdysis",
  "echocardiography",
  "eclampsia",
  "ecosystem",
  "ectoparasite",
  "ectotherm",
  "ectropion",
  "edema",
  "egg binding",
  "ehrlichiosis",
  "elapid",
  "electrocardiogram (EKG)",
  "electrocardiography",
  "electrolytes",
  "electromyography",
  "ELISA",
  "Elokomin fluke fever",
  "emaciation",
  "embolism",
  "embolus",
  "embryo",
  "emphysema",
  "emulsifier",
  "emulsion",
  "encephalitis",
  "encephalitozoonosis",
  "encysted",
  "endocarditis",
  "endocrine",
  "endoparasite",
  "endoscope",
  "endoscopic gastric biopsy",
  "endoscopy",
  "endotoxin",
  "endotracheal tube",
  "engorge",
  "enterolith",
  "enterotoxemia",
  "entropion",
  "enzootic",
  "enzyme",
  "eosinopenia",
  "eosinophil",
  "eosinophilia",
  "epidemic",
  "epidermis",
  "epidural anesthesia",
  "epilepsy",
  "epithelial",
  "epizootic lymphangitis",
  "equine",
  "equine granulocytic ehrlichiosis",
  "equine infectious anemia",
  "equine morbillivirus pneumonia",
  "equine protozoal myeloencephalitis",
  "equine sarcoid",
  "equine viral arteritis",
  "erosion",
  "erythrocyte",
  "erythropoiesis",
  "erythropoietin",
  "esophagus",
  "estrous",
  "estrous cycle",
  "estrus",
  "euthanasia",
  "excretion",
  "exertional rhabdomyolysis",
  "exoskeleton",
  "exotic pets",
  "expectorant",
  "exposure",
  "eyelid atresia",
  "failing heart",
  "farcy",
  "farrier",
  "fasting",
  "fatal",
  "fatty acid",
  "fecal",
  "feces",
  "feed hopper",
  "feline",
  "feline distemper",
  "feline infectious enteritis",
  "feline infectious peritonitis",
  "feline leukemia virus",
  "feline lymphoma",
  "feline panleukopenia",
  "femur",
  "fertility",
  "fertilization",
  "fetal",
  "fetal membranes",
  "fetus",
  "fever",
  "fibrocartilage",
  "fibroma",
  "fibromatosis",
  "fibropapillomatosis",
  "fibrosarcoma",
  "fibrosis",
  "fibrous osteodystrophy",
  "fibrous tissue",
  "filly",
  "filtration",
  "first aid",
  "fistulous withers",
  "flagellate",
  "flashing",
  "flea",
  "flexor tendons",
  "flora",
  "fluke",
  "fluorescein staining",
  "fly strike",
  "foal",
  "forceps",
  "forebrain",
  "foreskin",
  "fossorial species",
  "fracture",
  "frostbite",
  "fry",
  "fungal",
  "fungus",
  "fur mite",
  "fur slip",
  "gait",
  "gastrin",
  "gastrinoma",
  "gastroenteritis",
  "gastrointestinal",
  "gastrointestinal system",
  "gastrostomy",
  "genetic",
  "genitalia",
  "geotrichosis",
  "gestation",
  "Giardia",
  "giardiasis",
  "gingivitis",
  "glanders",
  "glaucoma",
  "gliding membrane",
  "globin",
  "glomerulonephritis",
  "glomerulonephrosis",
  "glomerulus",
  "glossitis",
  "glucagon",
  "glucocorticoid",
  "glucose",
  "glucosuria",
  "gnat",
  "goiter",
  "goitrogen",
  "gonads",
  "gout",
  "granule",
  "granulocyte",
  "gray patch disease",
  "growth plate",
  "gut loading",
  "guttural pouch",
  "habituation",
  "halogenated",
  "hardpad disease",
  "head tilt",
  "heart disease",
  "heart failure",
  "heat",
  "heat exhaustion",
  "heat stroke",
  "helminth",
  "hemangiosarcoma",
  "hematology",
  "hematoma",
  "hematopoiesis",
  "heme",
  "hemoglobin",
  "hemolysis",
  "hemorrhage",
  "hemostasis",
  "hemostatic",
  "hepatic",
  "hepatic encephalopathy",
  "hepatitis",
  "hepatopathy",
  "herbicide",
  "herbivores",
  "hereditary",
  "hernia",
  "herpesvirus",
  "hibernation",
  "histiocytoma",
  "histology",
  "histoplasmosis",
  "hives",
  "hock",
  "hormone",
  "host",
  "hot packing",
  "human-animal bond",
  "husbandry",
  "hutch",
  "hutch burn",
  "hydration",
  "hydrocephalus",
  "hygiene",
  "hygroma",
  "hyper-",
  "hyperadrenocorticism",
  "hypercalcemia",
  "hyperextension",
  "hyperlipemia",
  "hyperparathyroidism",
  "hyperplasia",
  "hypertension",
  "hyperthermia",
  "hyperthyroidism",
  "hypertrichosis",
  "hypertrophy",
  "hyperuricemia",
  "hypo-",
  "hypoadrenocorticism",
  "hypocalcemia",
  "hypoglycemia",
  "hypoparathyroidism",
  "hypoplasia",
  "hypoproteinemia",
  "hypothalamus",
  "hypothermia",
  "hypothyroidism",
  "hypoxia",
  "idiopathic",
  "immersion",
  "immune",
  "immune response",
  "immune system",
  "immune-mediated",
  "immunization",
  "immunized",
  "immunodeficiency",
  "immunoglobulin",
  "immunomodulator",
  "immunostimulant",
  "immunosuppressed",
  "immunosuppression",
  "immunotherapy",
  "impedance",
  "implant",
  "incisor",
  "inclusion body disease",
  "incontinence",
  "incubation period",
  "infection",
  "infectious",
  "infectious canine hepatitis",
  "infertility",
  "infest",
  "infestation",
  "infiltrate",
  "inflammation",
  "inflammatory",
  "influenza",
  "ingest",
  "inhalation",
  "inherited",
  "inhibitor",
  "initiation",
  "inoculation",
  "inorganic",
  "insect growth regulator",
  "insecticidal",
  "insulin",
  "insulinoma",
  "interdigital furunculosis",
  "interferon",
  "intermediate host",
  "intestinal protozoan",
  "intestine",
  "intoxication",
  "intramuscular",
  "intraocular",
  "intravascular",
  "intravenous",
  "intubation",
  "intussusception",
  "invasive",
  "invertebrate",
  "involuntary",
  "iodine deficiency",
  "iodine toxicity",
  "iris",
  "irradiation",
  "islet cell tumor",
  "islet of Langerhans",
  "isolation",
  "jaundice",
  "joint",
  "joint mice",
  "jugular",
  "keratin",
  "keratinization",
  "keratoacanthoma",
  "ketosis",
  "kidney",
  "kidney failure",
  "kit",
  "laceration",
  "lacrimal gland",
  "lactation",
  "Lactobacillus species",
  "lagenidiosis",
  "lagophthalmos",
  "lameness",
  "larva",
  "larvae",
  "larval",
  "larynx",
  "latent",
  "laxative",
  "leg banding",
  "Legg-Calvé-Perthes disease",
  "lens",
  "leptospirosis",
  "lesion",
  "lethargy",
  "leukemia",
  "leukocyte",
  "leukocytic",
  "leukocytosis",
  "leukogram",
  "leukopenia",
  "libido",
  "lice",
  "life cycle",
  "ligament",
  "liposarcoma",
  "listeriosis",
  "liver",
  "localized",
  "lockjaw",
  "long bones",
  "lordosis",
  "lumpectomy",
  "lumpy jaw",
  "lung",
  "luxation",
  "Lyme disease",
  "lymph node",
  "lymph",
  "lymphadenitis",
  "lymphangitis",
  "lymphatic system",
  "lymphocyte",
  "lymphocytic choriomeningitis",
  "lymphocytosis",
  "lymphoid",
  "lymphoma",
  "lymphopenia",
  "macrophage",
  "maggot",
  "magnetic resonance imaging (MRI)",
  "malabsorption",
  "malaise",
  "maldigestion",
  "malignant",
  "malnourished",
  "malocclusion",
  "mammals",
  "mammary",
  "mammary glands",
  "mange",
  "marking",
  "marsupials",
  "masking behavior",
  "mass",
  "mast cell",
  "mastitis",
  "maternal immunity",
  "mechanism",
  "megakaryocyte",
  "melanoma",
  "melioidosis",
  "membrane",
  "meninges",
  "meningitis",
  "meningoencephalitis",
  "metabolic activity",
  "metabolic bone disease",
  "metabolic disorder",
  "metabolic rate",
  "metabolism",
  "metacarpal bones",
  "metastasis",
  "metastatic calcification",
  "metastatic tumor",
  "metatarsal bones",
  "metritis",
  "microbiology",
  "microchipping",
  "microfilaria",
  "microhabitat",
  "microorganism",
  "midges",
  "miliary dermatitis",
  "mineralocorticoids",
  "mineral",
  "mite",
  "mitral valve",
  "molar",
  "mold",
  "molt",
  "Mongolian desert mice",
  "monkeypox",
  "monocyte",
  "monocytopenia",
  "monocytosis",
  "mortality",
  "mosquito",
  "motor",
  "motor function",
  "motor neurons",
  "MRI",
  "mucosa",
  "mucous membrane",
  "mucus",
  "multifocal",
  "murmur",
  "muscle",
  "muscular wasting",
  "musculoskeletal",
  "Mustelidae",
  "mutation",
  "muzzle",
  "mycetoma",
  "mycobacterial infections",
  "mycobacteriosis",
  "mycoplasma",
  "mycosis",
  "mycotoxicosis",
  "myelography",
  "myiasis",
  "myocardial disease",
  "myocarditis",
  "myocardium",
  "myopathy",
  "myositides",
  "myositis",
  "myxomatosis",
  "nagana",
  "nares",
  "nasal",
  "nasal cavity",
  "nasal discharge",
  "nasogastric tube",
  "nasolacrimal duct",
  "nebulization",
  "necropsy",
  "necrotic dermatitis",
  "necrotic meningoencephalitis",
  "nematode",
  "neonatal",
  "neoplasia",
  "neoplasm",
  "neosporosis",
  "nephritis",
  "nephrotic",
  "nephrotic syndrome",
  "nerve",
  "nest box",
  "neuroendocrine tissue tumors",
  "neurologic",
  "neurologic signs",
  "neuromuscular",
  "neuron",
  "neuropathic pain",
  "neurotransmitter",
  "neuter",
  "neutropenia",
  "neutrophil",
  "neutrophilia",
  "nevus",
  "Newcastle disease",
  "nictitating membrane",
  "nit",
  "nitrates",
  "nocardiosis",
  "nocturnal",
  "nodule",
  "noncontagious",
  "nonregenerative anemia",
  "nonsteroidal anti-inflammatory drug (NSAID)",
  "nonviremic",
  "nuclear scintigraphy",
  "nutrient",
  "nutrition",
  "nutritional deficiency",
  "nutritional osteodystrophy",
  "nymph",
  "nymphomania",
  "nystagmus",
  "obstruction",
  "ocular",
  "ocular fundus",
  "ointment",
  "oncologist",
  "oncology",
  "oocyst",
  "oomycosis",
  "opaque",
  "ophthalmoscope",
  "opiate",
  "opioid",
  "opportunistic agent",
  "opportunistic infection",
  "optic disk",
  "optic nerve",
  "optimal temperature zone",
  "oral",
  "oral cavity",
  "orbit",
  "organ",
  "organic",
  "organism",
  "osmoregulation",
  "osteoarthritis",
  "osteochondritis dissecans",
  "osteochondrosis",
  "osteomalacia",
  "osteomyelitis",
  "osteoporosis",
  "osteosarcoma",
  "otitis",
  "otitis externa",
  "otitis interna",
  "otitis media",
  "otoscope",
  "outbreak",
  "ovary",
  "ovariohysterectomy",
  "over-the-counter",
  "ovulation",
  "oxygenate",
  "oxytocin",
  "paecilomycosis",
  "palliative",
  "palpation",
  "pancreas",
  "panhypopituitarism",
  "papilloma",
  "papillomatosis",
  "papule",
  "paralysis",
  "paramyxovirus",
  "paranasal",
  "parasite",
  "parasitic",
  "parasitism",
  "parathyroid glands",
  "parathyroid hormone",
  "parvovirus",
  "passerine",
  "paste",
  "pasteurellosis",
  "patagium",
  "patella",
  "patellar luxation",
  "patent ductus arteriosus",
  "pathogenic",
  "pathologic",
  "pathologist",
  "pectoral muscle",
  "pelvic canal",
  "penicillosis",
  "penis",
  "pentastomes",
  "perianal",
  "pericardial disease",
  "pericardiocentesis",
  "pericarditis",
  "pericardium",
  "periodontal",
  "perioperative",
  "peripheral nervous system",
  "peritoneal cavity",
  "peritoneal lavage",
  "peritoneum",
  "peritonitis",
  "permeability",
  "persistent",
  "pesticide",
  "pH",
  "phaeohyphomycosis",
  "phagocyte",
  "phagocytosis",
  "phalanx",
  "pharmacology",
  "pharynx",
  "pheochromocytoma",
  "pheromone",
  "phobia",
  "photoperiod",
  "photoreceptor",
  "photosensitization",
  "pica",
  "pigmentation",
  "pink eye",
  "pinna",
  "pinworm",
  "piping",
  "pituitary",
  "pituitary dwarfism",
  "pituitary gland",
  "placenta",
  "placental",
  "plague",
  "plasma",
  "plasmodial organisms",
  "platelet",
  "pleura",
  "pleural cavity",
  "pleurisy",
  "pneumonia",
  "pneumonitis",
  "pneumothorax",
  "pododermatitis",
  "poison",
  "polioencephalomalacia",
  "poll evil",
  "polyarteritis nodosa",
  "polycythemia",
  "polydactyly",
  "polyp",
  "polyphagia",
  "polysaccharide",
  "porphyrin",
  "portosystemic shunt",
  "positive inotrope",
  "postpartum hypocalcemia",
  "powder",
  "predation",
  "predilection",
  "predisposing factor",
  "pregnancy toxemia",
  "prenatal",
  "prepuce",
  "prevalent",
  "priapism",
  "primary factor",
  "prognathia",
  "prognosis",
  "progressive",
  "prokinetic",
  "prolapse",
  "proliferative enteropathy",
  "promoter",
  "promotion",
  "prophylaxis",
  "prostate gland",
  "prostration",
  "protein",
  "protocol",
  "protozoa",
  "protozoal",
  "protozoan",
  "protrusion",
  "pruritus",
  "pseudomyiasis",
  "psittacine",
  "psychotropic drug",
  "puberty",
  "puerperal hypocalcemia",
  "puerperal tetany",
  "pulmonary",
  "pulmonary artery",
  "pulmonary edema",
  "pulmonary hypertension",
  "pulmonary valve",
  "pulse",
  "pupa",
  "pupil",
  "pus",
  "pustules",
  "pyloric stenosis",
  "pyoderma",
  "pyometra",
  "pythiosis",
  "rabbit calicivirus disease",
  "rabbitpox",
  "rabbitry",
  "radiation",
  "radiation therapy",
  "radiography",
  "radioisotope irradiation",
  "radiotherapy",
  "radius",
  "radon",
  "range (as in range plants)",
  "rash",
  "receptor",
  "recurrence",
  "recurrent",
  "reflex",
  "regenerative anemia",
  "regurgitate",
  "reinfestation",
  "reinforcement",
  "relapse",
  "remission",
  "renal",
  "replicate",
  "reproduction",
  "reproductive system",
  "reptile",
  "resistance",
  "resistant",
  "resorption",
  "respiratory",
  "respiratory failure",
  "respiratory sinus arrhythmia",
  "respiratory system",
  "respiratory tract",
  "reticulocyte",
  "retina",
  "retrovirus",
  "reverse osmosis",
  "rhabdomyolysis (sporadic exertional)",
  "rhinitis",
  "rhinosporidiosis",
  "rickets",
  "rickettsiae",
  "ringtail",
  "ringworm",
  "RNA",
  "Rocky Mountain spotted fever",
  "rodenticide",
  "root canal",
  "Rotavirus",
  "roughage",
  "roundworm",
  "ruminant",
  "saline",
  "saliva",
  "salmon poisoning disease",
  "salmonellosis",
  "sanitation",
  "sarcocystosis",
  "sarcoid (equine)",
  "sarcoma",
  "scabies",
  "scar tissue",
  "scintigraphy",
  "sclera",
  "scoliosis",
  "scraping",
  "screwworm",
  "scrotum",
  "scurvy",
  "scute",
  "sebaceous gland",
  "seborrhea",
  "sebum",
  "secondary hyperparathyroidism",
  "secretion",
  "sedate",
  "sedative",
  "sedentary",
  "seizure",
  "semen",
  "semiaquatic",
  "semilunar valves",
  "sensory",
  "sepsis",
  "septal defect",
  "septic shock",
  "septicemia",
  "septicemic cutaneous ulcerative disease (SCUD)",
  "serotype",
  "service animal",
  "sheath",
  "shock",
  "sign",
  "silage",
  "sinoatrial node",
  "sinus (cardiology)",
  "sinus (respiratory system)",
  "sinus arrest",
  "sinusitis",
  "skeletal disorder",
  "skin appendage",
  "skin tenting",
  "small intestine",
  "smear",
  "soft tissue",
  "solution",
  "solvent",
  "somatostatin",
  "soundness",
  "spasm",
  "spawning",
  "spay",
  "species",
  "spina bifida",
  "spinal",
  "spinal cord",
  "spine",
  "spirurid",
  "splay leg",
  "spleen",
  "splint",
  "sporadic",
  "spore",
  "sporocyst",
  "sporotrichosis",
  "spot-on",
  "squamous",
  "squamous cell carcinoma",
  "stamina",
  "star-gazing",
  "stenosis",
  "stereotypic behavior",
  "stethoscope",
  "stifle joint",
  "stillbirth",
  "stimulant",
  "stimulation",
  "stimulus",
  "stomatitis",
  "strain",
  "strike",
  "stunting",
  "stye",
  "subcutaneous",
  "subcutis",
  "subspectacle abscessation",
  "substrate",
  "subvalvular",
  "superficial",
  "superficial flexor tendon",
  "supplement",
  "supportive care",
  "suppression",
  "supravalvular",
  "surgery",
  "susceptibility",
  "suspension",
  "suspensory ligament",
  "suture",
  "syndrome",
  "synostosis",
  "synovial fluid",
  "synovial membrane",
  "systemic",
  "systole",
  "T cell",
  "T3, T4",
  "tablet",
  "tachycardia",
  "tarsal joint",
  "tarsal",
  "tartar",
  "temperate",
  "tendinitis",
  "tendon",
  "tension pneumothorax",
  "tentative diagnosis",
  "teratogen",
  "terrestrial",
  "testes",
  "tetanus",
  "tetany",
  "tetralogy of Fallot",
  "therapy",
  "thermography",
  "third eyelid",
  "third phalanx",
  "thoracentesis",
  "thoracic",
  "thorax",
  "thrombocyte",
  "thrombopoietin",
  "thrombosis",
  "thrombus",
  "thymus",
  "thyroid gland",
  "thyroid hyperplasia",
  "tick",
  "tick fever",
  "tissue",
  "tonometer",
  "tonsillitis",
  "tonsil",
  "topical",
  "torpor",
  "torticollis",
  "toxemia",
  "toxic",
  "toxicant",
  "toxicity",
  "toxicology",
  "toxicosis",
  "toxin",
  "toxoid",
  "toxoplasmosis",
  "trachea",
  "tracheal intubation",
  "tracheobronchitis",
  "tracheostomy",
  "tranquilizer",
  "transdermal",
  "transformation",
  "transfusion",
  "transmission",
  "trauma",
  "trematodes",
  "treponematosis",
  "trichinellosis",
  "tricuspid valve",
  "trocarization",
  "tube feeding",
  "tuberculosis",
  "tularemia",
  "tumor",
  "tympanic bullae",
  "Tyzzer's disease",
  "ubiquitous",
  "udder",
  "ulcer",
  "ulcerate",
  "ulcerative dermatitis",
  "ulna",
  "ultrasonography",
  "ultraviolet (UV) radiation",
  "umbilical",
  "umbilical cord",
  "unconscious",
  "unthriftiness",
  "upper airways",
  "urate",
  "uremia",
  "ureter",
  "urethra",
  "uric acid",
  "urinalysis",
  "urinate",
  "urine scalding",
  "urogenital tract",
  "urolith",
  "urolithiasis",
  "urticaria",
  "uterus",
  "uvea",
  "uveitis",
  "vaccination",
  "vaccine",
  "vagina",
  "valve",
  "valvular disease",
  "vascular",
  "vascular network",
  "vasculitis",
  "vasodilator",
  "vector",
  "vein",
  "venae cavae",
  "venereal disease",
  "venom",
  "venous dilator",
  "ventricle",
  "ventricular fibrillation",
  "vertebra",
  "vertebrate",
  "vesicle",
  "vesicular stomatitis",
  "vestibular system",
  "veterinarian",
  "veterinary dermatologist",
  "veterinary family practice",
  "vipers",
  "viral",
  "virus",
  "visceral",
  "visceral gout",
  "visceral leishmaniasis",
  "vitamin",
  "vitamin D",
  "volume overload",
  "vomit",
  "vulva",
  "warm-blooded",
  "wart",
  "water mold",
  "water salinity",
  "weaning",
  "wheal",
  "white blood cell",
  "withers",
  "womb",
  "worms",
  "x-ray",
  "x-ray therapy",
  "yeast",
  "yellow fat disease",
  "zinc toxicity",
  "zoonosis",
  "zoonotic risk",
  "zygomycosis",
];
