import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import NoteList from "./NoteList.js";
import { useTransition, animated } from "react-spring";
import Loading from "../../common/loading/Loading.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../../hooks/use-current-user.js";
import { Tooltip } from "@mui/material";
import Sort from "../../common/inbox/Sort.js";
import { useWatchNoteUpdates } from "../../../hooks";
import { useFlags } from "launchdarkly-react-client-sdk";

const GET_DASHBOARD_INBOX_NOTES = gql`
  query getDashboardInboxNotes(
    $limit: Int
    $offset: Int
    $sortAscending: Boolean
  ) {
    dashboardInboxNotes(
      limit: $limit
      offset: $offset
      sortAscending: $sortAscending
    ) {
      totalNotes
      notes {
        uuid
        createdAt
        quickNote
        title
        isDraft
        isNewRecording
        jobType
        jobTypeName
        isMarkedAsReview
        isMarkedAsProcessing
        isMarkedAsFailed
        isIncomplete
        isUnacceptable
        isHallucinating
        patient {
          name
          lastNameOfOwner
          sex
          species
        }
      }
    }
  }
`;

export function DashboardInbox({
  shouldRefetchForNewRecording,
  setShouldRefetchForNewRecording,
}) {
  const [loadingMore, setLoadingMore] = useState(false);
  const [sortAscending, setSortAscending] = useState(false);

  const navigate = useNavigate();

  const { data: currentUserData } = useCurrentUser();

  const { pollInterval } = useFlags();

  const queryOptions = { limit: 25, offset: 0 };

  const { data, refetch, loading, fetchMore } = useQuery(
    GET_DASHBOARD_INBOX_NOTES,
    {
      variables: queryOptions,
      fetchPolicy: "network-only",
      pollInterval: pollInterval ?? 10000,
    },
  );

  useEffect(() => {
    if (currentUserData) {
      refetch(queryOptions);
    }
  }, [currentUserData]);

  useWatchNoteUpdates({
    refetch: () => refetch(queryOptions),
    loading,
  });

  useEffect(() => {
    if (shouldRefetchForNewRecording) {
      refetch();
      setShouldRefetchForNewRecording(false);
    }
  }, [shouldRefetchForNewRecording]);

  const fadeAnim = useTransition(loading, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  function incrementOffset() {
    setLoadingMore(true);

    fetchMore({
      variables: {
        offset: data?.dashboardInboxNotes?.notes?.length || 0,
      },
    }).then(() => {
      setLoadingMore(false);
    });
  }

  return (
    <div className="h-[500px] border border-gray-100 bg-white dark:bg-gray-800 dark:border-gray-900/40 rounded-xl shadow-sm relative pt-16">
      <div className="flex flex-row items-end z-20 absolute w-full top-0 rounded-xl bg-indigo-50 dark:bg-gray-700/50 border-b border-indigo-100 dark:border-gray-800 p-4 shadow-sm">
        <div className="flex flex-row items-center flex-1 space-x-2">
          <h3 className="text-gray-700">Inbox</h3>
          <button
            className="rounded-full shadow-md bg-indigo-500  self-center py-1.5 px-3 text-white font-medium hover:bg-indigo-600 focus:outline-none transition-all"
            onClick={() => {
              navigate("/inbox");
            }}
          >
            See Full View <FontAwesomeIcon icon={faExternalLinkAlt} />
          </button>
        </div>

        <h4 className="px-2 h-8 flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 dark:bg-indigo-500 dark:text-indigo-100 text-base">
          {data?.dashboardInboxNotes?.totalNotes || 0}
        </h4>
      </div>

      {data?.dashboardInboxNotes?.totalNotes > 1 ? (
        <div className="absolute z-20 flex flex-row items-center justify-end space-x-2 rounded-b-lg pt-2 px-2 w-full">
          <button
            onClick={() => {
              refetch({
                sortAscending: !sortAscending,
              }).then(() => {
                setSortAscending(!sortAscending);
              });
            }}
          >
            <h5 className="text-sm text-gray-400 dark:text-gray-500">
              {!sortAscending ? "Show oldest " : "Show newest "}
              <FontAwesomeIcon
                icon={
                  !sortAscending
                    ? faChevronCircleDown
                    : faChevronCircleUp
                }
              />
            </h5>
          </button>
        </div>
      ) : null}

      <div className="h-full relative">
        {fadeAnim((styles, item) =>
          !item ? (
            <NoteList
              totalNotes={data?.dashboardInboxNotes?.totalNotes || 0}
              items={data?.dashboardInboxNotes?.notes || []}
              refetch={refetch}
              incrementOffset={incrementOffset}
              loadingMore={loadingMore}
            />
          ) : (
            <animated.div
              style={styles}
              className="flex flex-col h-full min-w-full place-content-center absolute z-10"
            >
              <Loading shouldShow={true} />
              <h4 className="mt-10 text-center text-base text-gray-500 dark:text-gray-400">
                Loading...
              </h4>
            </animated.div>
          ),
        )}
      </div>
    </div>
  );
}
