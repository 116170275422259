import { gql } from "@apollo/client";

export const CREATE_PATIENT_MUTATION = gql`
  mutation createPatient(
    $name: String
    $lastNameOfOwner: String
    $species: String
    $breed: String
    $weeks: Int
    $months: Int
    $years: Int
    $birthday: DateTime
    $sex: String
    $isIntact: Boolean
    $createdAt: DateTime
    $color: String
    $microchip: String
    $ownerContactInfo: String
    $emergencyContactInfo: String
  ) {
    createPatient(
      name: $name
      lastNameOfOwner: $lastNameOfOwner
      species: $species
      breed: $breed
      weeks: $weeks
      months: $months
      years: $years
      birthday: $birthday
      sex: $sex
      isIntact: $isIntact
      createdAt: $createdAt
      color: $color
      microchip: $microchip
      ownerContactInfo: $ownerContactInfo
      emergencyContactInfo: $emergencyContactInfo
    ) {
      patient {
        name
        uuid
      }
    }
  }
`;

export const EDIT_PATIENT_MUTATION = gql`
  mutation editPatient(
    $patientUuid: String
    $patientInput: PatientInput
  ) {
    editPatient(
      patientUuid: $patientUuid
      patientInput: $patientInput
    ) {
      patient {
        name
        uuid
      }
    }
  }
`;

export const DELETE_PATIENT_MUTATION = gql`
  mutation deletePatient($patientUuid: String) {
    deletePatient(patientUuid: $patientUuid) {
      ok
    }
  }
`;
