import React from "react";
import CanineDentalChart from "../../../../assets/imgs/charts/CanineDentalChart.js"; // Adjust as necessary
import FelineDentalChart from "../../../../assets/imgs/charts/FelineDentalChart.js"; // Adjust as necessary
import CanineChartWithLabels from "../../../../assets/imgs/charts/Canine-Labels.js";
import FelineChartWithLabels from "../../../../assets/imgs/charts/Feline-Labels.js";
import DentalChartLegend from "./DentalChartLegend.js";
import { Font, View } from "@react-pdf/renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const parseDentalRecord = (record = "") => {
  // Initialize conditions object and default type
  const conditions = {};
  let type = "feline";

  // Check for null, undefined, or empty strings explicitly
  if (
    record !== null &&
    record !== undefined &&
    record.trim() !== ""
  ) {
    record
      .trim()
      .split("\n")
      .forEach((line) => {
        const parts = line.split(":").map((part) => part.trim());
        if (parts.length === 2) {
          // Ensure the line splits into exactly two parts
          let [id, condition] = parts;
          id = id.replace(/-\s*/, "");
          if (condition) {
            // Ensure condition is not empty
            conditions[id] = condition;

            if (
              [
                "411",
                "410",
                "110",
                "210",
                "310",
                "311",
                "105",
                "205",
                "305",
                "405",
              ].includes(id)
            ) {
              type = "canine";
            }
          }
        }
      });
  } else {
    console.error("Invalid dental record input:", record);
  }

  return { conditions, type };
};

export default function DentalChart({ record, isForPDF = false }) {
  const { conditions = {}, type = "feline" } =
    parseDentalRecord(record);
  if (isForPDF) {
    return (
      <View
        style={{
          alignItems: "center",
          height: 500,
        }}
      >
        <DentalChartLegend isForPDF={true} />
        {type === "canine" ? (
          <CanineChartWithLabels toothConditions={conditions} />
        ) : (
          <FelineChartWithLabels toothConditions={conditions} />
        )}
      </View>
    );
  } else {
    return (
      <>
        <div className="p-3 rounded-xl bg-yellow-500/30 border border-yellow-300 dark:border-yellow-500/30 mb-4">
          <h5 className="text-yellow-600 dark:text-yellow-400 text-sm">
            <FontAwesomeIcon icon={faExclamationCircle} /> Please note
            that the dental chart will only reflect new note edits
            after refreshing the page.
          </h5>
        </div>
        <div className="flex flex-col items-center space-y-8 bg-white p-2 rounded-xl">
          <DentalChartLegend />
          {type === "canine" ? (
            <CanineDentalChart toothConditions={conditions} />
          ) : (
            <FelineDentalChart toothConditions={conditions} />
          )}
        </div>
      </>
    );
  }
}
