import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import EDIT_USER from "../../graphql/mutations/EditUser.js";
import { alert } from "../common/Alert.js";

export default function NoteExpiryTime({ data, refetch }) {
  const [noteExpiryTimeSelection, setNoteExpiryTimeSelection] =
    useState(data?.user?.emailExpiryDays || 1);

  const [editUser] = useMutation(EDIT_USER);

  useEffect(() => {
    if (
      data &&
      noteExpiryTimeSelection !== data?.user?.emailExpiryDays
    ) {
      editUser({
        variables: { emailExpiryDays: noteExpiryTimeSelection },
      })
        .then(() => {
          alert("success", "Account updated successfully.");
          refetch();
        })
        .catch((err) => alert("error", err.message));
    }
  }, [noteExpiryTimeSelection]);

  useEffect(() => {
    if (data?.user?.emailExpiryDays) {
      setNoteExpiryTimeSelection(data?.user?.emailExpiryDays || 1);
    }
  }, [data]);

  return (
    <div className={"mx-2 self-center"}>
      <select
        className="font-normal text-xs dark:text-gray-300 md:text-sm flex-1 mt-1 rounded-lg dark:bg-gray-700 bg-gray-100 p-2"
        name="filterOption"
        placeholder="Sort by..."
        value={noteExpiryTimeSelection}
        onChange={(e) => {
          setNoteExpiryTimeSelection(e.target.value);
        }}
      >
        <option value={1}>1 day</option>
        <option value={3}>3 days</option>
        <option value={7}>1 week</option>
        <option value={14}>2 weeks</option>
        <option value={31}>1 month</option>
      </select>
    </div>
  );
}
