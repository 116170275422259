import { gql } from "@apollo/client";

export default gql`
  mutation addFeedbackToLog(
    $userUuid: String
    $noteUuid: String
    $noteType: String
    $rating: String
    $feedback: String
  ) {
    addFeedbackToLog(
      userUuid: $userUuid
      noteUuid: $noteUuid
      noteType: $noteType
      rating: $rating
      feedback: $feedback
    ) {
      ok
    }
  }
`;
