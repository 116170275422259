import moment from "moment";

export function getDateFromInput({ dd, mm, yyyy }) {
  return moment(`${yyyy}-${mm}-${dd}`, "YYYY-MM-DD", true);
}

export function isDateInputValid({ dd, mm, yyyy }) {
  const date = getDateFromInput({ dd, mm, yyyy });
  const hasDatePassed = moment() > date;
  return date.isValid() && hasDatePassed;
}
