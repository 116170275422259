import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { alert } from "../common/Alert.js";
import MARK_NOTES_EXPORTED from "../../graphql/mutations/MarkNotesExported.js";
import SEND_EXPORT_NOTE_EMAIL from "../../graphql/mutations/SendExportNoteEmail.js";
import ExportModal from "./ExportModal.js";
import { useLocation } from "react-router-dom";

function ExistingNoteEmailExportButton({
  note,
  refetch,
  shouldOnlyShowEmailForm = null,
}) {
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const location = useLocation();

  const methods = useForm();
  const { control, handleSubmit } = methods;

  const [sendExportNoteEmail] = useMutation(SEND_EXPORT_NOTE_EMAIL);
  const [modifyNotes] = useMutation(MARK_NOTES_EXPORTED);

  function hideModal() {
    setShouldShowModal(false);
  }

  const onSend = async (data) => {
    sendExportNoteEmail({
      variables: {
        toEmail: data?.toEmail,
        grantedNoteUuid: note?.uuid,
        isForAssistant: false,
        specialInstructions: data?.specialInstructions,
        areAttachmentsIncluded: data?.includeAttachments,
        shouldEmbedEmailExports: data?.exportType === "embeddedText",
      },
    })
      .then(async () => {
        setShouldShowModal(false);
        modifyNotes({
          variables: {
            noteUuids: [note.uuid],
            action: "mark_exported",
          },
        }).then(() => {
          alert(
            "info",
            `Email sent! ${
              note?.hasBeenExported
                ? ""
                : "Your note has now been marked as exported."
            }`,
          );
        });
        if (location.pathname == "/inbox") {
          refetch({});
        } else {
          if (refetch) {
            refetch({ isApproved: true });
          }
        }
      })
      .catch(() => {
        alert("error", "An error occurred. Please try again later.");
      });
  };

  if (note) {
    return (
      <>
        <ExportModal
          shouldShowModal={shouldShowModal}
          hideModal={hideModal}
          handleSubmit={handleSubmit}
          onSend={onSend}
          control={control}
          methods={methods}
          shouldOnlyShowEmailForm={shouldOnlyShowEmailForm}
        />
        <button
          title="Export note via email"
          id="exportNoteIcon"
          disabled={
            note?.isNewRecording ||
            note?.isMarkedAsProcessing ||
            note?.isMarkedAsFailed
          }
          className={`flex-grow-0 self-center border rounded-full w-8 h-8 text-white transition-all outline-none font-medium focus:outline-none text-sm shadow-md
            ${
              !note?.isNewRecording &&
              !note?.isMarkedAsProcessing &&
              !note?.isMarkedAsFailed
                ? " bg-indigo-500 border-indigo-500 hover:bg-indigo-700 "
                : " rounded-full w-8 h-8 bg-gray-300 border-gray-300 dark:bg-gray-600 dark:border-gray-600  cursor-not-allowed"
            }`}
          onClick={() => {
            if (!note?.isDraft && !note?.isNewRecording) {
              setShouldShowModal(true);
            }
          }}
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </button>
      </>
    );
  } else return null;
}

export default ExistingNoteEmailExportButton;
