import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "@mui/material";

export default function NoteFormTypeToggle({ onChange, value }) {
  return (
    <Tooltip
      arrow
      title="Click to toggle between SOAP format and simple text box."
    >
      <div id="formTypeToggle">
        <button
          onClick={() => {
            onChange(!value);
          }}
          className={
            "px-2 bg-white dark:bg-gray-800 hover:bg-indigo-50 dark:hover:bg-gray-700 text-indigo-500 font-semibold justify-center w-10 lg:w-36 h-[40px] flex flex-row items-center transition-all focus:outline-none  disabled:bg-gray-300 disabled:hover:bg-gray-300 disabled:cursor-not-allowed rounded-r-lg"
          }
        >
          <FontAwesomeIcon icon={faFile} className="lg:mr-2" />
          <span className="hidden lg:block text-xs">
            {value ? "Use SOAP Form" : "Use Simple Form"}
          </span>
        </button>
      </div>
    </Tooltip>
  );
}
