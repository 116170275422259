import { gql } from "@apollo/client";
import { NOTE_FIELDS } from "../fragments/note.js";

export const GET_TEMPLATE_BY_UUID = gql`
  ${NOTE_FIELDS}
  query template($uuid: String) {
    template(uuid: $uuid) {
      name
      description
      colorScheme
      uuid
      note {
        ...NoteFields
        keyHistory
        keyMedications
        vaccinationStatus
      }
    }
  }
`;
