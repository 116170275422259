import React, { useEffect } from "react";
import { animated } from "react-spring";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export function UploadFinishedButton({
  trackColor,
  setIsFinishedUploading,
  transitions,
}) {
  useEffect(() => {
    setTimeout(() => {
      setIsFinishedUploading(false);
    }, 2000);
  }, [2000]);

  return transitions(
    (styles, item) =>
      item && (
        <CircularProgressbarWithChildren
          value={100}
          styles={{
            path: {
              stroke: "#34D399",
              strokeLinecap: "round",
            },
            trail: {
              stroke: trackColor,
              strokeLinecap: "round",
            },
          }}
        >
          <animated.div
            style={styles}
            className="flex text-center my-6 rounded-full w-3/4 h-3/4 bg-gradient-to-br from-green-400 to-green-600 place-items-center place-content-center shadow-lg"
          >
            <FontAwesomeIcon icon={faCheck} size="4x" color="white" />
          </animated.div>
        </CircularProgressbarWithChildren>
      ),
  );
}
