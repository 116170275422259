import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import {
  SuperSoapProvider,
  NoteMultiselectProvider,
  UploadProgressProvider,
  useAuth,
  useWindowDimensions,
  useAuthExpiry,
  OnboardingProvider,
  useAccountAuthorizationRedirect,
} from "../../hooks";
import Header from "./Header";
import Footer from "./Footer";
import Login from "../auth/Login.js";
import Register from "../auth/Register.js";
import RegisterConfirm from "../auth/FirstLogin/RegisterConfirm.js";
import ForgotPassword from "../auth/ForgotPassword.js";
import ForgotPasswordReset from "../auth/ForgotPasswordReset";
import FourOhFour from "../home/FourohFour.js";
import UserDashboard from "../dashboard/UserDashboard.js";
import UserAccount from "../account/UserAccount.js";
import Patients from "../patients/Patients.js";
import PatientAnalytics from "../analytics/PatientAnalytics.js";
import Notebook from "../notebook/Notebook.js";
import KeyPatientInfo from "../notebook/KeyPatientInfo.js";
import Attachments from "../attachments/Attachments.js";
import CreateNoteSelect from "../notes/CreateNoteSelect";
import CreateNote from "../notes/CreateNote";
import EditNote from "../notes/EditNote";
import SuperSoap from "../notes/SuperSoap.js";
import Quicknotes from "../quicknotes/Quicknotes.js";
import ExportNotes from "../export/ExportNotes";
import SharedExportNote from "../export/SharedExportNote.js";
import UpgradePlan from "../billing/UpgradePlan.js";
import Checkout from "../billing/Checkout.js";
import Templates from "../templates/Templates";
import EditTemplate from "../templates/EditTemplate.js";
import NewTemplate from "../templates/NewTemplate.js";
import {
  AuditTrailNote,
  AuditTrailPatient,
  AuditTrailUser,
} from "../audit";
import { MessageModal } from "../common/modals/PopupModals";
import { PermanentlyHideableBanner } from "../common/Banner";
import Inbox from "../inbox/Inbox";
import CopyPatientSelect from "../notebook/CopyPatientSelect";
import MovePatientSelect from "../notebook/MovePatientSelect";
import FirstLoginTour from "../auth/FirstLogin/FirstLoginTour";
import { useLDClient } from "launchdarkly-react-client-sdk";
import AdminPage from "../admin";
import TeamManagement from "../admin/team";
import { useFlags } from "launchdarkly-react-client-sdk";

function PromptUserRefreshAuthExpiry({ shouldShowModal }) {
  return (
    <div>
      <MessageModal shouldShow={shouldShowModal} title="">
        <div className="flex flex-col self-center mb-4">
          <h4 className="text-lg text-center mb-2">
            Your session has expired.
          </h4>
          <h5 className="text-sm text-center">
            You have been logged out for security purposes. Please
            refresh the page to be taken to the login page!
          </h5>
        </div>
      </MessageModal>
    </div>
  );
}

export function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  const { shouldShowModal } = useAuthExpiry();
  const ldClient = useLDClient();

  useEffect(() => {
    const userUuid = localStorage.getItem("root_logged_in_user_uuid");

    if (userUuid) {
      ldClient.identify({
        key: `user-uuid-${userUuid}`,
      });
    }
  }, [ldClient]);

  function renderPrivateRouteOrRedirect() {
    if (auth.isUserAuthenticated) {
      return children;
    } else {
      return <Navigate to={"/auth/login"} />;
    }
  }

  return (
    <>
      {renderPrivateRouteOrRedirect()}
      <PromptUserRefreshAuthExpiry
        shouldShowModal={shouldShowModal}
      />
    </>
  );
}

function AdminProtectedRoute({ children }) {
  const { adminPage } = useFlags();
  const { orgUuid } = useAuth();

  return orgUuid && adminPage ? children : <Navigate to={"/"} />;
}

function App(props) {
  const mainClassNameOriginal =
    "flex-grow w-11/12 md:w-[85%] min-h-[800px] self-center";
  const { width, height } = useWindowDimensions();
  const location = useLocation();

  return (
    <div
      id="root-node"
      className="flex flex-col min-h-screen font-body dark:bg-gray-800"
    >
      {location.pathname === "/tutorial" ? null : <Header />}
      <main className={mainClassNameOriginal}>
        {width < 500 && (
          <PermanentlyHideableBanner>
            We&#39;ve detected you&#39;re using Scribenote on a mobile
            device. <br /> For a more optimized mobile experience, try
            our mobile app on{" "}
            <a
              href="https://apps.apple.com/us/app/scribenote/id1573878076"
              target="_blank"
              rel="noreferrer"
            >
              <strong>iOS</strong>
            </a>{" "}
            or{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.scribenote.scribenote"
              target="_blank"
              rel="noreferrer"
            >
              <strong>Android</strong>
            </a>
            . Simply download the app for free and sign in with your
            existing Scribenote account!
          </PermanentlyHideableBanner>
        )}
        <Routes>
          {/* PUBLIC & AUTH ROUTES */}
          <Route path="/" element={<Login />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/register" element={<Register />} />
          <Route
            path="/forgot_password"
            element={<ForgotPassword />}
          />
          <Route
            path="/reset_password/:resetToken"
            element={<ForgotPasswordReset />}
          />
          <Route
            path="/registration_confirmation"
            element={
              <PrivateRoute>
                <RegisterConfirm />
              </PrivateRoute>
            }
          />

          {/* END AUTH ROUTES */}
          <Route
            path="/account"
            element={
              <PrivateRoute>
                <UploadProgressProvider>
                  <UserAccount />
                </UploadProgressProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/subscriptions"
            element={
              <PrivateRoute>
                <UpgradePlan />
              </PrivateRoute>
            }
          />
          <Route
            path="/checkout/:productName/:priceId"
            element={
              <PrivateRoute>
                <Checkout />
              </PrivateRoute>
            }
          />

          {/* END BILLING ROUTES */}
          {/* CLINICAL ROUTES */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <OnboardingProvider>
                  <UserDashboard />
                </OnboardingProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/tutorial"
            element={
              <PrivateRoute>
                <OnboardingProvider>
                  <FirstLoginTour />
                </OnboardingProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/inbox"
            element={
              <PrivateRoute>
                <NoteMultiselectProvider>
                  <Inbox />
                </NoteMultiselectProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/templates"
            element={
              <PrivateRoute>
                <Templates />
              </PrivateRoute>
            }
          />
          <Route
            path="/templates/new"
            element={
              <PrivateRoute>
                <SuperSoapProvider>
                  <NewTemplate />
                </SuperSoapProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/templates/edit/:uuid"
            element={
              <PrivateRoute>
                <SuperSoapProvider>
                  <EditTemplate />
                </SuperSoapProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/notebook"
            element={
              <PrivateRoute>
                <Patients />
              </PrivateRoute>
            }
          />
          <Route
            path="/notebook/:patientUUID"
            element={
              <PrivateRoute>
                <NoteMultiselectProvider>
                  <Notebook />
                </NoteMultiselectProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/notebook/:patientUUID/keyinfo"
            element={
              <PrivateRoute>
                <KeyPatientInfo />
              </PrivateRoute>
            }
          />
          <Route
            path="/notebook/:patientUUID/attachments"
            element={
              <PrivateRoute>
                <UploadProgressProvider>
                  <Attachments />
                </UploadProgressProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/notebook/analytics"
            element={
              <PrivateRoute>
                <PatientAnalytics />
              </PrivateRoute>
            }
          />
          <Route
            path="/quicknotes"
            element={
              <PrivateRoute>
                <NoteMultiselectProvider>
                  <Quicknotes />
                </NoteMultiselectProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/notebook_select/move/:noteUUIDs"
            element={
              <PrivateRoute>
                <MovePatientSelect />
              </PrivateRoute>
            }
          />
          <Route
            path="/notebook_select/copy/:noteUUIDs"
            element={
              <PrivateRoute>
                <CopyPatientSelect />
              </PrivateRoute>
            }
          />
          <Route
            path="/assistant_export_note/:noteAccessToken"
            element={<SharedExportNote />}
          />
          <Route
            path="/shared_export_note/:noteAccessToken"
            element={<SharedExportNote />}
          />
          <Route
            path="/audit_trail/note/:noteUUID"
            element={
              <PrivateRoute>
                <AuditTrailNote />
              </PrivateRoute>
            }
          />
          <Route
            path="/audit_trail/patient/:patientUUID"
            element={
              <PrivateRoute>
                <AuditTrailPatient />
              </PrivateRoute>
            }
          />
          <Route
            path="/audit_trail/user"
            element={
              <PrivateRoute>
                <AuditTrailUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit_note/:noteUUID"
            element={
              <PrivateRoute>
                <SuperSoapProvider>
                  <EditNote />
                </SuperSoapProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="/new_note/select"
            element={
              <PrivateRoute>
                <CreateNoteSelect />
              </PrivateRoute>
            }
          />
          <Route
            path="/new_note/patient/:patientUUID"
            element={
              <PrivateRoute>
                <CreateNote />
              </PrivateRoute>
            }
          />
          <Route
            path="/new_note/quicknote"
            element={
              <PrivateRoute>
                <CreateNote />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/super_soap"
            element={
              <PrivateRoute>
                <SuperSoapProvider>
                  <SuperSoap />
                </SuperSoapProvider>
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/admin"
            element={
              <AdminProtectedRoute>
                <PrivateRoute>
                  <AdminPage />
                </PrivateRoute>
              </AdminProtectedRoute>
            }
          />
          <Route
            path="/admin/:teamUuid"
            element={
              <AdminProtectedRoute>
                <PrivateRoute>
                  <TeamManagement />
                </PrivateRoute>
              </AdminProtectedRoute>
            }
          />
          {/* All routes must be above this 404 or they will redirect. */}
          <Route path="*" element={<FourOhFour />} />
        </Routes>

        {props.children}
      </main>
      {location.pathname === "/tutorial" ? null : <Footer />}
    </div>
  );
}

export default App;
