import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { EditableNoteSuperSOAP } from "./edit/EditableNoteSuperSOAP.js";
import { NOTE_FIELDS } from "../../graphql/fragments/note.js";
import PageTitle from "../layout/PageTitle.js";
import NapTime from "../../assets/imgs/otto-scribbles-sleep.svg";
import JoyrideTour from "../common/modals/JoyrideTour";
import { superSOAPSteps } from "../../assets/data/JoyrideTourSteps";
import withTemplatesMessageModal from "../../hocs/withTemplatesMessageModal.js";
import { useSuperSoap } from "../../hooks";
import FullScreenLoading from "../common/loading/FullScreenLoading.js";
import { Banner } from "../common/Banner.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleSwitch from "../common/buttons/ToggleSwitch.js";
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faSortAmountDown,
  faSortAmountUp,
  faSortNumericDown,
  faSortNumericUp,
} from "@fortawesome/free-solid-svg-icons";
import { useWindowVirtualizer } from "@tanstack/react-virtual";

export const GET_NOTES = gql`
  ${NOTE_FIELDS}
  query getUsersNotes(
    $isApproved: Boolean
    $isTagged: Boolean
    $isQuickNote: Boolean
  ) {
    user {
      notes(
        isApproved: $isApproved
        isTagged: $isTagged
        isQuickNote: $isQuickNote
      ) {
        patient {
          name
          lastNameOfOwner
          sex
          species
          uuid
        }
        ...NoteFields
        keyHistory
        keyMedications
        vaccinationStatus
        attachments {
          fileName
          fileLink
          contentType
          createdAt
        }
      }
    }
  }
`;

function EmptyState() {
  return (
    <div className="mx-auto">
      <div className="flex flex-col items-center justify-center space-y-5">
        <div className="flex self-center rounded-full w-30 h-30 bg-gray-200"></div>
        <img
          className="flex self-center"
          width="300px"
          src={NapTime}
        />
        <h4 className="text-center text-base text-gray-500 dark:text-gray-400">
          We&apos;re taking a paws...
        </h4>
        <h5 className="w-1/2 text-center text-sm text-gray-400 dark:text-gray-500">
          Notes will show up here automatically. Come back here when
          you have a few stacked up so you can knock them out in one
          go! For now, go enjoy the rest of your day!
        </h5>
      </div>
    </div>
  );
}

function SoapsRemainingCounter({ notes }) {
  const { uuidsOfCompletedNotes } = useSuperSoap();

  const numCompletedNotes =
    Object.keys(uuidsOfCompletedNotes)?.length || 0;

  const soapsRemaining = notes?.length - numCompletedNotes;

  if (soapsRemaining > 0) {
    return (
      <Banner bgStyle="bg-green-400">
        <h5 className="my-1 text-center dark:text-gray-800">
          <strong>
            Looks like you have{" "}
            <span className="py-0.5 md:py-1 px-1 md:px-2.5 rounded-full  bg-indigo-500 text-indigo-100 text-sm md:text-xl">
              {soapsRemaining}
            </span>{" "}
            more {soapsRemaining === 1 ? "SOAP" : "SOAPs"} to get
            through.
          </strong>{" "}
          Write them all out here, or from the comfort of your mobile
          device!
        </h5>
      </Banner>
    );
  } else {
    return null;
  }
}

function SuperSoap() {
  const [runJoyrideTour, setRunJoyrideTour] = useState(false);
  const [sortAscending, setSortAscending] = useState(true);
  const [sortedItems, setSortedItems] = useState([]);

  const { data, loading } = useQuery(GET_NOTES, {
    variables: { isApproved: false },
  });

  if (!loading && !data) {
    return null;
  }

  const listItems = data?.user?.notes;

  useEffect(() => {
    const listItems = data?.user?.notes;

    // Ideally this will be done on the backend next iteration
    // However, this change is several weeks late and this is the building block we have
    // so do sort on the client for now.
    if (listItems && sortedItems?.length == 0) {
      setSortedItems(listItems);
    }
  }, [data]);

  if (data?.user?.notes?.length == 0 && !loading) {
    return <EmptyState />;
  }

  return (
    <div>
      <FullScreenLoading loadingTrigger={loading} />
      <PageTitle
        title="Super SOAP"
        onClick={() => {
          setRunJoyrideTour(true);
        }}
        showHelpButton
      />
      <JoyrideTour
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
        steps={superSOAPSteps}
      />
      {listItems?.length >= 0 ? (
        <SoapsRemainingCounter notes={listItems} />
      ) : null}
      <div className="flex flex-row items-center justify-end space-x-2 bg-gray-100/50 dark:bg-gray-700/50 rounded-lg p-3 w-full mt-1">
        <button
          onClick={() => {
            setSortAscending(!sortAscending);
            setSortedItems(
              listItems.sort((a, b) => {
                if (sortAscending)
                  return (
                    new Date(a.createdAt) - new Date(b.createdAt)
                  );
                else
                  return (
                    new Date(b.createdAt) - new Date(a.createdAt)
                  );
              }),
            );
          }}
        >
          <h5 className="text-sm">
            {sortAscending ? "Show oldest " : "Show newest "}
            <FontAwesomeIcon
              icon={
                sortAscending
                  ? faChevronCircleDown
                  : faChevronCircleUp
              }
            />
          </h5>
        </button>
      </div>

      <div className="mb-10">
        {sortedItems?.length ? (
          <RowVirtualizerDynamicWindow notes={sortedItems} />
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
}

export default withTemplatesMessageModal(SuperSoap);

const RowVirtualizerDynamicWindow = ({ notes = [] }) => {
  const parentRef = React.useRef();

  const parentOffsetRef = React.useRef(0);

  React.useLayoutEffect(() => {
    parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
  }, []);

  const virtualizer = useWindowVirtualizer({
    count: notes?.length || 0,
    estimateSize: () => 1500,
    overscan: 15,
    scrollMargin: parentOffsetRef.current,
    enableSmoothScroll: false,
    paddingEnd: 100,
  });

  const items = virtualizer.getVirtualItems();

  return (
    <div ref={parentRef} className="List">
      <div
        style={{
          height: virtualizer.getTotalSize(),
          width: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(0px)`,
          }}
        >
          {items.map((virtualRow) => (
            <div
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={virtualizer.measureElement}
            >
              <EditableNoteSuperSOAP
                key={notes[virtualRow.index].uuid}
                note={notes[virtualRow.index]}
                isSuperSOAP={true}
                virtualizer={virtualizer}
                index={virtualRow.index}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
