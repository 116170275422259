import React from "react";
import { useQuery, gql } from "@apollo/client";
import { AttachmentsPreview } from "../../attachments/AttachmentsPreview";

const GET_ATTACHMENTS = gql`
  query patient($uuid: String) {
    patient(uuid: $uuid) {
      attachments {
        uuid
        fileName
        fileLink
        createdAt
      }
    }
  }
`;

export default function RecentAttachments({
  isForPatientProfile = true,
  patientUUID,
}) {
  const { data } = useQuery(GET_ATTACHMENTS, {
    variables: { uuid: patientUUID },
    fetchPolicy: "cache-first",
  });

  if (!data) {
    return null;
  }

  function handleAttachmentClick(attachment) {
    window.open(attachment.fileLink, "_blank");
  }

  return (
    <>
      <AttachmentsPreview
        patientUUID={patientUUID}
        isForPatientProfile={isForPatientProfile}
        isCompactView={true}
        attachments={data?.patient?.attachments}
        handleAttachmentClick={handleAttachmentClick}
      />
    </>
  );
}
