import React from "react";

export const Button = ({ children, type, onClick, dataCy }) => {
  return (
    <button
      onClick={onClick}
      type={type ? type : "button"}
      data-cy={dataCy ? dataCy : "Button"}
      className="group relative flex mx-auto justify-center items-center py-2 px-5 border border-transparent text-base font-medium rounded-full text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all"
    >
      {children}
    </button>
  );
};
