import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import PageTitle from "../layout/PageTitle.js";
import { PatientSelectForAction } from "../common/multiselect/PatientSelectionForAction.js";
import { gql, useMutation } from "@apollo/client";
import { alert } from "../common/Alert.js";
import mixpanel from "mixpanel-browser";

const ASSOCIATE_QUICKNOTES_WITH_PATIENT = gql`
  mutation associateQuicknotesWithPatient(
    $patientUuid: String
    $noteUuids: [String]
  ) {
    associateQuicknotesWithPatient(
      patientUuid: $patientUuid
      noteUuids: $noteUuids
    ) {
      ok
    }
  }
`;

export default function MovePatientSelect() {
  const [selectedPatientUuid, setSelectedPatientUuid] = useState("");
  const [selectedPatientName, setSelectedPatientName] = useState("");

  const { noteUUIDs } = useParams();
  const navigate = useNavigate();

  const [associateQuicknotesWithPatient, { loading }] = useMutation(
    ASSOCIATE_QUICKNOTES_WITH_PATIENT,
  );

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  const shouldPluralizeText = noteUUIDs.split(",").length > 1;
  const numNotes = noteUUIDs.split(",").length;

  function renderModalText() {
    return (
      <>
        Are you sure you&apos;d like to move{" "}
        <strong className="text-indigo-400">
          {numNotes} {shouldPluralizeText ? "notes" : "note"}{" "}
        </strong>
        to{" "}
        <strong className="text-indigo-400">
          {selectedPatientName}
        </strong>
        's notebook?
      </>
    );
  }

  return (
    <div>
      <PageTitle title="Move notes to a patient" />
      <PatientSelectForAction
        selectedPatientName={selectedPatientName}
        selectedPatientUuid={selectedPatientUuid}
        setSelectedPatientName={setSelectedPatientName}
        setSelectedPatientUuid={setSelectedPatientUuid}
        modalTitle={"Move to this patient?"}
        modalText={renderModalText()}
        loading={loading}
        confirmAction={() => {
          mixpanel.track("Added note to patient");
          associateQuicknotesWithPatient({
            variables: {
              noteUuids: noteUUIDs.split(","),
              patientUuid: selectedPatientUuid,
            },
          })
            .then(() => {
              navigate(`/notebook/${selectedPatientUuid}`);
              alert(
                "success",
                `Moved ${numNotes} note${
                  shouldPluralizeText ? "s" : ""
                } successfully.`,
              );
            })
            .catch((response) => {
              const errors = response.graphQLErrors.map((error) =>
                alert("error", error.message),
              );
            });
        }}
      />
    </div>
  );
}
