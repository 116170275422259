import { gql } from "@apollo/client";

export default gql`
  mutation sendClientSummaryEmail(
    $toEmail: String
    $clientSummary: String
  ) {
    sendClientSummaryEmail(
      toEmail: $toEmail
      clientSummary: $clientSummary
    ) {
      ok
    }
  }
`;
