import React from "react";
import { useParams } from "react-router";
import { gql, useQuery } from "@apollo/client";

import PageTitle from "../layout/PageTitle";
import { AuditTrailList } from "./AuditTrailList";
import FullScreenLoading from "../common/loading/FullScreenLoading";
import FourohFour from "../home/FourohFour";

const GET_USER_AUDIT_TRAIL = gql`
  query user {
    user {
      auditTrail {
        uuid
        fileName
        createdAt
      }
    }
  }
`;

export function AuditTrailUser() {
  const { data, loading } = useQuery(GET_USER_AUDIT_TRAIL);

  if (!data && loading) {
    <FullScreenLoading loadingTrigger={loading} />;
  }

  if (!data) {
    return <FourohFour />;
  }
  return (
    <div>
      <PageTitle className="mb-6" title="All past revisions" />
      <AuditTrailList auditTrail={data?.user?.auditTrail} />
    </div>
  );
}
