export function CardContent() {
  return (
    <>
      <h5 className="mt-3 text-gray-500 dark:text-indigo-500 text-sm">
        With a paid plan, you&apos;ll get access to all of
        Scribenote&apos;s great features, unlimited.
      </h5>
      <ul className="list-disc p-6 text-gray-500 dark:text-indigo-300 text-sm font-medium space-y-2">
        <li>Unlimited notes</li>
        <li>Unlimited Appointments</li>
        <li>Unlimited support (email, chat, phone, video).</li>
      </ul>
    </>
  );
}
