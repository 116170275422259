import React, { useState } from "react";
import { SoapSection } from "../../common/clinical/SoapSection.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { CustomNoteSection } from "../../common/clinical/CustomNoteSection.js";

export default function NotesExpandableSection({ data }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div className="relative">
      <div
        style={{ height: isCollapsed ? "110px" : "auto" }}
        className={isCollapsed ? "py-2 overflow-hidden" : "py-2"}
      >
        {data?.shouldUseCustomForm ? (
          <CustomNoteSection note={data} />
        ) : (
          <SoapSection note={data} />
        )}
      </div>
      <div
        onClick={() => setIsCollapsed(!isCollapsed)}
        title="Expand/collapse note"
        className={
          isCollapsed
            ? "w-full rounded-lg absolute z-10 top-16 pt-5 bg-gradient-to-t dark:from-gray-700 via-gray-50 from-gray-50 dark:via-gray-700"
            : "w-full rounded-lg pt-5"
        }
      >
        <div className="h-8 w-full dark:hover:bg-gray-600/50 rounded-full hover:bg-indigo-100 mx-auto flex items-center justify-center text-gray-700 dark:text-white transition-colors cursor-pointer">
          <FontAwesomeIcon
            icon={isCollapsed ? faChevronDown : faChevronUp}
          />
        </div>
      </div>
    </div>
  );
}
