import React, { useState, useEffect } from "react";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import NoteMultiSelector from "../notebook/note/NoteMultiSelector.js";
import Row from "./Row";
import LoadMore from "../common/inbox/LoadMore.js";

export default function Rows({
  items,
  isExportWindow,
  isAlreadyExported,
  refetch,
  itemCount,
  fetchMore,
  activeTab,
}) {
  const [loadingMore, setLoadingMore] = useState(false);

  if (!items) {
    return null;
  }

  function incrementOffset() {
    setLoadingMore(true);

    fetchMore().then(() => {
      setLoadingMore(false);
    });
  }

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
      }}
    >
      {items.map((row, index) => (
        <div key={row?.uuid} className="my-1">
          <NoteMultiSelector
            checkBoxItemUuid={row?.uuid}
            icon={faBolt}
            tooltipText="Select this note for ⚡️ Quick Actions"
            isPatient={!!row?.patient}
          >
            <Row
              row={row}
              isExportWindow={isExportWindow}
              isAlreadyExported={isAlreadyExported}
              refetch={refetch}
              activeTab={activeTab}
            />
          </NoteMultiSelector>
          {/* If this is the last item in the list and there are more notes to load */}
          {index === items.length - 1 && itemCount > items.length ? (
            <LoadMore
              incrementOffset={incrementOffset}
              loadingMore={loadingMore}
            />
          ) : null}
        </div>
      ))}
    </div>
  );
}
