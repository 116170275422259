import React from "react";
import NoteCreatedAt from "../notebook/note/NoteCreatedAt";
import Note from "../notebook/note/Note";
import NoteMultiSelector from "../notebook/note/NoteMultiSelector";
import { faBolt } from "@fortawesome/free-solid-svg-icons";

const QuicknoteItem = ({ quicknoteData, saveEdit }) => {
  return (
    <div className="flex flex-row">
      <NoteMultiSelector
        checkBoxItemUuid={quicknoteData?.uuid}
        isPatient={quicknoteData?.patient ? true : false}
        icon={faBolt}
        tooltipText="Select this note for ⚡️ Quick Actions"
      >
        <Note
          title={
            <NoteCreatedAt createdAt={quicknoteData?.createdAt} />
          }
          note={quicknoteData}
          user={quicknoteData.user}
          patientData={null}
          saveEdit={saveEdit}
        />
      </NoteMultiSelector>
    </div>
  );
};

export default QuicknoteItem;
