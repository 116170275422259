export const Breeds = {
  dog: [
    "Affenpinscher",
    "Afghan Hound",
    "Airedale Terrier",
    "Akita",
    "Alaskan Klee Kai",
    "Alaskan Malamute",
    "American Bulldog",
    "American English Coonhound",
    "American Eskimo Dog",
    "American Foxhound",
    "American Hairless Terrier",
    "American Leopard Hound",
    "American Staffordshire Terrier",
    "American Water Spaniel",
    "Anatolian Shepherd Dog",
    "Appenzeller Sennenhund",
    "Australian Cattle Dog",
    "Australian Kelpie",
    "Australian Shepherd",
    "Australian Stumpy Tail Cattle Dog",
    "Australian Terrier",
    "Azawakh",
    "Barbado da Terceira",
    "Barbet",
    "Basenji",
    "Basset Fauve de Bretagne",
    "Basset Hound",
    "Bavarian Mountain Scent Hound",
    "Beagle",
    "Bearded Collie",
    "Beauceron",
    "Bedlington Terrier",
    "Belgian Laekenois",
    "Belgian Malinois",
    "Belgian Sheepdog",
    "Belgian Tervuren",
    "Bergamasco Sheepdog",
    "Berger Picard",
    "Bernese Mountain Dog",
    "Bichon Frise",
    "Biewer Terrier",
    "Black and Tan Coonhound",
    "Black Russian Terrier",
    "Bloodhound",
    "Bluetick Coonhound",
    "Boerboel",
    "Bohemian Shepherd",
    "Bolognese",
    "Border Collie",
    "Border Terrier",
    "Borzoi",
    "Boston Terrier",
    "Bouvier des Flandres",
    "Boxer",
    "Boykin Spaniel",
    "Bracco Italiano",
    "Braque du Bourbonnais",
    "Braque Francais Pyrenean",
    "Briard",
    "Brittany",
    "Broholmer",
    "Brussels Griffon",
    "Bull Terrier",
    "Bulldog",
    "Bullmastiff",
    "Cairn Terrier",
    "Canaan Dog",
    "Cane Corso",
    "Cardigan Welsh Corgi",
    "Carolina Dog",
    "Catahoula Leopard Dog",
    "Caucasian Shepherd Dog",
    "Cavalier King Charles Spaniel",
    "Central Asian Shepherd Dog",
    "Cesky Terrier",
    "Chesapeake Bay Retriever",
    "Chihuahua",
    "Chinese Crested",
    "Chinese Shar-Pei",
    "Chinook",
    "Chow Chow",
    "Cirneco dell’Etna",
    "Clumber Spaniel",
    "Cocker Spaniel",
    "Collie",
    "Coton de Tulear",
    "Croatian Sheepdog",
    "Curly-Coated Retriever",
    "Czechoslovakian Vlcak",
    "Dachshund",
    "Dalmatian",
    "Dandie Dinmont Terrier",
    "Danish-Swedish Farmdog",
    "Deutscher Wachtelhund",
    "Doberman Pinscher",
    "Dogo Argentino",
    "Dogue de Bordeaux",
    "Drentsche Patrijshond",
    "Drever",
    "Dutch Shepherd",
    "English Cocker Spaniel",
    "English Foxhound",
    "English Setter",
    "English Springer Spaniel",
    "English Toy Spaniel",
    "Entlebucher Mountain Dog",
    "Estrela Mountain Dog",
    "Eurasier",
    "Field Spaniel",
    "Finnish Lapphund",
    "Finnish Spitz",
    "Flat-Coated Retriever",
    "French Bulldog",
    "French Spaniel",
    "German Longhaired Pointer",
    "German Pinscher",
    "German Shepherd Dog",
    "German Shorthaired Pointer",
    "German Spitz",
    "German Wirehaired Pointer",
    "Giant Schnauzer",
    "Glen of Imaal Terrier",
    "Golden Retriever",
    "Gordon Setter",
    "Grand Basset Griffon Vendéen",
    "Great Dane",
    "Great Pyrenees",
    "Greater Swiss Mountain Dog",
    "Greyhound",
    "Hamiltonstovare",
    "Hanoverian Scenthound",
    "Harrier",
    "Havanese",
    "Hokkaido",
    "Hovawart",
    "Ibizan Hound",
    "Icelandic Sheepdog",
    "Irish Red and White Setter",
    "Irish Setter",
    "Irish Terrier",
    "Irish Water Spaniel",
    "Irish Wolfhound",
    "Italian Greyhound",
    "Jagdterrier",
    "Japanese Akitainu",
    "Japanese Chin",
    "Japanese Spitz",
    "Japanese Terrier",
    "Jindo",
    "Kai Ken",
    "Karelian Bear Dog",
    "Keeshond",
    "Kerry Blue Terrier",
    "Kishu Ken",
    "Komondor",
    "Kromfohrlander",
    "Kuvasz",
    "Labrador Retriever",
    "Lagotto Romagnolo",
    "Lakeland Terrier",
    "Lancashire Heeler",
    "Lapponian Herder",
    "Leonberger",
    "Lhasa Apso",
    "Löwchen",
    "Maltese",
    "Manchester Terrier (Standard)",
    "Manchester Terrier (Toy)",
    "Mastiff",
    "Miniature American Shepherd",
    "Miniature Bull Terrier",
    "Miniature Pinscher",
    "Miniature Schnauzer",
    "Mountain Cur",
    "Mudi",
    "Neapolitan Mastiff",
    "Nederlandse Kooikerhondje",
    "Newfoundland",
    "Norfolk Terrier",
    "Norrbottenspets",
    "Norwegian Buhund",
    "Norwegian Elkhound",
    "Norwegian Lundehund",
    "Norwich Terrier",
    "Nova Scotia Duck Tolling Retriever",
    "Old English Sheepdog",
    "Otterhound",
    "Papillon",
    "Parson Russell Terrier",
    "Pekingese",
    "Pembroke Welsh Corgi",
    "Perro de Presa Canario",
    "Peruvian Inca Orchid",
    "Petit Basset Griffon Vendéen",
    "Pharaoh Hound",
    "Plott Hound",
    "Pointer",
    "Polish Lowland Sheepdog",
    "Pomeranian",
    "Poodle (Miniature)",
    "Poodle (Standard)",
    "Poodle (Toy)",
    "Porcelaine",
    "Portuguese Podengo",
    "Portuguese Podengo Pequeno",
    "Portuguese Pointer",
    "Portuguese Sheepdog",
    "Portuguese Water Dog",
    "Pudelpointer",
    "Pug",
    "Puli",
    "Pumi",
    "Pyrenean Mastiff",
    "Pyrenean Shepherd",
    "Rafeiro do Alentejo",
    "Rat Terrier",
    "Redbone Coonhound",
    "Rhodesian Ridgeback",
    "Romanian Mioritic Shepherd Dog",
    "Rottweiler",
    "Russell Terrier",
    "Russian Toy",
    "Russian Tsvetnaya Bolonka",
    "Saint Bernard",
    "Saluki",
    "Samoyed",
    "Schapendoes",
    "Schipperke",
    "Scottish Deerhound",
    "Scottish Terrier",
    "Sealyham Terrier",
    "Segugio Italiano",
    "Shetland Sheepdog",
    "Shiba Inu",
    "Shih Tzu",
    "Shikoku",
    "Siberian Husky",
    "Silky Terrier",
    "Skye Terrier",
    "Sloughi",
    "Slovakian Wirehaired Pointer",
    "Slovensky Cuvac",
    "Slovensky Kopov",
    "Small Munsterlander",
    "Smooth Fox Terrier",
    "Soft Coated Wheaten Terrier",
    "Spanish Mastiff",
    "Spanish Water Dog",
    "Spinone Italiano",
    "Stabyhoun",
    "Staffordshire Bull Terrier",
    "Standard Schnauzer",
    "Sussex Spaniel",
    "Swedish Lapphund",
    "Swedish Vallhund",
    "Taiwan Dog",
    "Teddy Roosevelt Terrier",
    "Thai Ridgeback",
    "Tibetan Mastiff",
    "Tibetan Spaniel",
    "Tibetan Terrier",
    "Tornjak",
    "Tosa",
    "Toy Fox Terrier",
    "Transylvanian Hound",
    "Treeing Tennessee Brindle",
    "Treeing Walker Coonhound",
    "Vizsla",
    "Weimaraner",
    "Welsh Springer Spaniel",
    "Welsh Terrier",
    "West Highland White Terrier",
    "Wetterhoun",
    "Whippet",
    "Wire Fox Terrier",
    "Wirehaired Pointing Griffon",
    "Wirehaired Vizsla",
    "Working Kelpie",
    "Xoloitzcuintli",
    "Yakutian Laika",
    "Yorkshire Terrier",
    "Mixed Breed/Mutt",
  ],
  cat: [
    "Domestic Short-haired",
    "Domestic Long-haired",
    "Abyssinian",
    "American Bobtail",
    "American Curl",
    "American Shorthair",
    "American Wirehair",
    "Balinese",
    "Bengal",
    "Birman",
    "Bombay",
    "British Shorthair",
    "Burmese",
    "Burmilla",
    "Chartreux",
    "Chausie",
    "Cornish Rex",
    "Cymric",
    "Devon Rex",
    "Egyptian Mau",
    "Exotic Shorthair",
    "Havana",
    "Himalayan",
    "Japanese Bobtail",
    "Javanese",
    "Korat",
    "LaPerm",
    "Maine Coon",
    "Manx",
    "Munchkin",
    "Nebelung",
    "Norwegian Forest Cat",
    "Ocicat",
    "Oriental Short Hair",
    "Persian",
    "Pixiebob",
    "Ragamuffin",
    "Ragdoll",
    "Russian Blue",
    "Scottish Fold",
    "Selkirk Rex",
    "Siamese",
    "Siberian",
    "Singapura",
    "Snowshoe",
    "Somali",
    "Sphynx / Hairless Cat",
    "Tonkinese",
    "Toyger",
    "Turkish Angora",
    "Turkish Van",
    "York Chocolate",
  ],
  horse: [
    "Mixed Breed/Grade",
    "Abyssinian",
    "Akhal Teke",
    "Albanian",
    "Altai",
    "American Cream Draft",
    "American Creme and White",
    "American Walking Pony",
    "Andalusian",
    "Andravida",
    "Anglo-Kabarda",
    "Appaloosa",
    "Araappaloosa",
    "Arabian",
    "Ardennes",
    "Argentine Criollo",
    "Asturian",
    "Australian Brumby",
    "Australian Stock Horse",
    "Azteca",
    "Balearic",
    "Baluchi",
    "Banker",
    "Ban-ei",
    "Barb",
    "Bashkir",
    "Bashkir Curly",
    "Basotho Pony",
    "Belgian",
    "Bhirum Pony",
    "Bhotia Pony",
    "Boer",
    "Bosnian Mountain Horse",
    "Breton",
    "Buckskin",
    "Budyonny",
    "Byelorussian Harness",
    "Camargue",
    "Campolina",
    "Canadian",
    "Carthusian",
    "Caspian",
    "Cayuse",
    "Cheju",
    "Chilean Corralero",
    "Chincoteague Pony",
    "Cleveland Bay",
    "Clydesdale",
    "Colorado Ranger Horse",
    "Connemara Pony",
    "Criollo (Uruguay)",
    "Crioulo",
    "Dales Pony",
    "Danube",
    "Dartmoor Pony",
    "Deliboz",
    "Djerma",
    "Døle",
    "Dongola",
    "Dülmen Pony",
    "Dutch Draft",
    "Dutch Warmblood",
    "East Bulgarian",
    "Egyptian",
    "Eriskay Pony",
    "Estonian Native",
    "Exmoor Pony",
    "Faeroes Pony",
    "Falabella",
    "Fell Pony",
    "Finnhorse",
    "Fleuve",
    "Florida Cracker Horse",
    "Fouta",
    "Frederiksborg",
    "French Saddlebred",
    "French Trotter",
    "Friesian",
    "Galiceño",
    "Galician Pony",
    "Gelderlander",
    "Gidran",
    "Golden American Saddlebred",
    "Gotland",
    "Groningen",
    "Guangxi",
    "Hackney",
    "Haflinger",
    "Hanoverian",
    "Hequ",
    "Highland Pony",
    "Hokkaido",
    "Holsteiner",
    "Hucul",
    "Hungarian Warmblood",
    "Icelandic",
    "Iomud",
    "Irish Draught",
    "Jinzhou",
    "Jutland",
    "Kabarda",
    "Karabair",
    "Karabakh",
    "Kazakh",
    "Kerry Bog Pony",
    "Kiger Mustang",
    "Kirdi Pony",
    "Kisber Felver",
    "Kiso",
    "Kladruby",
    "Knabstrup",
    "Kushum",
    "Kustanai",
    "Latvian",
    "Lithuanian Heavy Draft",
    "Lipizzan",
    "Lokai",
    "Losino",
    "Lusitano",
    "Malopolski",
    "Mangalarga",
    "Marwari",
    "M'Bayar",
    "Mérens Pony",
    "Messara",
    "Miniature",
    "Misaki",
    "Missouri Fox Trotting Horse",
    "Miyako",
    "Mongolian",
    "Morab",
    "Morgan",
    "Moyle",
    "Mustang",
    "Murgese",
    "National Show Horse",
    "New Forest Pony",
    "New Kirgiz",
    "Newfoundland Pony",
    "Noma",
    "Nooitgedacht Pony",
    "Noric",
    "Nordland",
    "Northeastern",
    "North Swedish Horse",
    "Norwegian Fjord",
    "Ob",
    "Oldenburg",
    "Orlov Trotter",
    "Paint",
    "Palomino",
    "Pantaneiro",
    "Paso Fino",
    "Percheron",
    "Peruvian Paso",
    "Pindos Pony",
    "Pinia",
    "Pintabian",
    "Pinto",
    "Piquira",
    "Polish Konik",
    "Pony of the Americas",
    "Pottok",
    "Przewalski",
    "Pyrenean Tarpan",
    "Qatgani",
    "Quarab",
    "Quarter Horse",
    "Quarter Pony",
    "Racking Horse",
    "Rocky Mountain Horse",
    "Russian Don",
    "Russian Heavy Draft",
    "Russian Trotter",
    "Saddlebred",
    "Sanhe",
    "Schleswiger Heavy Draft",
    "Schwarzwälder Fuchs",
    "Selle Francais",
    "Shagya",
    "Shetland Pony",
    "Shire",
    "Single-Footing Horse",
    "Skyros Pony",
    "Somali Pony",
    "Sorraia",
    "Soviet Heavy Draft",
    "Spanish Mustang",
    "Spanish-Barb",
    "Spanish-Norman",
    "Standardbred",
    "Sudan Country-Bred",
    "Suffolk",
    "Swedish Warmblood",
    "Taishuh",
    "Tarpan",
    "Tawleed",
    "Tennessee Walking Horse",
    "Tersk",
    "Thessalian",
    "Thoroughbred",
    "Tokara",
    "Tori",
    "Trakehner",
    "Ukrainian Saddle",
    "Vlaamperd",
    "Vladimir Heavy Draft",
    "Vyatka",
    "Welara Pony",
    "Welsh Pony and Cob",
    "West African Barb",
    "Western Sudan Pony",
    "Wielkopolski",
    "Xilingol",
    "Yakut",
    "Yanqi",
    "Yili",
    "Yonaguni",
    "Zaniskari Pony",
    "Zhemaich",
  ],
};

export default Breeds;
