import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocalStorage } from "./use-local-storage";

const preferencesContext = createContext();

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const usePreferences = () => {
  return useContext(preferencesContext);
};

// Hook
export function useProvidePreferences() {
  // Use our useLocalStorage hook to persist state through a page refresh.
  // Read the recipe for this hook to learn more: usehooks.com/useLocalStorage
  const [isAutosuggestEnabled, setIsAutosuggestEnabled] = useState(
    localStorage.getItem("autosuggest-enabled") === "true",
  );

  const [isFindAndReplaceEnabled, setIsFindAndReplaceEnabled] =
    useState(localStorage.getItem("find-replace-enabled") === "true");

  useEffect(() => {
    localStorage.setItem("autosuggest-enabled", isAutosuggestEnabled);
  }, [isAutosuggestEnabled]);

  useEffect(() => {
    localStorage.setItem(
      "find-replace-enabled",
      isFindAndReplaceEnabled,
    );
  }, [isFindAndReplaceEnabled]);

  // Return enabled  and setter
  return {
    isAutosuggestEnabled: isAutosuggestEnabled,
    setIsAutosuggestEnabled: setIsAutosuggestEnabled,
    isFindAndReplaceEnabled,
    setIsFindAndReplaceEnabled,
  };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function PreferencesProvider({ children }) {
  const preferences = useProvidePreferences();

  return (
    <preferencesContext.Provider value={preferences}>
      {children}
    </preferencesContext.Provider>
  );
}
