import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmailExportTypeForm } from "../email/EmailExportTypeForm";
import {
  AccountItem,
  AccountItemBlock,
  AccountItemLine,
} from "./AccountItem";
import { AutoEmail } from "./AutoEmail";
import NoteExpiryTime from "./NoteExpiryTime";

export default function EmailExportingSettings({
  control,
  setValue,
  data,
  refetch,
}) {
  return (
    <div id="accountAutoExport">
      <AccountItem
        title="Email Exporting"
        icon={
          <FontAwesomeIcon icon={faEnvelope} size="lg" color="gray" />
        }
      >
        <div className="flex flex-col space-y-4 divide-y dark:divide-gray-700">
          <AccountItemBlock
            title="Auto Export Email:"
            desc="SOAPs will
            automatically export to this address once you've
            completed them."
          >
            <AutoEmail data={data} refetch={refetch} />
          </AccountItemBlock>
          <AccountItemBlock
            title="Auto Export Email Type:"
            desc="Set whether
              automatically exported notes are sent as a link or
              embedded directly in the email."
          >
            <EmailExportTypeForm
              control={control}
              setValue={setValue}
              data={data}
              isForAccountSetting={true}
              refetch={refetch}
            />
          </AccountItemBlock>
          <AccountItemBlock
            title="Note Link Expiry Time:"
            desc="The length of
              time your emailed SOAPs (and optional attachments) will
              be available to access. Note that edits to notes over
              this time will be viewable by your email export
              recipient."
          >
            <NoteExpiryTime data={data} refetch={refetch} />
          </AccountItemBlock>
        </div>
      </AccountItem>
    </div>
  );
}
