import { gql } from "@apollo/client";
import { NOTE_FIELDS } from "../fragments/note";

const GET_PATIENTS_APPROVED_NOTES = gql`
  ${NOTE_FIELDS}
  query patient($uuid: String, $isApproved: Boolean, $last: Boolean) {
    patient(uuid: $uuid) {
      createdAt
      name
      lastNameOfOwner
      user {
        firstName
        lastName
        name
        account {
          accountType
          logoFileLink
          headerInfoPdf
        }
      }
      notes(isApproved: $isApproved, last: $last) {
        user {
          firstName
          lastName
        }
        ...NoteFields
        keyHistory
        keyMedications
        vaccinationStatus
      }
    }
  }
`;
export default GET_PATIENTS_APPROVED_NOTES;
