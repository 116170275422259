export const mapStateToPreferences = (state) => {
  return {
    visitSummary: state?.visitSummary?.include,
    abnormalPhysicalFindings:
      state?.abnormalPhysicalFindings?.include,
    vitals: state?.vitals?.include,
    allFindings: state?.allFindings?.include,
    pointFormSummary: state?.pointFormSummary?.include,
    oneLineSummary: state?.oneLineSummary?.include,
    ownerInstructions: state?.ownerInstructions?.include,
    medications: state?.medications?.include,
    vaccinations: state?.vaccinations?.include,
    diagnostics: state?.diagnostics?.include,
    diet: state?.diet?.include,
    followUpAppointments: state?.followUpAppointments?.include,
    homeCare: state?.homeCare?.include,
  };
};
