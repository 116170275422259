export function addSelectedNotes({
  noteUuid,
  selectedNoteUuids,
  setSelectedNoteUuids,
  numSelectedNotes,
  setNumSelectedNotes,
  isPatient,
  numPatientNotes,
  setNumPatientNotes,
}) {
  const updatedSelection = {
    ...selectedNoteUuids,
    [noteUuid]: true,
  };
  setSelectedNoteUuids(updatedSelection);
  setNumSelectedNotes(numSelectedNotes + 1);
  if (isPatient) {
    setNumPatientNotes(numPatientNotes + 1);
  }
}

export function removeSelectedNotes({
  noteUuid,
  selectedNoteUuids,
  setSelectedNoteUuids,
  numSelectedNotes,
  setNumSelectedNotes,
  isPatient,
  numPatientNotes,
  setNumPatientNotes,
}) {
  const updatedSelection = selectedNoteUuids;
  delete updatedSelection[noteUuid];
  setSelectedNoteUuids(updatedSelection);
  setNumSelectedNotes(numSelectedNotes - 1);
  if (isPatient) {
    setNumPatientNotes(numPatientNotes - 1);
  }
}

export function copyNotesToPatientRedirect({
  selectedNoteUuids,
  navigate,
}) {
  navigate(
    `/notebook_select/copy/${Object.keys(
      selectedNoteUuids,
    ).toString()}`,
  );
}

export function moveNotesToPatientRedirect({
  selectedNoteUuids,
  navigate,
}) {
  navigate(
    `/notebook_select/move/${Object.keys(
      selectedNoteUuids,
    ).toString()}`,
  );
}

/**
 * Returns true if the number of notes selected is equal to the number of notes that do
 * are not "new recordings" or "marked as submitted".
 *
 * Ie, false if any selected note is a "new recording" or "marked as submitted".
 *
 * @param {list} notes note data that fees the notes list this multiselect is being used on
 * @param {object} selectedNoteUuids an object with currently selected note uuids as keys
 * @param {int} numSelectedNotes
 * @returns {boolean}
 */
export function getShouldAllowCopyAction({
  notes,
  selectedNoteUuids,
  numSelectedNotes,
}) {
  if (!notes) {
    return false;
  }

  if (!selectedNoteUuids) {
    return false;
  }

  const shouldAllow =
    notes?.filter(
      (note) =>
        selectedNoteUuids[note?.uuid] &&
        !note?.isMarkedAsProcessing &&
        !note?.isNewRecording,
    ).length === numSelectedNotes;

  return shouldAllow;
}
