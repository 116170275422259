import { gql } from "@apollo/client";

const query = gql`
query GetPatients 
  {
    patients {
      name
      lastNameOfOwner
      uuid
      species
      sex
      breed
      age
    }
  }
`;

export default query;
