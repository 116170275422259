import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "../common/inputs/Input";
import { EmailExportTypeForm } from "./EmailExportTypeForm";

export function EmailExportForm({ control }) {
  const { watch } = useFormContext();
  const exportType = watch("exportType");

  return (
    <>
      <div className="space-y-2">
        <EmailExportTypeForm control={control} />
        <h5>
          Enter the email address (or addresses, separated by commas)
          you would like to send this note to:
        </h5>
        <Controller
          control={control}
          rules={{
            required: false,
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              placeholder="e.g. doctor@someclinic.com, staff@someclinic.com"
              onChange={(value) => onChange(value)}
              value={value}
            />
          )}
          name="toEmail"
          defaultValue=""
        />
      </div>

      <div className="space-y-2 pt-8">
        <h5>
          Enter any special instructions for the recipient{" "}
          <small>(optional)</small>:
        </h5>
        <Controller
          control={control}
          rules={{
            required: false,
          }}
          render={({ field: { onChange, value } }) => (
            <Input
              placeholder={
                "e.g. Please see below for the medical record we discussed."
              }
              onChange={(value) => onChange(value)}
              value={value}
            />
          )}
          name="specialInstructions"
          defaultValue=""
        />
      </div>

      {exportType !== "embeddedText" ? (
        <div className="flex flex-row items-center space-x-3 mt-8">
          <Controller
            control={control}
            rules={{
              required: false,
            }}
            render={({ field: { onChange, value } }) => (
              <input
                type="checkbox"
                className="hover:cursor-pointer"
                onChange={(value) => onChange(value)}
                checked={value}
                disabled={exportType === "embeddedText"}
              />
            )}
            name="includeAttachments"
            defaultValue={true}
          />
          <h4>Include attachments?</h4>
        </div>
      ) : null}
    </>
  );
}
