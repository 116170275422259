import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNoteMultiselectContext } from "../../../hooks/use-note-multiselect";

export function MultiSelectActionButton({
  onClick,
  onClickActionDescription,
  icon,
  buttonColor,
  isForPatientSelectionAction = true,
  isForTeamsTransferAction,
  disabled,
}) {
  const { selectedNoteUuids, numSelectedNotes } =
    useNoteMultiselectContext();

  return (
    <button
      disabled={disabled}
      onClick={() => onClick(selectedNoteUuids)}
      className={`${
        buttonColor
          ? buttonColor
          : "bg-indigo-500 hover:bg-indigo-600"
      } p-3 disabled:bg-gray-400 disabled:cursor-not-allowed cursor-pointer rounded-full text-white text-sm shadow-md transition-all`}
    >
      <FontAwesomeIcon icon={icon} /> {onClickActionDescription}{" "}
      {isForPatientSelectionAction
        ? "to patient"
        : isForTeamsTransferAction
        ? "ownership"
        : ""}
    </button>
  );
}

MultiSelectActionButton.propTypes = {
  numSelectedItems: PropTypes.number,
  selectedItemsName: PropTypes.string,
  onClick: PropTypes.func,
};
