import React, { useState, createContext, useContext } from "react";

const uploadContext = createContext();

export const useUploadProgress = () => {
  return useContext(uploadContext);
};

export function useProvideUploadProgress() {
  const [currentFileName, setCurrentFileName] = useState("");
  const [percentageUploaded, setPercentageUploaded] = useState(0);
  const [error, setError] = useState(null);

  return {
    currentFileName,
    setCurrentFileName,
    percentageUploaded,
    setPercentageUploaded,
    error,
    setError,
  };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useUploadProgress().
export function UploadProgressProvider({ children }) {
  const uploadProgress = useProvideUploadProgress();

  return (
    <uploadContext.Provider value={uploadProgress}>
      {children}
    </uploadContext.Provider>
  );
}
