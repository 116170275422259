import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, gql } from "@apollo/client";

import { Button } from "./form/Button.js";
import { alert } from "../common/Alert.js";
import Twemoji from "../../Twemoji.js";
import { ErrorMessage, Input } from "../common/inputs/Input.js";

const CONFIRM_USER_MUTATION = gql`
  mutation sendForgotPasswordEmail($email: String) {
    sendForgotPasswordEmail(email: $email) {
      ok
    }
  }
`;

export default function ForgotPassword() {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [sendForgotPasswordEmail] = useMutation(
    CONFIRM_USER_MUTATION,
  );

  function submitEmail({ email }) {
    sendForgotPasswordEmail({
      variables: {
        email,
      },
    })
      .then((response) => {
        const { ok } = response.data.sendForgotPasswordEmail;

        if (ok) {
          alert(
            "success",
            "If an account with this email exists, we've sent a password reset link to it!",
          );
        }
      })
      .catch((response) => {
        console.log(response);
        const errors = response.graphQLErrors.map((error) =>
          alert("error", error.message),
        );
        // need to catch errors to prevent exception
        // might as well console log to debug if client has issue
        console.log(errors);
      });
  }

  return (
    <form
      onSubmit={handleSubmit(submitEmail)}
      className="flex-col items-center justify-center sm:px-6 lg:px-8"
    >
      <div className="space-y-8">
        <h2 className="mt-6 text-center text-3xl font-extrabold dark:text-gray-300 text-gray-900">
          <Twemoji emoji="🤬" /> Forgotten Passwords...
        </h2>
        <h6 className="text-center dark:text-gray-300">
          Don&apos;t Worry! Enter your Scribenote account&apos;s email below and we&apos;ll send you a link to continue.
        </h6>
        <div className="flex flex-col max-w-md mx-auto">
          <Controller
            control={control}
            name="email"
            rules={{ required: true, pattern: /^\S+@\S+$/i }}
            render={({ field }) => (
              <Input
                placeholder={"Type your email here"}
                {...field}
              />
            )}
          />
          {errors.email && (
            <ErrorMessage message="Please enter your email address." />
          )}
          <div className="m-8">
            <Button type="submit">Submit</Button>
          </div>
        </div>
      </div>
    </form>
  );
}
