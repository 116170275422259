import mixpanel from "mixpanel-browser";
import { NORMAL_ON_EXAM } from "../../../constants";
import { alert } from "../../common/Alert";
import { getObjectiveFieldsString } from "../../common/clinical/utils";

export function copyClientSummary({
  frontmatter,
  backmatter,
  clientSummary,
}) {
  const textToCopy =
    frontmatter + "\n\n" + clientSummary + "\n\n" + backmatter;
  navigator.clipboard.writeText(textToCopy).then(() => {
    alert(
      "info",
      "Your client summary has been copied to your clipboard!",
    );
  });
}

export function copyAll({ note, isForSharedExport }) {
  mixpanel.track("Copy Note Text");
  let textToCopy = "";

  const capitalCopy =
    localStorage.getItem("should-use-capital-subtitles-copy") ===
    "true"
      ? true
      : false;

  const styledSub = !capitalCopy ? "Subjective" : "SUBJECTIVE";
  const styledObj = !capitalCopy ? "Objective" : "OBJECTIVE";
  const styledAssess = !capitalCopy ? "Assessment" : "ASSESSMENT";
  const styledPlan = !capitalCopy ? "Plan" : "PLAN";
  const styledAddNotes = !capitalCopy
    ? "Additional Notes"
    : "ADDITIONAL NOTES";

  if (note?.shouldUseCustomForm) {
    if (note?.customText) {
      textToCopy = note?.customText;
    } else {
      textToCopy = note?.customFormFields[0]?.textValue;
    }
  } else {
    textToCopy = textToCopy + `${styledSub}: \n`;
    textToCopy =
      textToCopy +
      (note?.subjective && note?.subjective.length > 0
        ? note?.subjective
        : "");
    textToCopy = textToCopy + "\n\n";

    textToCopy = textToCopy + `${styledObj}: \n`;

    if (note?.shouldUseGenericObjective) {
      if (note?.weight && note?.weight !== NORMAL_ON_EXAM) {
        textToCopy = textToCopy + "Weight: " + note?.weight + "\n";
      }
      textToCopy =
        textToCopy +
        (note?.objective && note?.objective.length > 0
          ? note?.objective
          : "");
    } else {
      textToCopy = textToCopy + getObjectiveFieldsString(note);
    }
    textToCopy = textToCopy + "\n\n";

    textToCopy = textToCopy + `${styledAssess}: \n`;
    textToCopy =
      textToCopy +
      (note?.assessment && note?.assessment.length > 0
        ? note?.assessment
        : "");
    textToCopy = textToCopy + "\n\n";

    textToCopy = textToCopy + `${styledPlan}: \n`;
    textToCopy =
      textToCopy +
      (note?.plan && note?.plan.length > 0 ? note?.plan : "");
    textToCopy = textToCopy + "\n\n";

    textToCopy =
      textToCopy +
      (note?.additionalNotes && note?.additionalNotes.length > 0
        ? `${styledAddNotes}: \n${note?.additionalNotes}`
        : "");
  }

  navigator.clipboard.writeText(textToCopy).then(() => {
    alert(
      "info",
      `Copied entire note! Now go ahead and paste it where you wish. ${
        note?.hasBeenExported || isForSharedExport
          ? ""
          : "Your note has been marked as exported."
      }`,
    );
  });
}
