import React, { useContext, useEffect, useState } from "react";
import { OBJECTIVE_FIELDS } from "../../../constants.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import ObjectiveFieldItem from "./ObjectiveFieldItem.js";
import CustomObjectiveFields from "./CustomObjectiveFields.js";

import {
  ObjectiveTypeMainSeparator,
  ObjectiveTypeSeparator,
} from "./ObjectiveTypeSeparators.js";
import { useFieldArray } from "react-hook-form";

export default function ObjectiveFields({
  control,
  setValue,
  isEditFieldModeActive,
}) {
  const { fields, replace, remove } = useFieldArray({
    control,
    name: "defaultObjectiveFieldArray",
  });

  function renderObjectiveFields(fields) {
    return (
      <>
        {fields.map((item, index) => (
          <React.Fragment key={index}>
            {index === 0 &&
              renderObjectiveFieldsTitle(
                item.type,
                item.type === "overall"
                  ? "Overall"
                  : item.type.charAt(0).toUpperCase() +
                      item.type.slice(1) +
                      "s",
              )}
            {index >= 1 &&
              item.type !== fields[index - 1].type &&
              renderObjectiveFieldsTitle(
                item.type,
                item.type === "overall" ? "Overall" : "Systems",
              )}

            <div
              className="group relative flex flex-row items-end"
              key={index}
            >
              {isEditFieldModeActive && (
                <div
                  onClick={() => {
                    remove(index);
                  }}
                  className="h-5 w-5 bg-pink-100 dark:bg-gray-600 group-hover:bg-red-400 dark:group-hover:bg-red-400 mr-2 rounded-full flex items-center justify-center transition-all cursor-pointer"
                >
                  <FontAwesomeIcon icon={faMinus} color="white" />
                </div>
              )}
              <ObjectiveFieldItem
                key={index}
                fieldName={item.name}
                fieldLabel={item.label}
                formFieldValue={`defaultObjectiveFieldArray[${index}].value`}
                control={control}
                allowEditable={item.allowEditable || false}
                type={"text"}
                isEditFieldModeActive={isEditFieldModeActive}
                dataCy={item.name}
              />
            </div>
          </React.Fragment>
        ))}
      </>
    );
  }

  function renderObjectiveFieldsTitle(type, title) {
    if (fields.filter((item) => item.type === type).length > 0) {
      return (
        <div className="mt-1">
          <ObjectiveTypeSeparator title={title} />
        </div>
      );
    }
  }

  return (
    <div>
      <ObjectiveTypeMainSeparator title="Default Fields" />
      {fields.length === 0 && (
        <h5 className="text-sm text-center text-gray-500 dark:text-gray-400 py-3">
          It appears all of the default fields have been removed. You
          can restore them by clicking the &apos;Restore Default
          Fields&apos; button when in{" "}
          <span className="italic">edit fields</span> mode.
        </h5>
      )}
      {renderObjectiveFields(fields)}

      {fields.length !== OBJECTIVE_FIELDS.length &&
      isEditFieldModeActive ? (
        <button
          className="group w-full py-1 px-2 bg-pink-50 dark:bg-gray-700 rounded-full mt-1 hover:bg-pink-100 dark:hover:bg-gray-600 transition-all"
          onClick={() => {
            // NOTE TO SELF: Refactor with template and EditableNoteFormWrapper useEffect
            let resetValues = OBJECTIVE_FIELDS.map(
              ({ name, label, type }) => {
                let value = "";

                let listIndexIfExisting = fields.findIndex(
                  (item) => item.name === name,
                );

                if (
                  listIndexIfExisting &&
                  fields[listIndexIfExisting]
                ) {
                  value = fields[listIndexIfExisting].value;
                }

                return {
                  name,
                  value,
                  label,
                  type,
                };
              },
            );

            replace(resetValues);
          }}
        >
          <h4 className="text-gray-400 text-sm transition-all">
            Restore Default Fields
          </h4>
        </button>
      ) : null}

      <CustomObjectiveFields
        control={control}
        setValue={setValue}
        isEditFieldModeActive={isEditFieldModeActive}
      />
    </div>
  );
}
