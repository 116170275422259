import React from "react";

export function EmailExportTypeRadioButton({
  title,
  description,
  onChange,
  value,
  currentValue,
}) {
  return (
    <div
      onClick={() => onChange(value)}
      className={`flex-1 ${
        value === currentValue ? "bg-indigo-50 dark:bg-gray-700" : ""
      } dark:hover:bg-gray-700 hover:bg-indigo-50 rounded-xl p-3 cursor-pointer`}
    >
      <div className="flex flex-row items-center space-x-2">
        <input
          onChange={() => {
            onChange(value);
          }}
          checked={value === currentValue}
          type={"radio"}
          value={value}
        />
        <label>
          <h5
            className={`font-semibold cursor-pointer ${
              value === currentValue
                ? "dark:text-indigo-400 text-indigo-500"
                : ""
            }`}
          >
            {title}
          </h5>
        </label>
      </div>
      <h5 className="text-xs text-gray-400 dark:text-gray-400">
        {description}
      </h5>
    </div>
  );
}
