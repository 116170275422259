import { useState } from "react";
import { getTempScribenoteFlask } from "../utils";
import { alert } from "../components/common/Alert";
import { set } from "lodash";

export function useStreamResult() {
  const [hasCancelEventOccurred, setHasCancelEventOccurred] =
    useState(false);
  const [isStreaming, setIsStreaming] = useState(false);

  const connectSSE = (taskUuid, updateCallback, finalizeCallback) => {
    const SCRIBENOTE_FLASK = getTempScribenoteFlask();

    // Critically - don't just copy / paste this code into
    // a mobile app and expect it to work.
    // With credentials relies on including http-only cookies
    // which the mobile apps currently do not support.
    const eventSource = new EventSource(
      `${SCRIBENOTE_FLASK}/stream?channel=llm-streaming-task:${taskUuid}`,
      {
        withCredentials: true,
      },
    );

    setIsStreaming(true);

    function reset() {
      eventSource.close();
      setIsStreaming(false);
      finalizeCallback();
    }

    eventSource.onmessage = function (event) {
      // Parse the incoming message
      const newMessage = event?.data;

      if (!newMessage) {
        return;
      }

      // new message format is {"data": " blah"} or {"event": "blah"}
      const type = JSON.parse(newMessage)?.event || "";

      if (type == "stream-end") {
        // Close the connection gracefully if the event is "end"
        reset();
        return;
      } else if (type == "stream-error") {
        // Close the connection with an error if the event is "stream-error"
        alert(
          "error",
          "An error has occurred. Please try again later.",
        );

        reset();
        return;
      } else {
        // ------- VALID MESSAGE -------
        // Parse the message to get the actual message
        const message = JSON.parse(newMessage)?.data || "";

        // Update the state to render the message in the state
        //   and also write it to whatever callback is provided

        updateCallback(message);
      }
    };

    eventSource.onerror = function () {
      // Close the connection if an error occurs
      eventSource.close();
    };
  };

  return {
    connectSSE,
    setHasCancelEventOccurred,
    isStreaming,
  };
}
