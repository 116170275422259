import React from "react";

export default function FourohFour() {
  return (
    <div className="flex flex-col h-96 items-center justify-center space-y-3">
      <h2>Whoops, we couldn&apos;t find this page.</h2>
      <h5>
        Something went wrong, and we weren&apos;t able to take you
        where you wanted to go. Sorry about that!
      </h5>
      <h5>
        If you need help, please contact{" "}
        <a
          className=" text-indigo-500 hover:text-indigo-300"
          href="mailto:support@scribenote.com"
        >
          support@scribenote.com
        </a>
      </h5>
    </div>
  );
}
