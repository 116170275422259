import React, { useEffect, createRef } from "react";

function AudioVisualiser({ audioData }) {
  const canvas = createRef();

  useEffect(() => {
    draw();
  });

  function draw() {
    const height = canvas.current.height;
    const width = canvas.current.width;
    const context = canvas.current.getContext("2d");
    let x = 0;
    const sliceWidth = (width * 1.5) / audioData.length;

    context.lineWidth = 5;
    context.lineCap = "round";
    context.lineJoin = "round";
    context.clearRect(0, 0, width, height);

    context.beginPath();
    context.moveTo(0, height / 2);

    for (const item of audioData) {
      const y = (item / 255) * height;

      context.lineTo(x, y);

      x += sliceWidth;
    }
    context.lineTo(width, height / 2);

    var grad = context.createLinearGradient(0, height, width, height);
    grad.addColorStop(0, "rgba(165,180,252,0)");
    grad.addColorStop(0.5, "rgba(165,180,252,1)");
    grad.addColorStop(1, "rgba(165,180,252,0)");

    context.strokeStyle = grad;

    context.stroke();
  }

  return <canvas className="w-full h-[100px]" ref={canvas} />;
}

export default AudioVisualiser;
