import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import PageTitle from "../../layout/PageTitle";
import { alert } from "../../common/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faUsers,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import FullScreenLoading from "../../common/loading/FullScreenLoading";
import PartyScribbles from "../../../assets/imgs/party-scribbles.png";
import { BasicButton } from "../../common/buttons/BasicButton";
import HeadingWrapper from "../../common/HeadingWrapper";
import {
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { EditExistingTeamMembers } from "./EditExistingTeamMembers";
import { useAuth } from "../../../hooks";
import GET_TEAM from "../../../graphql/queries/GetTeam";
import AddTeamMembersModal from "./AddTeamMembersModal";

export default function TeamManagement() {
  const [isShowingAddMembersModal, setIsShowingAddMembersModal] =
    useState(false);

  const { teamUuid } = useParams();
  const { orgUuid } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, loading, refetch } = useQuery(GET_TEAM, {
    variables: { uuid: teamUuid },
    fetchPolicy: "no-cache",
  });

  if (!data || loading) {
    return <FullScreenLoading loadingTrigger={loading} />;
  }

  const hideAddMembersModal = () => {
    setIsShowingAddMembersModal(false);
  };

  // Don't let admins of other orgs directly access other teams
  if (orgUuid !== data?.team?.organization?.uuid) {
    return (
      <div className="flex flex-col h-[600px] items-center justify-center">
        <div className="flex flex-col items-center space-y-10 bg-yellow-500/10 p-10 rounded-xl border border-yellow-200">
          <h2>Whoops, we can&apos;t take you there.</h2>
          <h5 className="max-w-xl text-center">
            This team is not part of your organization. Please return
            to your admin page to access your teams.
          </h5>
          <a href="/admin">
            <h4 className="underline text-indigo-500">
              Click here to return to your organization's admin
              organization page.
            </h4>
          </a>
        </div>
      </div>
    );
  }

  const handleBackClick = () => {
    const queryParams = new URLSearchParams(location.search);
    navigate(`/admin?${queryParams.toString()}`);
  };

  return (
    <>
      <AddTeamMembersModal
        orgUuid={orgUuid}
        isShowingAddMembersModal={isShowingAddMembersModal}
        hideAddMembersModal={hideAddMembersModal}
        refetch={refetch}
        team={data?.team}
      />
      <PageTitle
        className="mb-6"
        title={`Manage ${
          data?.team?.name ? data?.team?.name : "Team"
        }`}
        onClick={() => {
          alert("warning", "Tutorial coming soon!");
        }}
        showHelpButton
      />
      {data?.team && (
        <>
          <TeamHeaderInfo data={data} />
          <div className="flex flex-row items-center justify-end space-x-2">
            <BasicButton
              importance="secondary"
              onClick={handleBackClick}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />{" "}
              View Other Teams
            </BasicButton>
            <BasicButton
              onClick={() => setIsShowingAddMembersModal(true)}
            >
              <FontAwesomeIcon icon={faUserPlus} className="mr-2" />{" "}
              Add Team Members
            </BasicButton>
          </div>
          <div className="pb-4">
            <HeadingWrapper heading={<h3>Current Team Members</h3>} />
            {data?.team?.users.length === 0 ? (
              <div
                className="flex flex-col items-center bg-gray-100/70 dark:bg-gray-700/30 border-2 border-dashed border-gray-50 dark:border-gray-700/50 rounded-lg py-10 px-2 w-full my-4 dark:hover:bg-gray-700/50 transition-all cursor-pointer hover:bg-indigo-100 hover:border-indigo-200"
                onClick={() => setIsShowingAddMembersModal(true)}
              >
                <img
                  className="flex self-center"
                  width="100px"
                  src={PartyScribbles}
                  alt="Party Scribbles"
                />
                <h4 className="mt-4 mb-2">
                  No one&apos;s here yet...
                </h4>
                <h5 className="text-gray-700 max-w-3xl">
                  Add team members to get started!
                </h5>
              </div>
            ) : (
              <EditExistingTeamMembers data={data} />
            )}
          </div>
        </>
      )}
    </>
  );
}

function TeamHeaderInfo({ data }) {
  return (
    <div className="flex flex-col md:flex-row md:items-center bg-indigo-50 dark:bg-gray-700 shadow-sm rounded-xl p-4 my-4 md:my-10 space-x-2 md:space-x-4">
      <div className="h-10 w-10 flex items-center justify-center rounded-full bg-indigo-500 text-white ml-1 md:ml-4">
        <FontAwesomeIcon icon={faUsers} />
      </div>
      <div className="flex flex-col">
        <div className="w-44 lg:w-96">
          <h3>{data?.team?.name}</h3>
        </div>
        <h5 className="text-xs text-gray-500">
          Team ID: {data?.team?.uuid}
        </h5>
      </div>
      <div className="flex flex-col space-y-1">
        {data?.team?.clinicName && (
          <h4 className="text-sm font-normal">
            <span className="font-semibold">Clinic:</span>{" "}
            {data?.team?.name}, {data?.team?.location}
          </h4>
        )}
      </div>
    </div>
  );
}
