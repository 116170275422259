import React, { useState } from "react";
import usePlacesAutocomplete, {
  getDetails,
} from "use-places-autocomplete";
import PoweredByGoogleDark from "../../../assets/imgs/powered_by_google_on_non_white_hdpi.png";
import PoweredByGoogle from "../../../assets/imgs/powered_by_google_on_white_hdpi.png";
import { useDarkMode } from "../../../hooks";

import { InputWithRef } from "./Input";

export function GMapsInput({ updateBillingAddress, inputRef }) {
  const [isHovering, setIsHovering] = useState(false);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = (selection) => async () => {
    const details = await getDetails({
      placeId: selection.place_id,
    });

    const structuredBillingAddress = {
      city: null,
      country: null,
      line1: null,
      line2: null,
      postal_code: null,
      state: null,
    };

    for (const component of details.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          structuredBillingAddress.line1 = component.long_name + " ";
          break;
        }

        case "route": {
          structuredBillingAddress.line1 += component.long_name;
          break;
        }

        case "locality":
          structuredBillingAddress.city = component.long_name;
          break;

        case "administrative_area_level_1": {
          structuredBillingAddress.state = component.short_name;

          break;
        }
        case "country":
          structuredBillingAddress.country = component.short_name;

          break;

        case "postal_code":
          structuredBillingAddress.postal_code = component.long_name;
          break;
      }

      updateBillingAddress(structuredBillingAddress);
    }

    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(selection.description, false);
    clearSuggestions();
  };
  const darkModeEnabled = useDarkMode();

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion)}
          className="p-1 cursor-pointer hover:bg-indigo-100 dark:hover:bg-indigo-700"
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div
      className="relative"
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <InputWithRef
        ref={inputRef}
        onBlur={() => {
          if (!isHovering) {
            clearSuggestions();
            setValue("");
          }
        }}
        onKeyDown={(event) => {
          if (event?.code == "Tab") {
            event.preventDefault();
          }
        }}
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="123 Anywhere St."
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && (
        <ul className="border rounded-lg  border-gray-200 dark:border-gray-600 absolute z-10 w-full bg-white dark:bg-gray-800 divide-y p-2 divide-gray-200 dark:divide-gray-600">
          {renderSuggestions()}
          <div className="dark:bg-black flex justify-end rounded-lg mt-3">
            <img
              src={
                darkModeEnabled[0]
                  ? PoweredByGoogleDark
                  : PoweredByGoogle
              }
              className={"h-8 w-32 p-2"}
            />
          </div>
        </ul>
      )}
    </div>
  );
}
